import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import './applications.css';
import '../../App.css';

import APPLICANTS from '../../../../constants/applicants.js';

import AppHeader from '../../../../components/AppHeader';
import AppTextField from '../../../../components/ApplicationModels/AppTextField';
import ApplicationView from '../../../../components/ApplicationModels/ApplicationView';
import BackButton from '../../../../components/BackButton';

import { userTypeAtom, userAtom, schoolAtom } from '../../../../state';
import { useApiGetSchoolStudentApplication } from '../../../../api'


const Applications = ({ match }) => {
    const user = useRecoilValue(userAtom);
    const userType = useRecoilValue(userTypeAtom);
    const school = useRecoilValue(schoolAtom);
    const studentId = match.params.student_id;
    const [ applicant, setApplicant ] = useState(null);
    const {data: application, error } = useApiGetSchoolStudentApplication(studentId, school.id);

    return (
      <body className='d-md-flex' style={{backgroundColor: '#EFEFEF'}}>
        <header id='app-header-container' className='col-xs-12 col-md-3'>
          <AppHeader />
        </header>

        <section className='col-12 col-md-9'>
          <BackButton className='mt-20'/>
          {!!application && <ApplicationView application={application} /> }
        </section>
      </body>
    )
};

export default Applications
