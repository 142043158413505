import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import courses from "../../../../constants/courses";
import { Flex, Button, Text, Input, Select, Textarea } from "@chakra-ui/react";
import AppHeader from "../../../../components/AppHeader";
import "../../App.css";
import { Formik } from "formik";
const Course = () => {
  const search = useLocation().search;
  const edit = JSON.parse(new URLSearchParams(search).get("edit"));
  const [editing, setEditing] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const { id } = useParams();
  const [course, setCourse] = useState({
    title: "",
    sponsorship_priority: "",
    course_type: "",
    graduate_level: "",
    cut_off_points: "",
    description: "",
  });
  useEffect(() => {
    let findCourse = courses.find((item) => item.id === parseInt(id));
    setCourse(findCourse);
  }, [id]);

  return (
    <>
      <body className="d-md-flex">
        <AppHeader />
        <div
          className="col-xs-12 app-body-container"
          id="app-container"
        >
          <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
            <Flex>
              <Text fontSize={25} fontWeight={"500"}>
                Course
              </Text>
              {course ? (
                <Text ml={5} fontSize={25} fontWeight={300}>
                  {course.title}
                </Text>
              ) : (
                "loading"
              )}
            </Flex>
            <Flex mb={20} flexDir={"column"} mt={10}>
              {!!course && course.title.length > 0 ? (
                <>
                  {edit ? (
                    <Formik
                      initialValues={{
                        title: course.title,
                        sponsorship_priority: course.sponsorship_priority,
                        course_type: course.course_type,
                        graduate_level: course.graduate_level,
                        cut_off_points: course.cut_off_points,
                        description: course.description,
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.title) errors.title = "Required";
                        if (!values.sponsorship_priority)
                          errors.sponsorship_priority = "Required";
                        if (!values.course_type)
                          errors.course_type = "Required";
                        if (!values.graduate_level)
                          errors.graduate_level = "Required";
                        if (!values.cut_off_points)
                          errors.cut_off_points = "Required";
                        if (!values.description)
                          errors.description = "Required";

                        if (Object.keys(errors).length === 0)
                          setFormSuccess(true);
                        else setFormSuccess(false);

                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          alert(JSON.stringify(values, null, 2));
                          setSubmitting(false);
                        }, 400);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                      }) => (
                        <form>
                          <Button
                            disabled={!editing}
                            _hover={{ bg: "#FFA800", color: "black" }}
                            h={55}
                            fontSize={15}
                            fontWeight={500}
                            w={200}
                            rounded={0}
                            color="white"
                            bg="black"
                          >
                            Save Changes
                          </Button>

                          <Text mt={10} fontSize={25} fontWeight={600}>
                            Title
                          </Text>
                          <Input
                            onChange={(e) => {
                              if (e.target.value !== course.title) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            name="name"
                            onBlur={handleBlur}
                            value={values.title}
                            rounded={0}
                            w={500}
                          />
                          <Text color="red.500">
                            {touched.title && errors.title}
                          </Text>
                          <Text mt={10} fontSize={25} fontWeight={600}>
                            Sponsorship Priority
                          </Text>

                          <Select
                            onChange={(e) => {
                              if (
                                e.target.value !== course.sponsorship_priority
                              ) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            rounded={0}
                            name="sponsorship_priority"
                            onBlur={handleBlur}
                            w={120}
                          >
                            <option
                              value="High"
                              selected={
                                course.sponsorhip_priority === "High"
                                  ? true
                                  : false
                              }
                            >
                              High
                            </option>
                            <option
                              value="Medium"
                              selected={
                                course.sponsorship_priority === "Medium"
                                  ? true
                                  : false
                              }
                            >
                              Medium
                            </option>
                            <option
                              value="Low"
                              selected={
                                course.sponsorship_priority === "Low"
                                  ? true
                                  : false
                              }
                            >
                              Low
                            </option>
                          </Select>
                          <Text color="red.500">
                            {touched.sponsorship_priority &&
                              errors.sponsorship_priority}
                          </Text>
                          <Text mt={10} fontSize={25} fontWeight={600}>
                            Course Type
                          </Text>
                          <Input
                            onChange={(e) => {
                              if (e.target.value !== course.course_type) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            name="course_type"
                            rounded={0}
                            value={values.course_type}
                            w={300}
                          />
                          <Text color="red.500">
                            {touched.course_type && errors.course_type}
                          </Text>
                          <Text mt={10} fonstSize={25} fontWeight={600}>
                            Graduate Level
                          </Text>
                          <Select
                            onChange={(e) => {
                              if (e.target.value !== course.graduate_level) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            rounded={0}
                            name="graduate_level"
                            w={200}
                          >
                            <option
                              selected={
                                course.graduate_level === "Undergraduate"
                                  ? true
                                  : false
                              }
                            >
                              Undergraduate
                            </option>
                            <option
                              selected={
                                course.graduate_level === "Postgraduate"
                                  ? true
                                  : false
                              }
                            >
                              Postgraduate
                            </option>
                          </Select>
                          <Text color="red.500">
                            {touched.graduate_level && errors.graduate_level}
                          </Text>
                          <Text mt={10} fontSize={25} fontWeight={600}>
                            Points Cutoff
                          </Text>
                          <Input
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) !==
                                course.cut_off_points
                              ) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            type="number"
                            name="cut_off_points"
                            onBlur={handleBlur}
                            value={values.cut_off_points}
                            w={70}
                            rounded={0}
                          />
                          <Text color="red.500">
                            {touched.cut_off_points && errors.cut_off_points}
                          </Text>
                          <Text mt={10} fontSize={25} fontWeight={600}>
                            Description
                          </Text>
                          <Textarea
                            onChange={(e) => {
                              if (e.target.value !== course.description) {
                                setEditing(true);
                              }
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            name="description"
                            mb={20}
                            minW={500}
                            h={200}
                            value={values.description}
                            w={70}
                            rounded={0}
                          />
                          <Text color="red.500">
                            {touched.description && errors.description}
                          </Text>
                        </form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Title
                      </Text>
                      <Text>{course.title}</Text>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Sponrship Priority
                      </Text>
                      <Text>{course.sponsorship_priority}</Text>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Course Type
                      </Text>
                      <Text>{course.course_type}</Text>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Graduate Level
                      </Text>
                      <Text>{course.graduate_level}</Text>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Points Cutoff
                      </Text>
                      <Text>{course.cut_off_points}</Text>
                      <Text mt={10} fontSize={25} fontWeight={600}>
                        Description
                      </Text>
                      <Text w={500}>{course.description}</Text>
                    </>
                  )}
                </>
              ) : (
                <Text>Loading...</Text>
              )}
            </Flex>
          </Flex>
        </div>
      </body>
    </>
  );
};

export default Course;
