/*
  Documentation: https://devexpress.github.io/devextreme-reactive/react/chart/demos/pie/pie/
*/

import {
  Chart,
  PieSeries,
  Title,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';

import './index.css';
import { Flex } from '@chakra-ui/react';

var classNames = require('classnames');

export const PieGraph = ({ data, valueKey, titleKey, title, className, innerRadius }) => {
    return (
      // className={`pie-graph-container ${className}`}
      <Flex style={{boxShadow: '2px 2px 3px #C4C4C4'}} mb={50} w={360} h={300} border='1px solid #e0e0e0'  id='pie-chart-legend-wrapper'>
        <Chart
          data={data}
          width={360}
          height={360}
        >
          <PieSeries
            valueField={valueKey}
            argumentField={titleKey}
            innerRadius={innerRadius}
          />
          <Legend
            position='right'
          />
          { title && <Title text={title} />}
          <Animation />
          <EventTracker />
          <Tooltip />
        </Chart>
      </Flex>
    );
};

PieGraph.defaultProps = {
  title: null,
}
