import React, { useState, Fragment } from 'react';
import { Formik } from 'formik';

import './login.css';
import { loginUser } from '../../api/index'
import HomeHeader from '../../components/HomeHeader';


const Login = ({ handleLoginSuccess }) => {
  const [loginError, setLoginError] = useState(null);

  function handleLogin(e){
    e.preventDefault()
    let email = e.target.email.value;
    let password = e.target.password.value;

    if(!email || !password){
        let message = 'please check all fields are correct'
        setLoginError(message)
        return
    };

    loginUser(email, password).then((result) => {
      handleLoginSuccess(result.data)
    }).catch((error) => {
      let message = null
      let defaultMessage = 'sorry something went wrong please check email & password are correct and try again';
      if(!!error?.response?.data?.message && error?.response?.data?.message != ''){
        message = error.response.data.message
      };
      setLoginError(message || defaultMessage);
    });
  }

  return (
  <Fragment>
    <body id='login-page-container'>
      <header>
        <HomeHeader />
      </header>

      <section className='login-sign-up-section-container'>

        <div className='login-signup-intro-subtitle text-center'>
          <p className='login-signup-welcome-text-1 m-0 p-0 m-auto'>Welcome!</p>
          <p className='login-signup-welcome-text-2'>Complete the form below to login to your account</p>
        </div>

        <Formik
               initialValues={{ email: '', password: '' }}
               validate={values => {
                 // first clear all of the server side login error messages
                 setLoginError(null);

                 const errors = {};

                 // validate email
                 if (!values.email) {
                   errors.email = 'Required';
                 } else if (
                   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                 ) {
                   errors.email = 'Invalid email address';
                 }

                 // validate password
                 if (!values.password){
                   errors.password = 'Required'
                 }

                 return errors;
               }}
               onSubmit={(values, { setSubmitting }) => {
                 setTimeout(() => {
                   alert(JSON.stringify(values, null, 2));
                   setSubmitting(false);
                 }, 400);
               }}
             >
               {({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 /* and other goodies */
               }) => (
                 <form onSubmit={handleLogin} className="login-sign-up-form-container">

                  <div className='login-input-group'>
                     <label htmlFor="email">Email Address</label>
                     <input
                       type="email"
                       name="email"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.email}
                       className="login-field"
                     />
                     <span className='login-sign-up-error'>{errors.email && touched.email && errors.email}</span>
                  </div>

                   <div className='login-input-group'>
                     <label htmlFor="password">Password</label>
                     <input
                       type="password"
                       name="password"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.password}
                       className="login-field"
                     />
                     <span className='login-sign-up-error'>{errors.password && touched.password && errors.password}</span>
                   </div>

                   <button type="submit" disabled={isSubmitting} className="login-sign-up-button">
                     Log In
                   </button>
                 </form>
               )}
        </Formik>
        <p className='login-signup-error text-center'>{loginError}</p>
        <p className='text-center m-0'>Don't have an account</p>
        <p className='text-center m-0 underline'><a href='/sign-up'>Click to create an account</a></p>
      </section>
    </body>
  </Fragment>
  )
};

export default Login

// add validation on props: props validation
// add prop types
