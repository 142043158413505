import { useState, useEffect, Fragment } from 'react';
import ReactGA from 'react-ga';

import './institution-search.css';

import HomeHeader from '../../components/HomeHeader';
import SearchBar from '../../components/SearchBar';
import CustomSlider from '../../components/CustomSlider';
import SelectField from '../../components/SelectField';
import InstitutionSearchResult from '../../components/InstitutionSearchResult';
import InstitutionSearchResultModal from '../../components/InstitutionSearchResultModal';
import INSTITUTIONS from '../../constants/institutions';
import Drawer from '@material-ui/core/Drawer';
import { handleRedirect, goToAppIfLoggedIn, isUserLoggedIn, isMobile, isWeb, getUniqueLocations } from '../../utilities';
import { useApiGetSchools } from '../../api'

var classNames = require('classnames');

const filterData = {
  locations: {
    options: getUniqueLocations(INSTITUTIONS),
    name: "locations"
  },
  accommodations: {
    options: ["yes", "no"],
    name: "accommodations"
  },
  openApps: {
    options: ["yes", "no"],
    name: "open applications"
  },
  govSponsored: {
    options: ["yes", "no"],
    name: "gov't sponsored"
  }
};

const InstitutionSearch = () => {
  goToAppIfLoggedIn();
  const userLoggedIn = isUserLoggedIn();
  const [searchTerm, setSearchTerm] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    location: null,
    accommodations: null,
    openApps: null,
    govSponsored: null,
    fee: null,
    searchTerm: null,
  });
  const [filteredList, setFilteredList] = useState(INSTITUTIONS);
  const [drawerItem, setDrawerItem] = useState(null);
  const [drawerState, setDrawerState] = useState(false);
  const {data: schools, isLoading:getSchoolsIsLoading, error:getShoolsError} = useApiGetSchools()

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(()=>{
    if(!!schools){
      let newList = schools.filter((item)=>{
        return (
          (activeFilters.location === null  || matchSelectedLocation(item, activeFilters.location)) &&
          (activeFilters.accommodations === null  || item.accommodations === activeFilters.accommodations) &&
          (activeFilters.openApps === null  || item.accepting_applications === activeFilters.openApps) &&
          (activeFilters.govSponsored === null  || item.gov_sponsored === activeFilters.govSponsored) &&
          (activeFilters.fee === null  || item.fee <= activeFilters.fee) &&
          (activeFilters.searchTerm === null || matchSearchResults(item, activeFilters.searchTerm))
        );
      });

      setFilteredList(newList);
    }
  }, [activeFilters, schools])

  function matchSearchResults(item, searchTerm){
    let param = searchTerm.toLowerCase()
    return matchSelectedLocation(item, param) ||
      item.title.toLowerCase().includes(param) || param === ""
  }

  function matchSelectedLocation(item, searchTerm){
    return item.locations.some((location)=>location.toLowerCase()===searchTerm.toLowerCase())
  }

  function handleSelectSearchTerm(searchTerm){
    setActiveFilters({...activeFilters, searchTerm: searchTerm})
  }

  function handleSelectLocation(location){
    setActiveFilters({...activeFilters, location: location})
  }

  function handleSelectAccomodations(accommodations){
    let value = accommodations === null ? null : accommodations === "yes";
    setActiveFilters({...activeFilters, accommodations: value})
  }

  function handleSelectOpenApps(openApps){
    let value = openApps === null ? null : openApps === "yes";
    setActiveFilters({...activeFilters, openApps: value})
  }

  function handleSelectGovSponsored(govSponsored){
    let value = govSponsored === null ? null : govSponsored === "yes";
    setActiveFilters({...activeFilters, govSponsored: value})
  }

  function handleSelectFee(fee){
    setActiveFilters({...activeFilters, fee: fee})
  }

  function openSearchResultDrawer(item){
    setDrawerItem(item)
    setDrawerState(true)
  }

  return (
    <body id="institution-search-page-container">
      <header>
        <HomeHeader />
      </header>

      <section className="d-flex flex-column col-md-8 col-sm-12 m-auto" id="institution-search-container" >
        <p className="font-24">Browse Institutions</p>
        <SearchBar
          placeholder="Search by name or location..."
          handleInputChange={handleSelectSearchTerm}
        />
      </section>

      <section className="d-flex flex-row flex-wrap col-md-8 col-sm-12 m-auto" id="institution-search-filters">
        <SelectField data={filterData.locations} handleSelect={handleSelectLocation}/>
        <SelectField data={filterData.accommodations} handleSelect={handleSelectAccomodations}/>
        <SelectField data={filterData.openApps} handleSelect={handleSelectOpenApps}/>
        <SelectField data={filterData.govSponsored} handleSelect={handleSelectGovSponsored}/>
        <CustomSlider title={'application fee'} handleSelect={handleSelectFee}/>
      </section>

      <section
        className={classNames( "d-flex flex-row flex-wrap col-md-8 col-sm-12 m-auto", {'web': isWeb}, {'mobile': isMobile} )}
        id="institution-search-results">
        {filteredList.map((item, idx)=>{
          return <div className={classNames("insitution-search-result-item-wrapper", {'mobile': isMobile})}  onClick={()=>openSearchResultDrawer(item)} key={idx}>
              <InstitutionSearchResult
                title={item.title}
                location={item.locations}
                fee={item.fee}
                acceptingApps={item.accepting_applications}
                imgSrc={item.logo_image_src} />
            </div>
        })}
        </section>

        <section>
          <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={drawerState}
            onClose={()=>setDrawerState(false)}
            className='institution-search-result-drawer'
            id={classNames({'institution-search-result-web-drawer': !isMobile})}
          >
              {drawerItem && <InstitutionSearchResultModal item={drawerItem} toggleModal={setDrawerState}/>}
          </Drawer>
        </section>
      </body>
  )
};

export default InstitutionSearch;
