import { useHistory } from "react-router-dom";
import { useRecoilValue, useRecoilState } from 'recoil';

import { getLoginSessionStorage } from './utilities';
import {
  isUserLoggedInAtom,
  loginErrorAtom,
  userAtom,
  userTypeAtom,
  schoolAtom,
  personalDetailsAtom,
  nextOfKinDetailsAtom,
  academicDetailsAtom,
  studentProfileDocumentsAtom
} from './state';

import {
  setLoginSessionStorage,
  goToAppIfLoggedIn,
  isUserLoggedIn,
} from './utilities';

import {
  STUDENT_USER_TYPE,
  SCHOOL_USER_TYPE
} from './api/constants'

import Login from './pages/login/index';

const Auth = () => {
  const [userState, setUserState] = useRecoilState(userAtom)
  const [userType, setUserTypeState] = useRecoilState(userTypeAtom)
  const [schoolState, setSchoolState] = useRecoilState(schoolAtom)
  const [studentPersonalDetails, setStudentPersonalDetails] = useRecoilState(personalDetailsAtom)
  const [studentNextOfKinDetails, setStudentNextOfKinDetails] = useRecoilState(nextOfKinDetailsAtom)
  const [studentAcademicDetails, setStudentAcademicDetails] = useRecoilState(academicDetailsAtom)
  const [studentDocuments, setStudentDocuments] = useRecoilState(studentProfileDocumentsAtom)
  const history = useHistory();

  const handleLoginSuccess = (data) => {
    const { user_type, school, user, personal_details, next_of_kin_details, academic_details, documents } = data;
    setUserTypeState(user_type);
    setLoginSessionStorage(true);

    if(user_type === 'student'){
      setUserState(data);
      if(personal_details){
        setStudentPersonalDetails(personal_details)
      }
      if(next_of_kin_details){
        setStudentNextOfKinDetails(next_of_kin_details)
      }
      if(academic_details){
        setStudentAcademicDetails(academic_details)
      }
      if(documents){
        setStudentDocuments(documents)
      }
    } else if (user_type === 'school_admin') {
      setSchoolState(school);
      setUserState(user);
    }

    if(user_type === SCHOOL_USER_TYPE){
        history.push("/app/profile");
    } else {
        history.push('/app/student/profile')
    }
  }

  return (
    <div className="App">
      <header className="header"></header>
      <Login handleLoginSuccess={handleLoginSuccess} />
    </div>
  );
};


export default Auth;
