import { Fragment, useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';

import CloseIcon from '../../assets/js-icons/CloseIcon';

import { updateStudentApplicationStatusQuery } from '../../api';

const ApplicationStatusView = ({ details }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeCheckBox, setActiveCheckBox] = useState(0);
  const [status, setStatus] = useState(null)
  const [lastReviewedOn, setLastReviewedOn] = useState(null);
  const { applicationId } = details;

  useEffect(() => {
    if (status) {
      statuses.forEach((item, idx) => {
        if (item?.name === status) {
          setActiveCheckBox(idx)
        }
      })
    }
  }, [])

  useEffect(() => {
    setStatus(details?.status)
    setLastReviewedOn(details?.lastReviewedOn)
  }, [details])

  const statuses = [
    {
      name: 'not-reviewed',
      color: 'black',
    }, {
      name: 'in-review',
      color: '#0094FF',
    }, {
      name: 'accepted',
      color: '#00C981',
    }, {
      name: 'rejected',
      color: '#FF8000',
    }
  ];


  const handleOnCheck = (index) => {
    setActiveCheckBox(index)
  }

  const handleSaveReview = () => {
    var today = new Date();
    var newStatus = statuses[activeCheckBox].name;

    setStatus(newStatus)
    setLastReviewedOn(today)

    updateStudentApplicationStatusQuery({
      applicationId,
      status: newStatus,
      lastReviewedOn: today
    })
    setModalOpen(false)
  }

  const getStatusColor = (status) => {
    const statusDetails = statuses?.find((item) => item?.name?.toLowerCase() === status?.toLowerCase())
    return statusDetails?.color || '000'
  }

  const reviewedOnString = lastReviewedOn ? new Date(lastReviewedOn)?.toLocaleString() : null;

  return (
    <Fragment>
      <div className='mx-auto application-status-container' style={{ width: '90%' }}>
        <div className='d-flex flex-row align-items-center'>
          <button className='review-button mr-30' onClick={() => setModalOpen(true)}>Review Application</button>
          <p className='m-0 mr-10'>application status - </p>
          <p className='m-0 mr-30 bold'
            style={{ color: getStatusColor(status) }}
          >{status ? status : 'not reviewed'}</p>

          <p className='m-0 mr-10'>last reviewed - </p>
          <p className='m-0 mr-30 bold'>{reviewedOnString}</p>
        </div>
      </div>
      <Modal
        open={modalOpen}
      >
        <div className='app-select-status-modal'>
          <div>
            <a onClick={() => setModalOpen(false)}><CloseIcon /></a>
          </div>
          <div className='d-flex flex-column mx-auto'>
            <h1>review application</h1>
            <div className='app-select-statuses-wrapper mx-auto'>
              {
                statuses.map((s, index) => {
                  return (
                    <div className='app-select-status d-flex flex-row align-items-center'>
                      <input
                        type='checkbox'
                        className='mr-30 app-select-status-checkbox'
                        checked={index === activeCheckBox}
                        onChange={() => handleOnCheck(index)}
                        name={s.name}
                      />
                      <p className='m-0' style={{ color: getStatusColor(s.name) }}>{s.name}</p>
                    </div>
                  )
                })
              }
            </div>
            <button className='save-review-btn' onClick={handleSaveReview}>save review</button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
};

export default ApplicationStatusView
