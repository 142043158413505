import React from 'react'

import {
  Flex,
  Select,
} from "@chakra-ui/react";

const SelectApplicationSection = ({ items, handleSelect, style }) => {
  return (
    <Flex style={style}>
        <Select
          borderRadius={0}
          bg='black'
          color='white'
          onChange={(e)=>handleSelect(e.target.value)}
          style={{height: 60}}
          >
          <option>TKI University Application</option>
          { items.map((item, index)=>{
            return <option value={`${index}`}>{ item }</option>
          }) }
        </Select>
    </Flex>
  )
};

export default SelectApplicationSection
