import {Fragment} from 'react';
import './index.css';


var classNames = require('classnames');

const COLUMN_SIZES = {
  'lg': 'col-12',
  'md': 'col-12',
  'sm': 'col-12',
};

const CONTAINER_SIZES = {
  'lg': 'col-md-8 col-12',
  'md': 'col-md-4 col-6',
  'sm': 'col-md-2 col-4'
}

const AppTextField = ({ size, label, value, mode }) => {
    return (
      <div className={classNames('application-text-field-container', 'col-xs-12')}>
        {!!label && <label>{ label }</label>}
        <input
          className={classNames('d-flex application-text-field', 'col-xs-12')}
          value={ value }
          disabled={ mode === 'view'}
          style={{width: '100%'}}
        />
      </div>
    );
};

export default AppTextField;
