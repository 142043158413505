import React, { useState, useEffect, useRef } from "react";
import HomeHeader from "../../components/HomeHeader";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import CustomSlider from "../../components/CustomSlider";
import SearchBar from "../../components/SearchBar";
import {
  getUniqueCourseTypes,
  getUniqueSponsorships,
  isMobile,
  isWeb,
} from "../../utilities";
import CourseSearchResult from "../../components/CourseSearchResult";
import { useApiGetCourses } from "../../api";
import SelectField from "../../components/SelectField";
import Drawer from "@material-ui/core/Drawer";
import CourseSearchResultModal from "../../components/CourseSearchResultModal";

var classNames = require("classnames");

const CourseSearch = () => {
  const { data: courses, isLoading, error } = useApiGetCourses();
  const fitlerData = useRef({});
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    courseType: null,
    searchTerm: null,
    points: 0,
    sponsorshipPriority: null,
  });
  const [loading, setLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerItem, setDrawerItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (courses) {
      setFilteredCourses(courses);
      fitlerData.current = {
        courseTypes: {
          options: getUniqueCourseTypes(courses),
          name: "course types",
        },
        sponsorshipPriority: {
          options: getUniqueSponsorships(courses),
          name: "sponsorship priority",
        },
      };
      setLoading(false);
    }
  }, [courses]);

  useEffect(() => {
    if (courses) {
      const filteredCourses = courses.filter((course) => {
        return (
          (activeFilters.courseType === null ||
            (course.course_type !== null &&
              course.course_type.toLowerCase() === activeFilters.courseType)) &&
          (activeFilters.searchTerm === null ||
            (course.title !== null &&
              course.title
                .toLowerCase()
                .includes(activeFilters.searchTerm.toLowerCase()))) &&
          (activeFilters.points === 0 ||
            (course.cut_off_points !== null &&
              course.cut_off_points >= activeFilters.points)) &&
          (activeFilters.sponsorshipPriority === null ||
            (course.sponsorship_priority !== null &&
              course.sponsorship_priority.toLowerCase() ===
                activeFilters.sponsorshipPriority.toLowerCase()))
        );
      });
      setFilteredCourses(filteredCourses);
    }
  }, [activeFilters, courses]);

  const openSearchResultDrawer = (item) => {
    setDrawerItem(item);
    onOpen();
  };

  if (error) return <Text>An error has occurred</Text>;
  if (loading || isLoading) return <Text>Loading</Text>;
  if (!courses) return <Text>An error has occurred </Text>;
  return (
    <>
      <div id="institution-search-page-container">
        <HomeHeader />
        <Flex w="100%" flexDir={"column"} alignSelf={"center"}>
          <section
            className="d-flex flex-column col-md-8 col-sm-12 m-auto"
            id="institution-search-container"
          >
            <Text fontSize={24}>Browse Courses</Text>
            <SearchBar
              placeholder="Search by course name"
              handleInputChange={(e) =>
                setActiveFilters({ ...activeFilters, searchTerm: e })
              }
            />
          </section>
          <section
            className="d-flex flex-row flex-wrap col-md-8 col-sm-12 m-auto"
            id="institution-search-filters"
          >
            <SelectField
              data={fitlerData.current.courseTypes}
              handleSelect={(e) =>
                setActiveFilters({ ...activeFilters, courseType: e })
              }
            />
            <SelectField
              data={fitlerData.current.sponsorshipPriority}
              handleSelect={(e) =>
                setActiveFilters({ ...activeFilters, sponsorshipPriority: e })
              }
            />
            <Flex ml={3}>
              <CustomSlider
                title={"points cutoff"}
                max={55}
                defaultValue={0}
                handleSelect={(value) =>
                  setActiveFilters({ ...activeFilters, points: value })
                }
              />
            </Flex>
          </section>
          <Flex
            justifyContent={"space-between"}
            className={classNames(
              "d-flex flex-row flex-wrap col-md-8 col-sm-12 m-auto",
              { web: isWeb },
              { mobile: isMobile }
            )}
            id="course-search-results"
          >
            {filteredCourses.map((item, index) => (
              <CourseSearchResult
                action={() => openSearchResultDrawer(item)}
                key={index}
                title={item.title}
              />
            ))}

            <section>
              {drawerItem && (
                <CourseSearchResultModal
                  course={drawerItem}
                  isOpen={isOpen}
                  onClose={onClose}
                />
              )}
              {/* <Drawer
                anchor={isMobile ? "bottom" : "right"}
                open={drawerState}
                onClose={() => setDrawerState(false)}
                className="course-search-result-drawer"
                id={classNames({
                  "course-search-result-web-drawer": !isMobile,
                })}
              >
                {drawerItem && (
                  <CourseSearchResultModal
                    item={drawerItem}
                    toggleModal={setDrawerState}
                  />
                )}
              </Drawer> */}
            </section>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default CourseSearch;
