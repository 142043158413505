import { Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Image } from '@chakra-ui/react';

import './applicant.css';
import '../../App.css';

import AppHeader from '../../../../components/AppHeader';
import NoPhoto from '../../../../assets/js-icons/NoPhoto.js';
import PrinterIcon from '../../../../assets/js-icons/PrinterIcon.js';
import DocumentIcon from '../../../../assets/js-icons/DocumentIcon.js';
import BackButton from '../../../../components/BackButton';

import { formatStdDate, formateDashDate } from '../../../../utilities';
import { selectStudentApplicant } from '../../../../state/selectors';
import { getDocumentsFromStudentApplication, getDocumentIcon } from '../../../../formatters';

const Applicant = ({ match, prevProps }) => {
  const studentId = match.params.student_id;
  const applicant = useRecoilValue(selectStudentApplicant(studentId));
  const [graduationDate, setGradDate] = useState(null);
  const [appliedOn, setAppliedOn] = useState(null);
  const [documents, setDocuments] = useState([]);

  const handleDocLink = (url)=>{
    if (url) {
      window.open(url, '_blank').focus();
    }
  };

  const renderDocument = (document) => {
    return (
      <div className='col-md-6 applicant-document' onClick={() => handleDocLink(document?.url)}>
        {getDocumentIcon(document?.docType)}
        <p className='app-document-title'>{document?.title}</p>
        <div className='m-0 p-0 app-document-printer'>
          <PrinterIcon />
        </div>
        <p className='app-document-date'>{appliedOn}</p>
      </div>
    );
  };

  useEffect(() => {
    if (applicant) {
      var gradDate = formatStdDate(applicant?.last_school_attended?.graduation_date);
      // TODO: replace applied on with rails date
      var appDate = formateDashDate(applicant?.applied_on);

      setGradDate(gradDate);
      setAppliedOn(appDate);
    };
    setDocuments(getDocumentsFromStudentApplication(applicant?.form));
  }, [applicant]);

  return (
    <Fragment>
      <body className='d-md-flex' id='applicant-page'>
        <header id='app-header-container' className='col-xs-12 col-md-3'>
          <AppHeader />
        </header>
        {applicant &&
          <div className='app-body-container col-xs-12'>
            <section className='applicant-profile-summary col-12 col-md-7 p-0'>
              <BackButton className='mb-30' />
              <p className='applicant-section-title'>Student Profile</p>
              <div className='d-flex flex-direction-column justify-content-between pl-10'>
                {!applicant?.profile_photo && <div><NoPhoto /></div>}
                {!!applicant?.profile_photo && <Image width={145} height={145} src={applicant?.profile_photo} />}
                <div>
                  <p><b>{applicant?.full_name}</b></p>
                  <p>{applicant?.email}</p>
                </div>
                <div>
                  <p><b>Last School Attended</b></p>
                  <p>{applicant?.last_school_attended}</p>
                  <p>graduated on: {graduationDate}</p>
                </div>
              </div>
            </section>

            <section className='applicant-profile-application d-flex flex-column col-10 p-0'>
              <p className='applicant-section-title'>Applications</p>
              <NavLink to={`/app/application/${studentId}`}>
                <div className='d-flex align-items-center applicant-application-mini'>
                  <DocumentIcon />
                  <p>Course 1st choice - {applicant?.first_academic_choice}</p>
                  <p>{applicant?.points} - points</p>
                  <p>{applicant?.status}</p>
                </div>
              </NavLink>
            </section>

            <section className='applicant-profile-documents'>
              <p className='applicant-section-title'>Documents</p>
              {documents && documents.map((doc)=>renderDocument(doc))}
            </section>
          </div>
        }
      </body>
    </Fragment>
  )
};

export default Applicant;
