import { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";

import { useRecoilValue, useRecoilState } from 'recoil'
import { userAtom } from '../../../../state'
import { selectStudentApplicationBySchoolId } from '../../../../state/selectors';
import { createStudentApplication } from '../../../../api';

import AppHeader from '../../../../components/AppHeader'
import PageForm from '../../../../components/PageForm'
import FormDetailSummary from '../../../../components/FormDetailSummary'
import CompleteAllSectionsModal from '../../../../components/CompleteAllSectionsModal'

import Templates from '../../../../constants/application-templates'

const styles = {
  pageContainer:{
    backgroundColor: '#dfe6e9',
    height: '100vh'
  },
  headerWrapper:{
    backgroundColor: 'white',
  },
  contentContainer: {
    width: '100%',
    marginTop:40
  },
  Button: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: 0,
    fontSize: 12,
  },
  title:{
    marginBottom: 16
  },
  buttonWrapper:{
    marginTop: 40,
    margin: 8
  },
  reviewButton:{
    width: '100%',
    height: 50,
    borderRadius: 0,
    backgroundColor: '#2ecc71'
  }
}


const StudentApplicationsSchoolForm = ({ match })=>{
  const BASE_URL = window.location.pathname
  const user = useRecoilValue(userAtom);
  const schoolId = match.params.school_id;
  const [ studentApplication, setStudentApplication ] = useRecoilState(selectStudentApplicationBySchoolId(schoolId));
  const [ incompleteSections, setIncompleteSections ] = useState([]);
  const [ openModal, setOpenModal ] = useState(false);
  let appSections = null;

  appSections = studentApplication?.sections;

  const handleReviewApplication = ()=>{
    if(incompleteSections.length > 0){
      setOpenModal(true)
    } else {
      window.location.replace(`/app/student/applications/review/${schoolId}`)
    }
  };

  const handleResetModal = () => {
    setOpenModal(false)
  }

  const getIsSectionCompleted = (section) => {
    let isComplete = null;

    if(section.type === 'standard' || section.type === 'signature'){
      isComplete = !section?.fields?.find((i)=>!i.value);
    } else if (section.type === 'academics') {
      isComplete = section?.grades?.length > 0;
    } else if (section.type === 'document' || section.type === 'documents') {
      isComplete = !section?.fields?.find((i)=>!i.value);
    };

    // set incompleteSections
    if(!isComplete && !incompleteSections.includes(section.title)){
      setIncompleteSections([...incompleteSections, section.title])
    }
    return isComplete;
  };

  return (
    <Flex flexDir={"column"} style={styles.pageContainer}>
      <Flex style={styles.headerWrapper}>
        <AppHeader />
      </Flex>

      <Flex flexDir='column' className='text-center justify-content-center' style={styles.contentContainer}>
        <Text style={styles.title}>{ studentApplication?.title }</Text>
        {appSections && appSections.map((section) =>
          <FormDetailSummary
            containerStyles={{backgroundColor: 'white'}}
            link={`${BASE_URL}/${section.id}`}
            title={section.title}
            isComplete={getIsSectionCompleted(section)}
            />
        )}
      </Flex>
      <Flex style={styles.buttonWrapper}>
        <Button style={styles.reviewButton} onClick={handleReviewApplication}>Review & Submit</Button>
      </Flex>
      <CompleteAllSectionsModal openModal={openModal} sectionTitles={incompleteSections} handleOnClose={handleResetModal}/>
    </Flex>
  )
};

export default StudentApplicationsSchoolForm
