import React, { useEffect, useState } from "react";
import { Text, Textarea, Select, Input, Flex, Button, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import axios from "axios";
import AppHeader from "../../../../components/AppHeader";
import "../../App.css";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { coursesAtom } from "../../../../state";
import { API_BASE_URL } from "../../../../api/constants";

const EditCourse = () => {
  const courses = useRecoilValue(coursesAtom);
  const toast = useToast();
  const { id } = useParams();
  const [formSuccess, setFormSuccess] = useState(false);
  const [course, setCourse] = useState({
    title: "",
    sponsorship_priority: "",
    course_type: "",
    graduate_level: "",
    cut_off_points: "",
    description: "",
    id: null,
  });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    let findCourse = courses.find((item) => item.id === parseInt(id));
    setCourse(findCourse);
  }, [id]);

  const editCourse = async (e) => {
    e.preventDefault();

    const params = {
      title: e.target.title.value,
      sponsorship_priority: e.target.sponsorship_priority.value,
      course_type: e.target.course_type.value,
      graduate_level: e.target.graduate_level.value,
      cut_off_points: e.target.cut_off_points.value,
      description: e.target.description.value,
    }

    if (
      !params.title ||
      !params.sponsorship_priority ||
      !params.course_type ||
      !params.cut_off_points ||
      !params.description ||
      !params.graduate_level
    ) {
      toast({
        title: "Error",
        description: "All fields are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const URL = `${API_BASE_URL}/courses/${id}`

    await axios.put(URL, params).then((res)=>{
      if (res.status.toString().startsWith('20')) {
        toast({
          title: "Success",
          description: "Course successfully updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "An error has occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }).catch((err)=>{
      toast({
        title: "Error",
        description: "An error has occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    })
  };

  return (
    <>
      <body className="d-md-flex">
        <AppHeader />
        <div
          className="col-xs-12 app-body-container"
          id="app-container"
        >
          <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
            <Flex>
              <Text fontSize={25} fontWeight={"500"}>
                Course
              </Text>
              {course ? (
                <Text ml={5} fontSize={25} fontWeight={300}>
                  {course.title}
                </Text>
              ) : (
                "loading"
              )}
            </Flex>
            <Flex mb={20} flexDir={"column"} mt={10}>
              {course.title.length > 0 ? (
                <>
                  <Formik
                    initialValues={{
                      title: course.title,
                      sponsorship_priority: course.sponsorship_priority,
                      course_type: course.course_type,
                      graduate_level: course.graduate_level,
                      cut_off_points: course.cut_off_points,
                      description: course.description,
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.title) errors.title = "Required";
                      if (!values.sponsorship_priority)
                        errors.sponsorship_priority = "Required";
                      if (!values.course_type) errors.course_type = "Required";
                      if (!values.graduate_level)
                        errors.graduate_level = "Required";
                      if (!values.cut_off_points)
                        errors.cut_off_points = "Required";
                      if (!values.description) errors.description = "Required";

                      if (Object.keys(errors).length === 0)
                        setFormSuccess(true);
                      else setFormSuccess(false);

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                    }) => (
                      <form onSubmit={editCourse}>
                        <Button
                          disabled={!editing}
                          _hover={{ bg: "#FFA800", color: "black" }}
                          h={55}
                          fontSize={15}
                          fontWeight={500}
                          w={200}
                          rounded={0}
                          color="white"
                          bg="black"
                          type="submit"
                        >
                          Save Changes
                        </Button>

                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Title
                        </Text>
                        <Input
                          onChange={(e) => {
                            if (e.target.value !== course.title) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          title="title"
                          name="title"
                          onBlur={handleBlur}
                          value={values.title}
                          rounded={0}
                          w={500}
                        />
                        <Text color="red.500">
                          {touched.title && errors.title}
                        </Text>
                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Sponsorship Priority
                        </Text>

                        <Select
                          onChange={(e) => {
                            if (
                              e.target.value !== course.sponsorship_priority
                            ) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          rounded={0}
                          title="sponsorship_priority"
                          name="sponsorship_priority"
                          onBlur={handleBlur}
                          w={120}
                        >
                          <option
                            value="High"
                            selected={
                              course.sponsorhip_priority === "High"
                                ? true
                                : false
                            }
                          >
                            High
                          </option>
                          <option
                            value="Medium"
                            selected={
                              course.sponsorship_priority === "Medium"
                                ? true
                                : false
                            }
                          >
                            Medium
                          </option>
                          <option
                            value="Low"
                            selected={
                              course.sponsorship_priority === "Low"
                                ? true
                                : false
                            }
                          >
                            Low
                          </option>
                        </Select>
                        <Text color="red.500">
                          {touched.sponsorship_priority &&
                            errors.sponsorship_priority}
                        </Text>
                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Course Type
                        </Text>
                        <Input
                          onChange={(e) => {
                            if (e.target.value !== course.course_type) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          title="course_type"
                          name="course_type"
                          rounded={0}
                          value={values.course_type}
                          w={300}
                        />
                        <Text color="red.500">
                          {touched.course_type && errors.course_type}
                        </Text>
                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Graduate Level
                        </Text>
                        <Select
                          onChange={(e) => {
                            if (e.target.value !== course.graduate_level) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          rounded={0}
                          title="graduate_level"
                          name="graduate_level"
                          w={200}
                        >
                          <option
                            selected={
                              course.graduate_level === "Undergraduate"
                                ? true
                                : false
                            }
                          >
                            Undergraduate
                          </option>
                          <option
                            selected={
                              course.graduate_level === "Postgraduate"
                                ? true
                                : false
                            }
                          >
                            Postgraduate
                          </option>
                        </Select>
                        <Text color="red.500">
                          {touched.graduate_level && errors.graduate_level}
                        </Text>
                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Points Cutoff
                        </Text>
                        <Input
                          onChange={(e) => {
                            if (
                              parseInt(e.target.value) !== course.cut_off_points
                            ) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          type="number"
                          title="cut_off_points"
                          name="cut_off_points"
                          onBlur={handleBlur}
                          value={values.cut_off_points}
                          w={70}
                          rounded={0}
                        />
                        <Text color="red.500">
                          {touched.cut_off_points && errors.cut_off_points}
                        </Text>
                        <Text mt={10} fontSize={25} fontWeight={600}>
                          Description
                        </Text>
                        <Textarea
                          onChange={(e) => {
                            if (e.target.value !== course.description) {
                              setEditing(true);
                            }
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          h={200}
                          title="description"
                          name="description"
                          minW={500}
                          value={values.description}
                          w={70}
                          rounded={0}
                        />
                        <Text mb={20} color="red.500">
                          {touched.description && errors.description}
                        </Text>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <Text>Loading...</Text>
              )}
            </Flex>
          </Flex>
        </div>
      </body>
    </>
  );
};

export default EditCourse;
