import axios from 'axios'
import { useState } from 'react'
import {
  Flex,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRecoilValue, useRecoilState } from 'recoil'
import { TrashIcon } from 'chakra-ui-ionicons';

import BackButton from '../../../../components/BackButton';
import AcademicDetailItem from '../../../../components/AcademicDetailItem';
import AddAcademicDetailModal from '../../../../components/AcademicDetailItem/AddAcademicDetailModal';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { userAtom, academicDetailsAtom } from '../../../../state';
import { selectStudentApplicationGrades } from '../../../../state/selectors'
import { API_BASE_URL } from '../../../../api/constants';
import AcademicDetailsDisplay from '../../../../components/AcademicDetailsDisplay'

import { removeItemFromArray } from '../../../../utilities';

const StudentApplicationsAcademicsFormSection = ({ appSection, schoolId, sectionId }) => {
  // const [academicDetails, setAcademicDetails] = useRecoilState(academicDetailsAtom)
  const [ academicDetails, setAcademicDetails ] = useRecoilState(selectStudentApplicationGrades({schoolId, sectionId}))
  const subjectsIsEmpty = academicDetails?.length === 0
  const [ openConfirmationModal, setConfirmationModal] = useState(false)
  const [ itemToBeRemoved, setItemToBeRemoved ] = useState(null)
  const user = useRecoilValue(userAtom)
  const toast = useToast()

  const handleNewAcademicDetail = (newItem)=>{
    const newItems = [...academicDetails, newItem]
    // add a new academic detail to the academic details atom
    setAcademicDetails(newItems)
  }

  const handleRemoveItemConfirmation = (isConfirmed) => {
    if(isConfirmed){
      const newItems = academicDetails.reduce((acc, item)=>{
        if(item.subject != itemToBeRemoved.subject){
          acc.push(item)
        }
        return acc
      }, [])
      setAcademicDetails(newItems)
      // set new academic details in atom
    }
    setItemToBeRemoved(null)
    setConfirmationModal(false)
  }

  const handleTrashItem = (idx) => {
    setItemToBeRemoved(academicDetails[idx])
    setConfirmationModal(true)
  }

  return (
    <>
      <Flex mt={4} mb={4}>
        <BackButton />
      </Flex>
      <AcademicDetailsDisplay
          handleNewItem={handleNewAcademicDetail}
          handleRemoveItem={handleRemoveItemConfirmation}
          academicDetails={academicDetails}
          setAcademicDetails={setAcademicDetails}
          openConfirmationModal={openConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          itemToBeRemoved={itemToBeRemoved}
          setItemToBeRemoved={setItemToBeRemoved}
          handleTrashItem={handleTrashItem}
        />
    </>
  )
};

export default StudentApplicationsAcademicsFormSection
