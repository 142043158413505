export const JOIN_SASA_URL = 'https://forms.clickup.com/f/a3vmd-1940/36SCHPQTB3M5DQIR85';
export const REQUEST_DEMO_URL = 'https://forms.clickup.com/f/a3vmd-1820/DME5EM9SQBQOS807P1';
export const TRACKING_ID = "UA-203971712-1";

// Category: Capitalize 1st letter and all letters for ABBREVS
// Actions: must be in Camel space Case ie: Log In
export const ANALYTICS_EVENTS = {
  JOIN_SASA_CTA: {
    category: 'CTA',
    action: 'Join Sasa'
  },
  REQUEST_DEMO_CTA: {
    category: 'CTA',
    action: 'Request Demo'
  },
  BROWSE_INSTITUTIONS_CTA: {
    category: 'CTA',
    action: 'Browse Institutions'
  },
}
