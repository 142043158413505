export const validatePresence = (input) => {
  if(typeof input === 'string'){
    return !!input.trim()
  } else {
    return !!input
  }
}

export const validateLength = (input, length) => {
  return input.toString()?.length > length
}

export const validateDateText = (input, format) => {
  if(input.split('/').length !== 3){
    return false
  }

  if(format === 'yyyy/mm/dd'){
    // return an array of errors if present in dateString input
    let errors = input.split('/').map((item, idx)=>{
      let int = parseFloat(item)
      if(idx === 0){
        return !_validateYear(int)
      } else if (idx === 1) {
        return !_validateMonth(int)
      } else if (idx === 2) {
        return !_validateDay(int)
      }
      return false
    });

    return errors.includes(true) ? false : true
  } else if(format === 'dd/mm/yyyy'){
    // return an array of errors if present in dateString input
    let errors = input.split('/').map((item, idx)=>{
      let int = parseFloat(item)
      if(idx === 0){
        return !_validateDay(int)
      } else if (idx === 1) {
        return !_validateMonth(int)
      } else if (idx === 2) {
        return !_validateYear(int)
      }
      return false
    });

    return errors.includes(true) ? false : true
  } else if(format === 'mm/dd/yyyy'){
    // return an array of errors if present in dateString input
    let errors = input.split('/').map((item, idx)=>{
      let int = parseFloat(item)
      if(idx === 0){
        return !_validateMonth(int)
      } else if (idx === 1) {
        return !_validateDay(int)
      } else if (idx === 2) {
        return !_validateYear(int)
      }
      return false
    });

    return errors.includes(true) ? false : true
  }

  function _validateYear(int){
    if(int.toString().length === 4){
      return true
    }
    return false
  }

  function _validateMonth(int){
    if(int <= 12 && int >= 1){
      return true
    }
    return false
  }

  function _validateDay(int){
    if(int <= 31 && int >= 1){
      return true
    }
    return false
  }
}
