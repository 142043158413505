import { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  Button,
  Input,
  Checkbox,
  Stack,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";

import SignatureField from '../SignatureField'
import DocumentUpload from '../DocumentUpload';


const CheckboxGroup = ({ title, values, groupName, handleFormikChange, handleInputChange, initialValue }) => {
  const [checkedIdx, setCheckedIdx] = useState(null);
  const onChange = (e, idx) => {
      const newValue = values?.[idx]?.title
      setCheckedIdx(idx)
      handleFormikChange(e)
      handleInputChange(groupName, newValue)
  };

  useEffect(()=>{
    const initialCheckedIdx = values.findIndex((v)=>v.name === initialValue)
    setCheckedIdx(initialCheckedIdx)
  }, [initialValue])

  return (
    <Flex mt={8} flexDir='column'>
      <Text>{title}</Text>
      <Flex>
        { values.map((item, idx)=>{
          return (
            <Checkbox
               isChecked={checkedIdx === idx}
               onChange={(e) => onChange(e, idx)}
               mr={6}
               name={groupName}
             >
               {item.title}
             </Checkbox>
          )
        })}
      </Flex>
    </Flex>
  );
};

const SelectGroup = ({title, values, initialValue, groupName, handleInputChange}) => {
  const onChange = (e)=>{
    handleInputChange(groupName, e.target.value)
  };

  return(
    <Flex flexDir='column' mt={8}>
      <Text>{title}</Text>
      <Select name={groupName} onChange={onChange}>
        {values.map((item)=>{
          const { name, title } = item
          return <option value={name} selected={initialValue === name}>{title}</option>
        })}
      </Select>
    </Flex>
  )
};

const TextInput = ({ title, name, values, handleInputChange, handleFormikChange, placeholder })=>{
  return (
    <Flex mt={8} flexDir='column' key={name}>
      <Text>{title}</Text>
      <Input
        onChange={(e)=>{
          handleInputChange(name, e.target.value)
          handleFormikChange(e)
        }}
        value={values[name]}
        name={name}
        placeholder={placeholder}
      />
    </Flex>
  )
};

const TextArea = ({ title, name, values, handleInputChange, handleFormikChange, placeholder })=>{
  return (
    <Flex mt={8} flexDir='column' key={name}>
      <Text>{title}</Text>
      <Textarea
        onChange={(e)=>{
          handleInputChange(name, e.target.value)
          handleFormikChange(e)
        }}
        value={values[name]}
        name={name}
        placeholder={placeholder}
      />
    </Flex>
  )
};

const FormTextDisplay = ({ name, title, value, handleInputChange }) => {
  return (
    <Flex flexDir='column'>
      {title && <>
        <Text>{title}</Text>
        <br></br>
        </>}
      {value && <>
        <Text>{ value}</Text>
        <br></br></>}
    </Flex>
  )
}

const AppForm = ({
  fields,
  handleSubmit,
  initialValues,
}) => {
  const [ formValues, setFormValues ] = useState(initialValues)
  const [ submitEnabled, enableSubmit ] = useState(false)
  const toast = useToast()

  const handleInputChange = (name, value) => {
    const newInput = {}
    newInput[name] = value
    setFormValues({...formValues, ...newInput})
    enableSubmit(true)
  }
  const [ errors, setErrors ] = useState({})

  const generateFormInput = (item, values, handleChange, errors) => {
    const { fieldType, name, title, options, placeholder, value } = item;
    const renderItem = ()=>{
      switch (fieldType) {
        case 'input':
          return <TextInput
              title={title}
              name={name}
              values={values}
              handleInputChange={handleInputChange}
              handleFormikChange={(e)=>handleChange(e)}
              placeholder={placeholder}
            />
            break;
        case 'textarea':
          return <TextArea
              title={title}
              name={name}
              values={values}
              handleInputChange={handleInputChange}
              handleFormikChange={(e)=>handleChange(e)}
              placeholder={placeholder}
            />
            break;
        case 'checkboxes':
          return <CheckboxGroup
              title={title}
              values={options.values}
              groupName={name}
              handleFormikChange={(e)=>handleChange(e)}
              handleInputChange={handleInputChange}
              initialValue={values?.[name]}
            />
            break;
        case 'select':
          return <SelectGroup
            title={title}
            values={options.values}
            groupName={name}
            handleInputChange={handleInputChange}
            initialValue={values?.[name]}
          />
          break;
        case 'signature':
          return <SignatureField
              initialValue={value}
              handleSignature={(value)=>handleInputChange(name, value)}
            />
        case 'text-display':
          return <FormTextDisplay
              name={name}
              title={title}
              value={value}
              handleInputChange={handleInputChange}
            />
            break;
        case 'document':
          return <DocumentUpload
              initialValue={value}
              title={title}
              onSuccess={(url)=>handleInputChange(name, url)}
              docType={options?.docType}
              styles={{marginTop: '30px'}}
            />
          break;
        default:
          return null
          break;
      }
    }

    return (
      <>
        {renderItem()}
        {errors?.[name] && <Text color="red.500">{errors[name]}</Text>}
      </>
    )
  }

  const onSubmit = (e) => {
      e.preventDefault();

      fields.forEach((item)=>{
        let error = null;

        if(item.fieldType === 'text-display'){
            formValues[item.name] = item.value
        };

        if(item?.validate){
          const input = formValues?.[item.name] ? formValues?.[item?.name] : ''

          error = item.validate(input)
          if(error){
            let setError = {}
            setError[item.name] = error
            setErrors(Object.assign(errors, setError))
          } else {
            errors[item.name] = null
          }
        }
      })

      handleSubmit(formValues)
      toast({
        title: "Success",
        description: "Saved!",
        status: "success",
        duration: 2000,
      })
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={()=>console.log('submited form')}
  >
    {({
      values,
      touched,
      handleChange,
      handleBlur,
    }) => (
      <form onSubmit={(e)=>onSubmit(e)}>
        {fields?.map((item) => generateFormInput(item, values, handleChange, errors))}
        <Button
          _hover={{ bg: "#FFA800", color: "black" }}
          h={55}
          fontSize={15}
          fontWeight={500}
          mt={10}
          w={'100%'}
          rounded={0}
          color="white"
          bg="black"
          type="submit"
          disabled={!submitEnabled}
        >
          Save Changes
        </Button>
      </form>
    )}
  </Formik>
};

export default AppForm
