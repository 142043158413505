import { Fragment } from 'react';
import { Paper, Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import './home-intro-carousel.css';
import { isMobile, isWeb } from '../../utilities';

import institutionSearchWeb from '../../assets/images/institution-search-cropped.png';
import institutionSearchMobile from '../../assets/images/institution-search-mobile.png';

import studentProfileWeb from '../../assets/images/student-profile-cropped.png';
import studentProfileMobile from '../../assets/images/student-profile-mobile.png';


var classNames = require('classnames');


const ITEMS = [
  {
    title: 'Discover Institutions in Eswatini',
    description: 'get university details that will help you make the best decision on which school to attend',
    image: {
      web: {
        src: institutionSearchWeb,
      },
      mobile: {
        src: institutionSearchMobile,
      },
    },
  },
  {
    title: 'Discover Institutions in Eswatini',
    description: 'get university details that will help you make the best decision on which school to attend',
    image: {
      web: {
        src: studentProfileMobile,
      },
      mobile: {
        src: studentProfileMobile,
      },
    },
  },
]

const Item = ({ title, description, image }) => {
  return (
    <div className='home-carousel-item'>
      <div className={classNames(
          'home-carousel-image-container d-flex align-items-center',
          { 'web': isWeb }
        )}>
        { !!isWeb && <img src={image.web.src} width={'100%'} /> }
        { !!isMobile && <img src={image.mobile.src} className='m-auto' height={300} width='100%'/> }
      </div>
      <p className='text-center title'>{ title }</p>
      <p className='text-center description'>{ description }</p>
    </div>
  )
}

const HomeIntroCarousel = () => {
  return (
    <Carousel className='m-auto'>
      {
        ITEMS.map((item, i) => <Item key={i} {...item}/>)
      }
    </Carousel>
  )
}

export default HomeIntroCarousel
