import './institution-search-result.css';
import openIcon from '../../assets/open-icon@32px.png';
import { isMobile, isWeb, getUniqueLocations } from '../../utilities';
import {
  Flex,
  Image
} from '@chakra-ui/react';

var classNames = require('classnames');

const InstitutionSearchResult = ({ title, fee, acceptingApps, imgSrc }) => {
  return (
    <Flex className={classNames("institution-search-result-mini-container", { 'web': isWeb }, { 'mobile': isMobile })}>
      <Image src={imgSrc} width={50} height={50} className="institution-search-result-image" />
      <div>
        <p className="institution-search-result-title">{title}</p>
        <div className="d-flex flex-row institution-search-section-summary">
          {/*<p className="font-11">application fee: {fee}</p>*/}
          <p className="font-11">accepting applications: {!!acceptingApps ? 'yes' : 'no'}</p>
        </div>
      </div>
      {/*<img src={openIcon} width={20} height={20} className="institution-search-open-icon"/>*/}
    </Flex>
  )
};

export default InstitutionSearchResult;
