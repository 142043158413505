import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import {
  Flex,
  Text,
  Circle,
  Image,
} from "@chakra-ui/react";

import { userAtom } from '../../state';
import {
  CLOUDINARY_NAME,
  CLOUDINARY_STUDENTS_PROFILE_PHOTO,
  UPLOAD_PRESET,
  API_BASE_URL,
} from '../../api/constants';

const PhotoUploadAndDisplay = ()=> {
  const user = useRecoilValue(userAtom);
  const [photoUrl, setPhotoUrl] = useState(user?.profile_photo);
  const toast = useToast()

  const handleUpload = (err, res) => {
    if(res?.event === 'success'){
      const url =  res.info?.url
      const photo = url?.split('upload').join('upload/w_1000,ar_1:1,c_fill,g_auto')
      const data = {profile_photo: photo}

      setPhotoUrl(photo);

      axios.post(`${API_BASE_URL}/students/${user.student_id}/profile-photo`, data)
      .then((res) => {
          if (res.status.toString().startsWith('20')) {
            // Toast success message
            toast({
              title: "Success",
              description: "photo uploaded successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        }).catch((e) => {
          toast({
            title: "Error",
            description: "Something went wrong, please try again",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      });

    }
  };

  let widget = window.cloudinary.createUploadWidget({
      cloudName: CLOUDINARY_NAME,
      folder: CLOUDINARY_STUDENTS_PROFILE_PHOTO,
      uploadPreset: UPLOAD_PRESET
    },
    (error, result) => {handleUpload(error, result)}
  );

  const showWidget = ()=>{
    widget.open()
  }
  return (
    <Flex onClick={showWidget}>
      {!photoUrl && <Circle size='160px' bg='tomato' color='white'>
        <Text>click to upload photo</Text>
      </Circle>}
      {photoUrl && <Image
          borderRadius='full'
          boxSize='150px'
          src={photoUrl}
          alt='Profile Photo'
          boxShadow='md'
        />}
    </Flex>
  )
}

export default PhotoUploadAndDisplay
