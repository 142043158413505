import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import './index.css';
import { TRACKING_ID } from './constants';


import reportWebVitals from './reportWebVitals';

import Auth from './Auth';
import Home from './pages/home/index.js';
import SignUp from './pages/signup/index.js';
import InstitutionSearch from './pages/institution-search/index.js';
import Applicant from './pages/app/institutions/applicant/index.js';
import Applicants from './pages/app/institutions/applicants/index.js';
import Applications from './pages/app/institutions/applications/index.js';
import Profile from './pages/app/institutions/profile/index.js';
import Dashboard from './pages/app/institutions/dashboard/index.js';
import App from './pages/app/App.js';
import { default as StudentProfile } from './pages/app/students/profile';
import { default as StudentApplicationsHome } from './pages/app/students/student-applications-home';
import { default as StudentApplicationsBrowse } from './pages/app/students/student-applications-browse';
import { default as StudentApplicationsSchoolForm } from './pages/app/students/student-applications-school-form';
import { default as StudentApplicationsFormSection } from './pages/app/students/student-applications-form-section';
import { default as StudentApplicationsReview } from './pages/app/students/student-applications-review';
import StudentPersonalDetails from './pages/app/students/profile/personal-details';
import StudentNextOfKinDetails from './pages/app/students/profile/next-of-kin-details';
import StudentAcademicDetails from './pages/app/students/profile/academic-details';
import Courses from './pages/app/institutions/course/courses';
import Course from './pages/app/institutions/course';
import ProfileEdit from './pages/app/institutions/profile_edit';
import EditCourse from './pages/app/institutions/course/edit_course';
import NewCourse from './pages/app/institutions/course/new_course';
import PrivateRoute from './PrivateRoute';
import Login from './pages/login';
import CourseSearch from './pages/course-search';
import StudentDocuments from './pages/app/students/profile/documents';
import AdminTemplate from './pages/admin/AdminTemplate';
// INITIALIZE GOOGLE ANALYTICS
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RecoilRoot>
        <RecoilNexus />
          <ChakraProvider>
              <Route exact path="/">
                <Home />
              </Route>

              <Route exact path="/login">
                <Auth />
              </Route>

              <Route exact path="/sign-up">
                <SignUp />
              </Route>

              <Route exact path="/search">
                <InstitutionSearch />
              </Route>

              <Route exact path="/courses">
                <CourseSearch />
              </Route>

              {/* // INSTITUTION APP PAGES */}
              <PrivateRoute exact path='/app/profile' component={Profile} />
              <PrivateRoute exact path='/app/profile/edit' component={ProfileEdit} />
              <PrivateRoute exact path='/app/dashboard' component={Dashboard} />
              <PrivateRoute exact path='/app/applicants' component={Applicants} />
              <PrivateRoute exact path='/app/courses' component={Courses} />
              <PrivateRoute exact path='/app/courses/:id' component={Course} />
              <PrivateRoute exact path='/app/courses/:id/edit' component={EditCourse} />
              <PrivateRoute exact path='/app/new/course' component={NewCourse} />
              <PrivateRoute exact path='/app/applicants/:student_id' component={Applicant} />
              <PrivateRoute exact path='/app/application/:student_id' component={Applications} />

              {/* // STUDENT APP PAGES */}
              <PrivateRoute exact path='/app/student/profile' component={StudentProfile} />
              <PrivateRoute exact path='/app/student/profile/personal-details' component={StudentPersonalDetails}/>
              <PrivateRoute exact path='/app/student/profile/academic-details' component={StudentAcademicDetails} />
              <PrivateRoute exact path='/app/student/profile/next-of-kin-details' component={StudentNextOfKinDetails}/>
              <PrivateRoute exact path='/app/student/applications' component={StudentApplicationsHome} />
              <PrivateRoute exact path='/app/student/applications/new' component={StudentApplicationsBrowse} />
              <PrivateRoute exact path='/app/student/applications/start/:school_id' component={StudentApplicationsSchoolForm} />
              <PrivateRoute exact path='/app/student/applications/start/:school_id/:section_id' component={StudentApplicationsFormSection} />
              <PrivateRoute exact path='/app/student/applications/review/:school_id' component={StudentApplicationsReview} />
              <PrivateRoute exact path='/app/student/profile/documents' component={StudentDocuments} />

              {/* Admin Page */}
              <PrivateRoute exact path='/admin/get-template/:key' component={AdminTemplate} />
          </ChakraProvider>
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
