import PdfIcon from '../assets/js-icons/PdfIcon.js';
import PhotoIcon from '../assets/js-icons/PhotoIcon.js';

export const formatDocumentSection = (section) => {
    return section?.fields?.map((field) => {
        return {
            name: field?.name,
            title: field?.title,
            docType: field?.options?.docType,
            url: field?.value,
        };
    });
};

export const getDocumentsFromStudentApplication = (form) => {
    const docSection = form?.sections?.find((section) => section?.type === 'document');
    if (docSection) {
        return formatDocumentSection(docSection);
    };
    return [];
};

export const getDocumentIcon = (docType) => {
    switch (docType) {
        case 'pdf':
            return <PdfIcon />
            break;
        case 'jpg' || 'jpeg':
            return <PhotoIcon />
            break;
        default:
            return null
            break;
    }
}