
import { selectorFamily, atom, get, set } from 'recoil';
import {
  studentApplicationsAtom,
  userAtom,
  schoolApplicantsResultsAtom,
  studentProfileDocumentsAtom,
} from './index';
import { updateStudentApplicationQuery } from '../api';
import { FORM_GENERAL_KEYS } from '../constants/student-details-keys';

export const selectStudentApplicationBySchoolId = selectorFamily({
  key: 'selectStudentApplicationBySchoolId',
  get: schoolId => ({get}) => get(studentApplicationsAtom)[schoolId],
  set: schoolId => ({set}, newValue) =>
    set(studentApplicationsAtom, prevState => ({...prevState, [schoolId]: newValue})),
});

export const selectStudentApplicationSection = selectorFamily({
  key: 'selectStudentApplicationSection',
  get: ({schoolId, sectionId}) => ({get}) => {
    const app = get(selectStudentApplicationBySchoolId(schoolId))
    return app?.sections?.find((i) => i.id === sectionId)
  },
  set: ({ schoolId, sectionId }) => ({set, get}, newValues) => {
    set(studentApplicationsAtom, prevState => {
      const studentApp = get(selectStudentApplicationBySchoolId(schoolId))
      const user = get(userAtom)
      let firstChoice = studentApp?.first_academic_choice
      let secondChoice = studentApp?.second_academic_choice
      let thirdChoice = studentApp?.third_academic_choice
      let points = studentApp?.points
      let fullName = studentApp?.fullName
      let lastSchoolAttended = studentApp?.last_school_attended

      const updatedSections = studentApp.sections.map((section)=>{
        if(section.id === sectionId){
          const updatedFields = section.fields.reduce((acc, field)=>{
            const key = field.name
            const value = newValues[key]
            acc.push({...field, value})

            // Other updates
            if(field.name === FORM_GENERAL_KEYS.firstChoice){
              firstChoice = value
            } else if (field.name === FORM_GENERAL_KEYS.secondChoice) {
              secondChoice = value
            } else if (field.name === FORM_GENERAL_KEYS.thirdChoice) {
              thirdChoice = value
            } else if (field.name === FORM_GENERAL_KEYS.points) {
              points = value
            } else if (field.name === FORM_GENERAL_KEYS.fullName) {
              fullName = value
            } else if (field.name === FORM_GENERAL_KEYS.lastSchoolAttended) {
              lastSchoolAttended = value
            }

            return acc
          }, [])

          return {...section, fields: updatedFields}
        } else {
          return section
        }
      })
      const updateApp = {...studentApp,
        sections: updatedSections,
        points: points,
        full_name: fullName,
        last_school_attended: lastSchoolAttended,
        first_academic_choice: firstChoice,
        second_academic_choice: secondChoice,
        third_academic_choice: thirdChoice,
      }

      updateStudentApplicationQuery(user?.student_id, schoolId, updateApp)
      return {...prevState, [schoolId]: updateApp}
    })
  },
});

export const selectStudentApplicationGrades = selectorFamily({
  key: 'selectStudentApplicationAcademics',
  get: ({ schoolId, sectionId }) => ({ get }) => {
    const appSection = get(selectStudentApplicationSection({schoolId, sectionId}))
    return appSection?.grades
  },
  set: ({ schoolId, sectionId }) => ({ set, get}, newValues) => {
    set(studentApplicationsAtom, prevState => {
      const section = get(selectStudentApplicationSection({schoolId, sectionId}))
      const app = get(selectStudentApplicationBySchoolId(schoolId))
      const user = get(userAtom)

      const newSection = {...section, ...{grades: newValues}}

      const newSections = app.sections.map((section)=>{
        if(section.id === sectionId){
          return newSection
        } else {
          return section
        }
      })
      const newApp = {...app, ...{sections: newSections}}
      updateStudentApplicationQuery(user?.student_id, schoolId, newApp)
      return {...prevState, [schoolId]: newApp}
    })
  }
});

export const selectStudentApplicationIsSubmitted = selectorFamily({
  key: 'selectStudentApplicationIsSubmitted',
  get: schoolId => ({get}) => get(studentApplicationsAtom)[schoolId]?.isSubmitted,
  set: schoolId => ({set, get}, isSubmitted) => {
    const studentApplication = get(studentApplicationsAtom)[schoolId];
    const newApp = {...studentApplication, is_submitted: true}

    set(studentApplicationsAtom, prevState => {
      return {...prevState, [schoolId]: newApp}
    })
  }
});

export const selectStudentApplicant = selectorFamily({
  key: 'selectStudentApplicant',
  get: studentId => ({ get }) => {
    const results = get(schoolApplicantsResultsAtom)
    return results[studentId]
  },
  set: studentId => ({set, get}, param) => {
    // SET HERE
  }
});
