export function handleRedirect(url) {
  window.location.replace(url);
}

export function setLoginSessionStorage(bool) {
  window.sessionStorage.setItem("isUserLoggedIn", bool);
}

export function getLoginSessionStorage() {
  let stringBool = window.sessionStorage.getItem("user");
  return stringBool == "true";
}

export function setUserSessionStorage(userData, userType) {
  window.localStorage.setItem("user", JSON.stringify(userData));
  window.localStorage.setItem("userType", JSON.stringify(userType));
}

export function getUserLocalStorage() {
  let user = window.localStorage.getItem("user");
  let userType = window.localStorage.getItem("userType");

  if (!!user) {
    user = JSON.parse(user);
  }

  if (!!userType) {
    userType = JSON.parse(userType);
  }

  return { user, userType };
}

export function isUserLoggedIn() {
  return getLoginSessionStorage();
}

export function goToAppIfLoggedIn() {
  const userLogin = isUserLoggedIn();
  if (userLogin) {
    handleRedirect("/app/profile");
  }
}

export function goToLoginIfLoggedOut() {
  const userLogin = isUserLoggedIn();
  if (!userLogin) {
    handleRedirect("/login");
  }
}

export function handleLogOut() {
  setLoginSessionStorage(false);
  window.localStorage.clear()
  handleRedirect("/");
}

export const isMobile = window.innerWidth <= 768;

export const isWeb = !isMobile;

export function getUniqueLocations(institutions) {
  var unique_locations = [];

  institutions.forEach((item, i) => {
    item.locations.forEach((location) => {
      if (!unique_locations.includes(location.toLowerCase())) {
        unique_locations.push(location.toLowerCase());
      }
    });
  });

  return unique_locations;
}

export const getUniqueCourseTypes = (courses) => {
  var unique_course_types = [];
  courses.forEach((course) => {
    if (course.course_type !== null) {
      if (!unique_course_types.includes(course.course_type.toLowerCase())) {
        unique_course_types.push(course.course_type.toLowerCase());
      }
    }
  });
  return unique_course_types;
};

export const getUniqueSponsorships = (courses) => {
  var unique_sponsorships = [];
  courses.forEach((course) => {
    if (course.sponsorship_priority !== null) {
      if (!unique_sponsorships.includes(course.sponsorship_priority.toLowerCase())) {
        unique_sponsorships.push(course.sponsorship_priority.toLowerCase());
      }
    }
  });
  return unique_sponsorships
};

export function generateRandomNDigits(n) {
  return Math.floor(Math.random() * (9 * Math.pow(10, n))) + Math.pow(10, n);
}

export function makeKey() {
  return generateRandomNDigits(5);
}

export function formatStdDate(date) {
  if(!!date){
    var d = date.toLocaleString("en").toString();
    return d.split(",").[0];
  }
  return null
}

export function formateDashDate(date) {
  if(date){
    var d = formatStdDate(date).split("/");
    return `${d.[0]}-${d.[1]}-${d.[2]}`;
  }
  return null
}

export function setVisitorStorage(visitor) {
  window.localStorage.setItem("visitorType", visitor);
}

export function getVisitorStorage() {
  var visitorType = window.localStorage.getItem("visitorType");
  return visitorType;
}

export function removeItemFromArray(array, index) {
  if (index > -1) {
    array.splice(index, 1); // 2nd parameter means remove one item only
  }
}
