import { Flex, Text } from '@chakra-ui/react';
import './index.css'

var classNames = require('classnames');

export const ValueList = ({ title, items, className, valueClassName, itemTitleClassName }) => {
  return (
    <Flex className={className} id='value-list-container'>
      <Text mb={5} className='diagram-title'>{ title }</Text>
      {items.map((item, index)=>{
        const showHr = index + 1  != items.length
        return (
          <div>
            <div className='d-flex flex-row value-list-items-wrapper'>
              <p className='value-item-value'>{item.value}</p>
              <p className='value-item-title'>{item.title}</p>
            </div>
            { showHr && <div style={{ border: "1px solid gray ", marginTop: 20, marginBottom: 20 }}></div> }
          </div>
        )
      })}
    </Flex>
  );
};

ValueList.defaultProps = {
  className: null,
  valueClassName: null,
  itemTitleClassName: null,
};
