import AppHeader from '../../../../components/AppHeader';
import { SingleDigit } from '../../../../components/Diagrams/SingleDigit';
import { ValueList } from '../../../../components/Diagrams/ValueList';
import { BarGraph } from '../../../../components/Diagrams/BarGraph';
import { PieGraph } from '../../../../components/Diagrams/PieGraph';
import '../../App.css';
import './index.css';
import { Flex, Text } from '@chakra-ui/react';

const data = [
  { course: 'Film Production', applicants: 124 },
  { course: 'Information Technology', applicants: 136 },
  { course: 'Hotel Management', applicants: 144 },
  { course: 'Nursing', applicants: 156 },
  { course: 'Diploma in Civic Engagement', applicants: 170 },
  { course: 'Certificate in Finance and Entrepreneurship', applicants: 192 },
  { course: 'BA Social Media Marketing', applicants: 224 },
  { course: 'BA Business Leadership', applicants: 256 },
];

const pieGraphData = [
  { appStatus: 'Accepted', count: 400 },
  { appStatus: 'Rejected', count: 200 },
];

const cityPieGraph = [
  { city: 'Manzini', count: 35 },
  { city: 'Mbabane', count: 55 },
  { city: 'Nhlangano', count: 10 },
];

const valueList = [
  {
    value: 'E1,024',
    title: 'collected this month',
  },
  {
    value: 'E12,256',
    title: 'collected this year',
  },
];

const totalApps = {
  value: '512',
  title: 'Total Applications',
}

const appsThisMonth = {
  value: '64',
  title: 'Applications This Month',
}

const Dashboard = () => {
  return (
    <body className='d-md-flex'>
      <header id='app-header-container' className='col-xs-12 col-md-3'>
        <Flex w='100%'>
          <AppHeader />
        </Flex>
      </header>

      <section className='col-xs-12 app-body-container mx-auto' id='app-dashboard-container'>

        <Flex mb={3}>
          <div className='row d-flex flex-column pl-20'>
            <Text fontSize={25} fontWeight={'500'}>Dashboard</Text>
            <p>welcome to your metrics dashboard</p>
          </div>
        </Flex>

        <div className='row d-flex'>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-row'>
              <SingleDigit item={totalApps} className='dashboard-graph mr-30' />
              <SingleDigit item={appsThisMonth} />
            </div>
            <div className='col-12 justify-content-center justify-content-md-start mb-50'>
              <h1 className='text-center'>Top Courses</h1>
              <BarGraph data={data} valueKey='applicants' titleKey='course' rotated />
            </div>
          </div>

          <div className='col-12 col-md-6 d-flex justify-content-center'>
            <div className='d-flex flex-column'>
              <ValueList
                title='Application fees collected'
                items={valueList}
                className='dashboard-graph'
              />
              <div className='pie-graph-wrapper'>
                <div className='d-flex flex-row diagram-period-header pie-graph-header'>
                  <p className='diagram-title'>Application Statuses</p>
                  <p className='ml-auto'>This Year</p>
                </div>
                <PieGraph
                  data={pieGraphData}
                  valueKey='count'
                  titleKey='appStatus'
                  className='dashboard-graph'
                />
              </div>

              <div className='pie-graph-wrapper'>
                <div className='d-flex flex-row diagram-period-header pie-graph-header'>
                  <p className='diagram-title'>City Demographics</p>
                  <p className='ml-auto'>This Year</p>
                </div>
                <PieGraph
                  data={cityPieGraph}
                  valueKey='count'
                  titleKey='city'
                  className='dashboard-graph'
                  innerRadius={0.6}
                />
              </div>

            </div>
          </div>
        </div>
      </section>

    </body>
  )
};

export default Dashboard
