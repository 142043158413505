import { useState } from 'react';
import './search-bar.css';

const SearchBar = ({placeholder, handleInputChange, height, width})=>{
  const [searchInput, setSearchInput] = useState(null);

  function onInputChage(e){
    e.preventDefault();
    let value = e.target.value
    setSearchInput(value);
    handleInputChange(value)
  };

  const styles = {
    width: width || '100%',
    height:  height || 48,
  }

  return (
    <div className="d-flex flex-row search-bar-container" style={styles}>
      <input type="text" placeholder={placeholder} className="search-bar-input" onChange={onInputChage}/>
    </div>
  );
};

export default SearchBar
