import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 160,
    marginTop: 16,
  },
  sliderLabel: {
    fontSize:12,
  }
}));

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    marginTop:-6,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSlider = ({ handleSelect, title, max=600, defaultValue=20 }) => {
  const classes = useStyles();

  // handleSelect(300)
  const handleSliderChange = (event, newValue) => {
    handleSelect(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.sliderLabel}>{title}</Typography>
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={defaultValue}
        max={max}
        min={0}
        step={10}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default CustomSlider
