import { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";

import { useRecoilValue } from 'recoil'
import { userAtom, studentApplicationsAtom } from '../../../../state';
import {  setStudentApplicationsAtom } from '../../../../state/helpers';
import { useApiGetStudentApplicationsByStudentId } from '../../../../api'
import AppHeader from '../../../../components/AppHeader'
import StudentApplicationSummary from '../../../../components/StudentApplicationSummary'

const styles = {
  pageContainer:{
    backgroundColor: '#e9e6df',
    height: '100vh'
  },
  headerWrapper:{
    backgroundColor: 'white',
  },
  noAppContainer: {
    height: 450,
  },
  noAppButton: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: 0,
    fontSize: 12,
    width:240,
    height:50,
  },
  applicationsListContainer:{
    paddingTop: 32,
    paddingBottom: 72,
    padding: 16,
    overflowY: 'scroll',
  },
  addNewbutton: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'black',
    color:'white',
    borderRadius: 0,
    height: 64,
    backgroundColor: 'black',
    fontWeight: 'medium',
    fontSize: 20,
  }
}

// 1. Check to see if student has any applications Started
// 2. Display student started applications
// 3. Display start new application if no applications started

const formatStudentApplicationItems = (studentApps) => {
  return studentApps?.map((item)=>
    ({
      id: item?.school_id,
      isSubmitted: item?.is_submitted,
      title: item?.form?.title,
    })
  )
}

const Applications = ()=>{
  const user = useRecoilValue(userAtom);
  const {data: studentApplications, error, isLoading } = useApiGetStudentApplicationsByStudentId(user?.student_id);
  const [studentApplicationItems, setStudentApplicationItems] = useState(null);

  useEffect(()=>{
    setStudentApplicationItems(
      formatStudentApplicationItems(studentApplications)
    );
    setStudentApplicationsAtom(studentApplications)
  }, [studentApplications])

  const renderStudentAppList = (studentAppList) => {
    return studentAppList?.map((item)=><StudentApplicationSummary {...item} />)
  }

  const noAppsStarted = !studentApplications || studentApplications?.length === 0

  return (
    <Flex flexDir={"column"} style={styles.pageContainer}>
      <Flex style={styles.headerWrapper}>
        <AppHeader />
      </Flex>
      {noAppsStarted && !isLoading && <>
        <Flex flexDir='column' className='text-center align-items-center justify-content-center' style={styles.noAppContainer}>
          <Text>No Application Started Yet Click Below To Begin Applying</Text>
          <a href='/app/student/applications/new'>
            <Button style={styles.noAppButton} className='mt-20'>Start New Application</Button>
          </a>
        </Flex>
      </>}
      {!noAppsStarted && !isLoading &&
        <>
          <div style={styles.applicationsListContainer}>
            { renderStudentAppList(studentApplicationItems) }
          </div>
          <a href='/app/student/applications/new'>
            <Button style={styles.addNewbutton}>Start New Application</Button>
          </a>
        </>
      }
      { isLoading && <Text style={{margin:'auto'}}>Loading Applications...</Text>}
    </Flex>
  )
};

export default Applications
