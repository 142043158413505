import TKI_APPLICATION_TEMPLATE from './tki'

export default {
  "tki-university": {
    "school_application_template": {
      version: "1.0",
      form: TKI_APPLICATION_TEMPLATE
    }
  },
}
