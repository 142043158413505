import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  CLOUDINARY_NAME,
  CLOUDINARY_STUDENTS_DOCUMENTS,
  UPLOAD_PRESET,
} from "../../api/constants";

import CheckMarkFilledIcon from '../../assets/js-icons/CheckMarkFilledIcon';
import { getDocumentIcon } from "../../formatters";

const DocumentUpload = ({ title, docType, onSuccess, initialValue=null, styles, displayMode=false}) => {
  const [documentUrl, setDocumentUrl] = useState(null);
  const toast = useToast()
  const handleUpload = (err, res) => {
    if (res?.event === "success") {
      const url = res.info?.url;
      onSuccess(res.info?.url);
      setDocumentUrl(url);
    }
    if (err) {
      toast({
        title: "Error",
        description: "Error uploading document",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  let widget = window.cloudinary.createUploadWidget(
    {
      cloudName: CLOUDINARY_NAME,
      folder: CLOUDINARY_STUDENTS_DOCUMENTS,
      uploadPreset: UPLOAD_PRESET,
      clientAllowedFormats: ["jpeg","jpg", "png", "pdf"],
      resouceType: "image",
    },
    (error, result) => {
      handleUpload(error, result);
    }
  );

  const showWidget = () => {
    !displayMode && widget.open();
  };

  useEffect(()=>{
    setDocumentUrl(initialValue)
  }, [initialValue])

  return (
    //add children with click handler
    <>
      <Flex
        onClick={showWidget}
        _hover={{ bg: "black", color: "white", cursor: "pointer" }}
        mb={5}
        p={5}
        w="100%"
        h={20}
        borderColor="black"
        borderWidth={2}
        boxShadow={"sm"}
        justifyContent='space-between'
        alignItems='center'
        borderRadius={0}
        style={styles}
      >
        {getDocumentIcon(docType)}
        <Text alignSelf={"center"}>{title}</Text>
        {!displayMode && <>
          {!documentUrl && <Text>click to upload</Text>}
          {documentUrl && <CheckMarkFilledIcon checked />}
        </>}
      </Flex>
    </>
  );
};

export default DocumentUpload;
