import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import HomeHeader from '../../components/HomeHeader';
import HomeIntroCarousel from '../../components/HomeIntroCarousel';
import TypedText from '../../components/TypedText';
import HomeIntroModal from '../../components/HomeIntroModal';
import ClickUpSurveyModal from '../../components/ClickUpSurveyModal';

import SearchIcon from '../../assets/js-icons/SearchIcon';

import './home.css';
import '../../index.css';
import { isMobile, isWeb, getVisitorStorage } from '../../utilities';
import {
  useRecoilValue,
} from 'recoil';
import { visitorTypeAtom } from '../../state';
import { JOIN_SASA_URL, REQUEST_DEMO_URL, ANALYTICS_EVENTS } from '../../constants';

import sasaStripes from '../../assets/images/sasa-stripes.png';
import howItWorksOne from '../../assets/images/how-it-works-1.png';
import howItWorksTwo from '../../assets/images/how-it-works-2.png';
import howItWorksThree from '../../assets/images/how-it-works-3.png';
import tkiPhotosWeb from '../../assets/images/tki-photos.png';
import tkiPhotosMobile from '../../assets/images/tki-photos-mobile-2.png';
import {
  Flex,
  Text
} from '@chakra-ui/react';

var classNames = require('classnames');

const Home = () => {
  const searchStrings = [
    'study in Manzini',
    'study in Mbabane',
    'study Business',
    'study Sales',
    'study Engineering',
    'study in Eswatini'
  ];
  const visitor = useRecoilValue(visitorTypeAtom) || getVisitorStorage();
  const [surveyModalState, setSurveyModalState] = useState(false);
  const [demoModalState, setDemoModalState] = useState(false);
  const [clickUpUrl, setClickUpUrl] = useState(null);
  const [analyticsEvent, setAnalyticsEvent] = useState(null);

  const buttonCopy = 'Sign Up';

  useEffect(() => {
    ReactGA.pageview('home')
  }, []);

  function toggleDemoModal() {
    ReactGA.event(ANALYTICS_EVENTS.REQUEST_DEMO_CTA)
    setDemoModalState(!demoModalState)
  };

  function handleBrowseBtn() {
    window.location.replace('/search');
    ReactGA.event(ANALYTICS_EVENTS.BROWSE_INSTITUTIONS_CTA)
  };

  function handleSignUpCta(){
    window.location.replace('/sign-up')
  };

  return (
    <body id='home-page'>
      <header>
        <HomeHeader />
      </header>

      {/*<img src={sasaStripes} className='sasa-stripes'/>*/}
      <section id='home-search-intro' className={classNames('justify-content-center', { 'mobile': isMobile, 'web': isWeb })}>
        <div className='home-search-intro-content'>
          <h1 className='text-center home-search-intro-title'>Search For Universities In Eswatini</h1>
          <div className='mx-auto col-12 col-md-8'>
            <a href='/search'>
              <div className='d-flex home-search-intro-search-text'>
                <Flex alignSelf='center'>
                  <span className='ml-10 mr-20' style={{ opacity: 0.5 }}>
                    <SearchIcon size={isWeb ? 36 : 20} />
                  </span>
                </Flex>
                <p className='text-center m-0'>
                  I would like to...<TypedText strings={searchStrings} />
                </p>
              </div>
            </a>
          </div>
          <div className='d-flex'>
            <div className='mx-auto mt-10' onClick={handleBrowseBtn}>
              <button className='search-home-btn'>Browse Institutions</button>
            </div>
          </div>
        </div>
      </section>

      <section id='home-page-intro' className='d-flex flex-column flex-md-row'>
        <div className={classNames(
          'col-md-6 d-flex flex-column',
          { 'web': isWeb },
          { 'mobile': isMobile },
        )}>
          <div className={classNames('d-flex flex-column mx-auto home-intro-text', { 'web': isWeb }, { 'mobile': isMobile })}>
            <Text mb={'20px'} fontWeight='700' fontSize={25}>A SIMPLE AND QUICK WAY TO DISCOVER TERTIARY INSTITUTIONS</Text>
            {/* <h1 className='title mb-20'>A SIMPLE AND QUICK WAY TO DISCOVER TERTIARY INSTITUTIONS</h1> */}
            <p className='subtitle'>Explore institutions that offer courses of your interest and find admission information
              for all institutions in Eswatini on Sasa</p>
            {!!isWeb && buttonCopy &&
              <button
                onClick={() => handleSignUpCta()}
                className='carousel-home-btn mt-10'>{buttonCopy}
              </button>
            }
          </div>
        </div>

        <div className={classNames(
          'col-12 col-md-6 d-flex flex-column home-intro-carousel',
          { 'web': isWeb },
          { 'mobile': isMobile },
        )}>
          <HomeIntroCarousel />
          {!!isMobile && buttonCopy &&
            <button
              onClick={() => handleSignUpCta()}
              className='carousel-home-btn mt-10 mx-auto'>{buttonCopy}
            </button>}
        </div>
      </section>

      {isMobile && <section id='home-how-it-works' className='mobile'>
        <h1 className='text-center home-how-it-works-title'>How It Works</h1>
        <div className='how-it-works-info'>
          <div>
            <img
              src={howItWorksOne}
              width='40%'
              className='how-it-works-png how-it-works-png-1 left' />
          </div>
          <div className='col-md-6'>
            <h3 className='how-it-works-subtitle'>Search and Find Institutions and Courses</h3>
            <p className='how-it-works-description'>Search and filter institutions based on location, application fee, accommodation etc</p>
          </div>
        </div>
        <div className='how-it-works-info'>
          <div>
            <img
              width='40%'
              src={howItWorksTwo}
              className='how-it-works-png how-it-works-png-2 right' />
          </div>
          <div>
            <h3 className='how-it-works-subtitle'>Create Profile On SASA</h3>
            <p className='how-it-works-description'>Create a profile where you fill in information required by most institutions once</p>
          </div>
        </div>
        <div className='how-it-works-info'>
          <div>
            <img
              width='40%'
              src={howItWorksThree}
              className='how-it-works-png how-it-works-png-3 left' />
          </div>
          <div>
            <h3 className='how-it-works-subtitle'>Send Applications to multiple institutions</h3>
            <p className='how-it-works-description'>Complete and send applications to multiple institutions with one click</p>
          </div>
        </div>
      </section>}

      {isWeb && <section id='home-how-it-works' className='web'>
        <div className='col-md-10 col-lg-8 mx-auto'>
          <Text textAlign={'center'} className='home-how-it-works-title' fontSize={24}>HOW IT WORKS</Text>
          {/* <h1 className='text-center home-how-it-works-title'>HOW IT WORKS</h1> */}
          <div className='how-it-works-info row'>
            <div className='col-md-6'>
              <img src={howItWorksOne} className='how-it-works-png how-it-works-png-1 left' />
            </div>
            <div className='col-md-6 d-flex flex-column justify-content-center'>
              <Text fontSize={30} fontWeight={'500'} className='how-it-works-subtitle'>Search and Find Institutions and Courses</Text>
              {/* <h3 className='how-it-works-subtitle'>Search and Find Institutions and Courses</h3> */}
              <p className='how-it-works-description'>Search and filter institutions based on location, application fee, accommodation etc</p>
            </div>
          </div>
          <div className='how-it-works-info row'>
            <div className='col-md-6 d-flex flex-column justify-content-center'>
              <Text fontSize={30} fontWeight={'500'} className='how-it-works-subtitle'>Create Profile On SASA</Text>
              {/* <h3 className='how-it-works-subtitle'>Create Profile On SASA</h3> */}
              <p className='how-it-works-description'>Create a profile where you fill in information required by most institutions once</p>
            </div>
            <div className='col-md-6'>
              <img src={howItWorksTwo} className='how-it-works-png how-it-works-png-2 right' />
            </div>
          </div>
          <div className='how-it-works-info row'>
            <div className='col-md-6'>
              <img src={howItWorksThree} className='how-it-works-png how-it-works-png-3 left' />
            </div>
            <div className='col-md-6 d-flex flex-column justify-content-center'>
              <Text fontSize={30} fontWeight={'500'} className='how-it-works-subtitle'>Send Applications to multiple institutions</Text>
              {/* <h3 className='how-it-works-subtitle'>Send Applications to multiple institutions</h3> */}
              <p className='how-it-works-description'>Complete and send applications to multiple institutions with one click</p>
            </div>
          </div>
        </div>
      </section>}

      {isWeb && <section id='home-request-demo'>
        <div className='col-md-10 d-flex flex-row mx-auto'>
          <div className='col-md-6'>
            <img src={tkiPhotosWeb} />
          </div>
          <div className='col-md-6 mx-auto text-center'>
            <Flex mb={2} justifyContent='center'>
              <Text fontWeight={'600'} w={500} fontSize={30}>WORK FOR A TERTIARY INSTITUTION?</Text>
            </Flex>
            {/* <h2 className='home-request-demo-title'>WORK FOR A TERTIARY INSTITUTION?</h2> */}
            <Flex justifyContent={'center'} mb={2}>
              <p>REQUEST A DEMO TODAY</p>
            </Flex>
            <button className='request-demo-home-btn' onClick={() => toggleDemoModal()}>Request A Demo</button>
          </div>
        </div>
      </section>}

      {isMobile && <section id='home-request-demo'>
        <div className='col-md-10 d-flex flex-column mx-auto'>
          <div className='mx-auto mb-20'>
            <div className='mx-auto'>
              <img src={tkiPhotosMobile} width='100%' />
            </div>
          </div>
          <div className='mx-auto text-center'>
            <h2 className='home-request-demo-title'>WORK FOR A TERTIARY INSTITUTION?</h2>
            <p>REQUEST A FREE DEMO TODAY</p>
            <button className='request-demo-home-btn' onClick={() => toggleDemoModal()}>Request A Demo</button>
          </div>
        </div>
      </section>}
    </body>
  )
};

export default Home;
