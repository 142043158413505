import { useState } from 'react'
import {
  Icon,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { AiOutlinePlus } from "react-icons/ai";
import { ESWATINI_TEST_CERTIFICATES, SUBJECTS } from '../../../constants/subjects'
import { studentDetailsKeys } from '../../../constants/student-details-keys'

const getTestDates = ()=> {
  const ANCHOR = new Date().getFullYear()
  const dates = []
  for (let i = ANCHOR; i > ANCHOR - 20 ; i--) {
    dates.push(i)
  }
  return dates
}

const GRADES = ['A', 'A-', 'B', 'B-', 'C', 'C-', 'D', 'D-', 'E', 'E-', 'F', 'F-'];

const styles = {
  addNewbutton: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'black',
    color:'white',
    borderRadius: 0,
    height: 48,
    backgroundColor: '#10ac84',
    fontWeight: 'bold',
    fontSize: 20,
  }
}

const { academicDetails } = studentDetailsKeys

const AddAcademicDetailModal = ({ onSubmit, itemsAdded }) => {
  const emptyState = {
    [academicDetails.certificate]: null,
    [academicDetails.subject]: null,
    [academicDetails.year]: null,
    [academicDetails.grade]: null
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const testDates = getTestDates()
  const [formDetails, setFormDetails] = useState(emptyState)
  const [error, setError] = useState(null)
  const handleOnChange = (name, value) => {
    if(name === 'subject'){
      const existingSubject = itemsAdded.find((item)=>item['subject'].toLowerCase() === value.toLowerCase())
      if(!!existingSubject){
        setError(`you have already entered details for ${value}`)
        return
      } else {
        setError(null)
      }
    }
    setFormDetails({...formDetails, ...{[name]: value}})
  };
  const handleOnSubmit = () => {
    let emptyField = Object.keys(formDetails).find((key)=> !!formDetails[key] === false)
    if(emptyField){
      setError(`please enter a ${emptyField } to continue`)
    } else {
      setFormDetails(emptyState)
      setError(null)
      onSubmit(formDetails)
      onClose()
    }
  }

  return (
    <>
      <Button onClick={onOpen} style={styles.addNewbutton}>Add New +</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={0}>
          <ModalHeader>Add New</ModalHeader>
          <ModalBody>
            <Select variant={"flushed"} className='mb-10' placeholder='certificate' onChange={((e)=>handleOnChange(academicDetails.certificate, e.target.value))}>
              {Object.keys(ESWATINI_TEST_CERTIFICATES)
                .map((key)=>
                  <option value={ESWATINI_TEST_CERTIFICATES[key]}>
                    {ESWATINI_TEST_CERTIFICATES[key]}
                  </option>)}
            </Select>
            <Select variant={"flushed"} className='mb-10' placeholder='subject' onChange={((e)=>handleOnChange(academicDetails.subject, e.target.value))}>
              {Object.keys(SUBJECTS)
                .map((key)=>
                  <option value={SUBJECTS[key]}>
                    {SUBJECTS[key]}
                  </option>)}
            </Select>
            <Select variant={"flushed"} className='mb-10' placeholder='year taken' onChange={((e)=>handleOnChange(academicDetails.year, e.target.value))}>
              {testDates.map((value)=>
                  <option value={value}>
                    {value}
                  </option>)}
            </Select>
            <Select variant={"flushed"} className='mb-10' placeholder='grade' onChange={((e)=>handleOnChange(academicDetails.grade, e.target.value))}>
              {GRADES.map((value)=>
                  <option value={value}>
                    {value}
                  </option>)}
            </Select>
            {!!error && <Text color='red'>{error}</Text>}
          </ModalBody>

          <ModalFooter>
            <Button borderRadius={0} colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => handleOnSubmit()}
              borderRadius={0}
              color="white"
              _hover={{ bg: "#FFA800", color: "black" }}
              bg="black"
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAcademicDetailModal
