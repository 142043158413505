import { useState, useEffect } from 'react';
import './select-field.css'

var classNames = require('classnames');

// accepts an object with data containing options and name
const SelectField = ({ data, handleSelect }) => {
  const {options, name} = data;
  const [choice, setChoice] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(()=>{
    let inActive = choice === "select" || choice === null;
    setIsActive(!inActive);
  }, [choice])

  const onSelect = (e)=> {
    e.preventDefault();
    let value = e.target.value === "select" ? null : e.target.value
    setChoice(value);
    handleSelect(value);
  }

  return (
    <div>
      <div className="d-flex flex-column select-container">
        <label>{name}</label>
        <select
          name={name}
          id={"select-option" + name}
          onChange={onSelect}
          className={classNames("select-option", {"select-option-active" : isActive })}
        >
          <option value="select">select</option>
          { options.map((option, index)=>
            <option key={index} value={option}>{option}</option>
          )}
        </select>
      </div>
    </div>
  )
};


export default SelectField;
