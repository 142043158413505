import {
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { studentDetailsKeys } from '../../constants/student-details-keys'

const styles = {
  detailContainer:{
    backgroundColor: '#303952',
    height: 70,
    padding: 5,
    paddingRight: 12,
    paddingLeft: 12,
    borderRadius: 16,
    color: 'white',
    marginBottom: 16,
  },
  subject: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  capitalize: {
    textTransform: 'uppercase'
  },
  bottomContent: {
    fontSize: 14
  },
  certificate: {
    textTransform: 'uppercase',
  }
}

const { academicDetails } = studentDetailsKeys

const AcademicDetailItem = ({ item }) => {
  const subject = item[academicDetails.subject]
  const grade = item[academicDetails.grade]
  const certificate = item[academicDetails.certificate]
  const year = item[academicDetails.year]


  return (
    <Flex flexDir='column' style={styles.detailContainer}>
      <Flex flexDir='row' style={styles.topContent}>
        <Text className='mr-auto' style={styles.subject}>{ subject }</Text>
        <Text style={styles.subject}>{ grade }</Text>
      </Flex>
      <Flex flexDir='row' style={styles.bottomContent}>
        <Text className='mr-auto' style={styles.certificate} >{ certificate }</Text>
        <Text style={styles.right}>{ year }</Text>
      </Flex>
    </Flex>
  )
}

export default AcademicDetailItem
