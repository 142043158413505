import {
  Flex,
  Text,
  Link,
} from "@chakra-ui/react";

import DocumentIconTwo from '../../assets/js-icons/DocumentIconTwo'

const styles = {
  container: {
    width: '100%',
    height: 100,
    padding: 18,
    backgroundColor: 'white',
    marginBottom: 18,
    boxShadow: '2px 2px 8px gray',
  },
  title: {
    fontSize: 20
  },
};

const StudentApplicationSummary = ({ title, isSubmitted, id }) => {
  return (
    <Link href={`/app/student/applications/start/${id}`}>
      <Flex style={styles.container}>
        <Flex style={{marginTop: 4}}>
          <DocumentIconTwo />
        </Flex>
        <Flex flexDir='column' style={{marginLeft:8}}>
          <Text style={styles.title}>{ title }</Text>
          <Text>submitted:  {isSubmitted ? 'yes' : 'no' }</Text>
        </Flex>
      </Flex>
    </Link>
  )
}

export default StudentApplicationSummary
