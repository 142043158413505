import axios from 'axios'
import { useState } from 'react'
import {
  Flex,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRecoilValue, useRecoilState } from 'recoil'
import { TrashIcon } from 'chakra-ui-ionicons';

import BackButton from '../BackButton';
import AcademicDetailItem from '../AcademicDetailItem';
import AddAcademicDetailModal from '../AcademicDetailItem/AddAcademicDetailModal';
import ConfirmationModal from '../ConfirmationModal';
import { userAtom, academicDetailsAtom } from '../../state';
import { API_BASE_URL } from '../../api/constants';

import { removeItemFromArray } from '../../utilities';

const styles = {
  pageContainer: {
    height:'100vh',
  },
  emptyContent: {
    marginTop: '33vh',
    height: 100,
  },
  emptyText: {
    maxWidth: 280,
  },
  detailsContainer: {
    overflowY:'scroll',
    height:'90vh',
    paddingBottom: 72,
    width: 360,
  },
  detailsTitle:{
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  trashIcon: {
    marginLeft:'auto',
    marginTop: -10,
  },
  detailItemWrapper: {
    marginBottom: 10,
  },
};

const AcademicDetailsDisplay = ({
  handleNewItem,
  handleRemoveItem,
  academicDetails,
  setAcademicDetails,
  openConfirmationModal,
  setConfirmationModal,
  itemToBeRemoved,
  setItemToBeRemoved,
  handleTrashItem,
}) => {
  const subjectsIsEmpty = academicDetails.length === 0

  return (
    <Flex flexDir='column' style={styles.pageContainer}>
      <Flex className='justify-content-center'>
        <Flex style={styles.emptyContent}>
          {subjectsIsEmpty &&
            <Text className='text-center' style={styles.emptyText}>Click Below To Start Entering Academic Details</Text> }
        </Flex>
        {!subjectsIsEmpty &&
        <Flex flexDir='column' style={styles.detailsContainer}>
            <Text style={styles.detailsTitle}>Academics</Text>
            {academicDetails.map((item, idx) => {
                return <Flex flexDir='column' style={styles.detailItemWrapper} key={idx}>
                  <AcademicDetailItem item={item} />
                  <TrashIcon  w={6} style={styles.trashIcon} onClick={()=>handleTrashItem(idx)}/>
                </Flex>
            })}
        </Flex>}
        <AddAcademicDetailModal
          onSubmit={handleNewItem}
          itemsAdded={academicDetails}
        />
        <ConfirmationModal
          openModal={openConfirmationModal}
          message={'Are you sure you want to remove this entry?'}
          handleOnClose={handleRemoveItem}
          />
      </Flex>
    </Flex>
  )
};

export default AcademicDetailsDisplay;
