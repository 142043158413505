import { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';

import '../../index.css';
import './click-up-survey-modal.css';
import CloseIcon from '../../assets/js-icons/CloseIcon';

var classNames = require('classnames');

const ClickUpSurveyModal = ({ url, modalState, toggleModal }) => {
  const [ modalOpenStatus, setModalOpen ] = useState(modalState);

  useEffect(()=>{
    setModalOpen(modalState)
  }, [modalState])

  return (
    <Modal open={modalOpenStatus} disableBackdropClick>
      <div className='click-up-modal-container col-12 col-md-6'>
        <div className='click-up-close-btn'>
          <a onClick={()=>toggleModal()}><CloseIcon /></a>
        </div>
        <div className='click-up-embed-wrapper'>
          <iframe className="clickup-embed clickup-dynamic-height" src={ url } onWheel="" width="100%" height="100%" style={
            { background: 'transparent', border: '1px solid #ccc'}}/>
          <script src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
        </div>
      </div>
    </Modal>
  )
};

ClickUpSurveyModal.defaultProps = {
  modalOpen: false
}


export default ClickUpSurveyModal;
