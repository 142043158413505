import {
  Flex,
  Text,
} from "@chakra-ui/react";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../state'

const styles = {
  noticeContainer: {
    padding: 20,
    backgroundColor: '#FFEA35',
  },
  noticeText: {
    fontWeight: 'bold',
    fontSize: 24,
  }
};

const StudentNotice = ()=> {
  const user = useRecoilValue(userAtom);

  return (
    <Flex
      style={styles.noticeContainer}
      flexDir='column'
    >
      <Text style={styles.noticeText}>Welcome {user.first_name}</Text>
      <Text>Lets get you all setup so you can start applying  to schools! Please fill out the information below</Text>
    </Flex>
  )
}

export default StudentNotice
