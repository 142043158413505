import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { isMobile, isWeb } from '../../utilities';
var classNames = require('classnames');

const CourseSearchResult = ({title, location, action}) => {
  return (
    <>
    <Flex onClick={action} className={classNames("institution-search-result-mini-container", { 'web': isWeb }, { 'mobile': isMobile })}>
      <div>
        <p className="institution-search-result-title">{title}</p>
        <div className="d-flex flex-row institution-search-section-summary">
        </div>
      </div>
    </Flex>
    </>
  )
}

export default CourseSearchResult