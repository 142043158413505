import { makeKey } from '../../utilities';

import './index.css';

const ApplicationSectionViewContainer = ({ header, title, children }) => {
  return (
    <div className='mx-auto application-section-container' key={makeKey()} style={{width:'90%'}}>
      <p className='application-section-header p-20 m-0'>{ header }</p>
      <div className='d-flex flex-column row col-10 m-auto p-0'>
        <h1 className='application-section-title'>{ title }</h1>
        { children }
      </div>
    </div>
  );
}

export default ApplicationSectionViewContainer
