import { userAtom , studentApplicationsAtom} from '../index';
import { getRecoil, setRecoil } from "recoil-nexus";

const _setRecoilState = (atom, newState) => {
    setRecoil(atom, newState);
    return newState
};

export const setStudentApplicationsAtom = (data) => {
  if(!data){
    return null
  }
  const initialState = getRecoil(studentApplicationsAtom) || {};

  const newApplications = data?.reduce((acc, item)=>{
    return {...acc, ...{[item?.school_id]: item?.form}}
  }, initialState);

  return _setRecoilState(studentApplicationsAtom, newApplications)
};

export const hasStudentStartedApplication = (schoolId) => {
    const applications = getRecoil(studentApplicationsAtom);
    return Object.keys(applications).includes(schoolId.toString())
};
