import { useRef, useEffect, Fragment } from 'react';
import Typed from "react-typed";


const TypedText = ({ strings }) => {
  return (
    <Fragment>
       <Typed
        strings={strings}
        typeSpeed={90}
        loop
        />
    </Fragment>
  );
};

export default TypedText
