import {
  studentDetailsKeys,
  PERSONAL_DETAILS_KEY,
  NEXT_OF_KIN_DETAILS_KEY,
  ACADEMIC_DETAILS_KEY,
  FORM_GENERAL_KEYS,
} from '../student-details-keys';

import { APPLICATION_SECTION_TYPES } from './types'

const {
  personalDetails,
  nextOfKinDetails,
  academicDetails
} = studentDetailsKeys;


const TKI_APPLICATION_TEMPLATE = {
  title: 'TKI University Application',
  is_submitted: false,
  full_name: null,
  first_academic_choice: null,
  second_academic_choice: null,
  third_academic_choice: null,
  points: null,
  last_school_attended: null,
  sections: [
    {
      id: 'section_a',
      type: APPLICATION_SECTION_TYPES.standard,
      header: 'section a',
      title: 'Overview',
      type: 'standard',
      fields: [
        {
          title: 'Full Name',
          name: FORM_GENERAL_KEYS.fullName,
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 1,
        },
        {
          title: 'First Choice',
          name: FORM_GENERAL_KEYS.firstChoice,
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 2,
        },{
          title: 'Second Choice',
          name: FORM_GENERAL_KEYS.secondChoice,
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 3,
        },
        {
          title: 'Third Choice',
          name: FORM_GENERAL_KEYS.thirdChoice,
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 4,
        },
        {
          title: 'Last School Attended',
          name: FORM_GENERAL_KEYS.lastSchoolAttended,
          value: null,
          fieldType: 'input',
          size: 'lg',
          position: 5,
        },
        {
          title: 'Points',
          name: FORM_GENERAL_KEYS.points,
          value: null,
          fieldType: 'input',
          size: 'sm',
          position: 6,
        },
      ]
    },
    {
      id: 'section_b',
      type: APPLICATION_SECTION_TYPES.standard,
      header: 'section b',
      title: 'Personal Info',
      fields: [
        {
          title: 'first name',
          name: 'first_name',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 1,
        },{
          title: 'last name',
          name: 'last_name',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 2,
        },
        {
          title: 'id number',
          name: 'id_number',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 3,
          key: PERSONAL_DETAILS_KEY.firstName,
        },
        {
          title: 'date of birth',
          name: 'dob',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 4,
          key: PERSONAL_DETAILS_KEY.dob,
        },
        {
          title: 'country of citizenship',
          name: 'citizenship',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 5,
          key: PERSONAL_DETAILS_KEY.citizenship,
        },
        {
          title: 'sex',
          name: 'sex',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 6,
          key: PERSONAL_DETAILS_KEY.gender,
        },
        {
          title: 'email address',
          name: 'email',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 7,
        },
        {
          title: 'contact number (cell)',
          name: 'contact_number_cell',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 8,
        },
        {
          title: 'contact number (home)',
          name: 'contact_number_home',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 9,
        },
        {
          title: 'P.O. Box',
          name: 'po_box',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 10,
        },
        {
          title: 'city',
          name: 'city',
          value: null,
          fieldType: 'input',
          size: 'md',
          position: 11,
        },
        {
          title: 'home address',
          name: 'home_address',
          value: null,
          fieldType: 'input',
          size: 'lg',
          position: 12,
        },
        {
          title: 'Disabilities',
          name: 'disability',
          value: null,
          fieldType: 'input',
          size: 'lg',
          position: 13,
        },
      ]
    },
    {
      id: 'section_c',
      type: APPLICATION_SECTION_TYPES.academics,
      header: 'section c',
      title: 'Academics',
      grades: [],
    },
    {
      id: 'section_d',
      type: APPLICATION_SECTION_TYPES.signature,
      header: 'section d',
      title: 'Signature',
      fields: [
        {
          title: 'Declaration',
          name: 'declaration',
          value: 'I declare that all the above information is correct and true to the best of my knowledge and belief.\
          I agree that if I am accepted by the University, I shall be liable to disciplinary control of the University authority\
           and I undertake to conform to the rules and regulations of the University.',
          fieldType: 'text-display',
        },
        {
          title: 'Signature',
          name: 'signature',
          value: null,
          fieldType: 'signature',
        },
      ],
    },
    {
      id: 'section_e',
      type: 'document',
      header: 'section e',
      title: 'Documents',
      fields: [
        {
          title: 'Transcript',
          name: 'transcript',
          value: null,
          fieldType: 'document',
          options: {
            docType: 'pdf'
          }
        },
        {
          title: 'Photo ID',
          name: 'photo_id',
          value: null,
          fieldType: 'document',
          options: {
            docType: 'jpg'
          }
        },
      ]
    }
  ]
}

export default TKI_APPLICATION_TEMPLATE
