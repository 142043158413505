// ONCE SET ANY CHANGES HERE SHOULD MATCH DB ATTRIBUTES
export const PERSONAL_DETAILS_KEY = 'personalDetails'
export const NEXT_OF_KIN_DETAILS_KEY = 'nextOfKinDetails'
export const ACADEMIC_DETAILS_KEY = 'academicDetails'

export const studentDetailsKeys = {
  [PERSONAL_DETAILS_KEY]: {
    firstName: 'first_name',
    lastName: 'last_name',
    idNumber: 'id_number',
    dob: 'dob',
    gender: 'gender',
    citizenship: 'citizenship',
    disability: 'disability',
    academicProfessionalInterests: 'professional_interests'
  },
  [NEXT_OF_KIN_DETAILS_KEY]: {
    firstName: 'first_name',
    lastName: 'last_name',
    relationship: 'relationship',
    email: 'email',
    contactNumber: 'contact_number',
    address: 'permanent_address'
  },
  [ACADEMIC_DETAILS_KEY]: {
    certificate: 'certificate',
    subject: 'subject',
    grade: 'grade',
    year: 'year_taken',
  }
};

export const FORM_GENERAL_KEYS = {
  firstChoice: 'first_academic_choice',
  secondChoice: 'second_academic_choice',
  thirdChoice: 'third_academic_choice',
  points: 'points',
  lastSchoolAttended: 'last_school_attended',
  fullName: 'full_name',
}

export default {
  studentDetailsKeys,
  PERSONAL_DETAILS_KEY,
  NEXT_OF_KIN_DETAILS_KEY,
  ACADEMIC_DETAILS_KEY,
  FORM_GENERAL_KEYS,
}
