import {
  Flex,
  Text,
  Link,
  Button,
  Image,
} from "@chakra-ui/react";

import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../../state';
import { setStudentApplicationsAtom, hasStudentStartedApplication } from '../../../../state/helpers';
import { useApiGetSchoolApplicationsSearchResults } from '../../../../api';
import AppHeader from '../../../../components/AppHeader';
import SearchBar from '../../../../components/SearchBar';
import InstitutionSearchResult from '../../../../components/InstitutionSearchResult';

const styles = {
  pageContainer:{
    height: '100vh',
    backgroundColor: '#dfe6e9',
    overflowY:'scroll',
  },
  headerWrapper:{
    backgroundColor: 'white',
  },
  contentContainer: {
    padding: 16,
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  Button: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: 0,
    fontSize: 12,
  },
  textContainer: {
    paddingTop: 10,
    marginLeft: 20,
  },
  title: {
    textAlign: 'start',
    fontWeight: 'bold'
  },
  subText: {
    textAlign: 'start',
    fontSize: 10
  },
  schoolItemContainer: {
    backgroundColor: 'white',
    padding:15,
    boxShadow: '2px 4px 5px gray',
    marginBottom: 16,
  },
}

const renderSearchResult = (school) => {
  if(!school){
      return null
  }

  return <Flex style={styles.schoolItemContainer}>
    <Image src={school?.logo_image_src} width={'60px'} height={'60px'} borderRadius={10}/>
    <Flex flexDir='column' style={styles.textContainer}>
      <Text style={styles.title}>{ school?.title }</Text>
      <Flex style={{justifyContent: 'space-between'}}>
        <Text style={styles.subText} className='mr-10'>application fee: { school?.fee }</Text>
        <Text style={styles.subText}>accepting applications: { school?.accepting_applications ? 'yes' : 'no'}</Text>
      </Flex>
    </Flex>
  </Flex>
}

const StudentApplicationsBrowse = ()=>{
  const user = useRecoilValue(userAtom);
  const {data: schools , error } = useApiGetSchoolApplicationsSearchResults();

  const handleNewApplication = (index) => {
    const school = schools?.[index]
    const application = school?.school_application_templates[0]
    const hasNotStartedApplication = !hasStudentStartedApplication(school?.id)
    if(hasNotStartedApplication){
      setStudentApplicationsAtom([application])
    };
    window.location.replace(`/app/student/applications/start/${school.id}`)
  }

  // GET ALL OF THE SCHOOLS
  // GET ONLY THOSE SCHOOLS WHICH CONTAIN AN APPLICATION
  return (
    <Flex flexDir={"column"} style={styles.pageContainer}>
      <Flex style={styles.headerWrapper}>
        <AppHeader />
      </Flex>

      <Flex flexDir='column' className='text-center' style={styles.contentContainer}>
        <Flex flexDir='column'>
          {
            schools?.map((school, index)=>
            <a onClick={()=>handleNewApplication(index)}>
              {renderSearchResult(school)}
            </a>
            )
          }
        </Flex>
      </Flex>
    </Flex>
  )
};

export default StudentApplicationsBrowse
