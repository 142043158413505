import axios from 'axios'
import { useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useToast } from "@chakra-ui/react"

import PageForm from '../../../../../components/PageForm'
import { nextOfKinDetailsAtom, userAtom } from '../../../../../state'
import { API_BASE_URL } from '../../../../../api/constants'
import FIELDS from '../../../../../constants/student-personal-details/next-of-kin-details'

const StudentNextOfKinDetails = () => {
  const toast = useToast()
  const user = useRecoilValue(userAtom)
  const [nextOfKinDetailsState, setNextOfKinDetailsState] = useRecoilState(nextOfKinDetailsAtom)

  const handleSubmit = (form) => {
      axios.post(`${API_BASE_URL}/students/${user.student_id}/next-of-kin-details`, form)
      .then((res) => {
          if (res.status.toString().startsWith('20')) {
            // Toast success message
            toast({
              title: "Success",
              description: "Profile updated successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setNextOfKinDetailsState(form)
          }
        }).catch((e) => {
          toast({
            title: "Error",
            description: "Something went wrong, saving failed",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      })
  };

  return <PageForm
    fields={FIELDS}
    initialValues={nextOfKinDetailsState}
    title='Next Of Kin Details'
    handleSubmit={handleSubmit}
  />
}

export default StudentNextOfKinDetails
