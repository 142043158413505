const TKI_SAMPLE_DATA = {
      title: 'TKI University',
      fee: 100,
      acceptingApps: true,
      imgSrc: 'https://res.cloudinary.com/sasa-app/image/upload/v1634309935/tki_logo_a6gbsc.png',
      locations: ['Manzini', 'Mbabane'],
      govSponsored: true,
      openApps: true,
      accommodations: false,
      contacts: {
        email: 'tki.eswatini@gmail.com',
        phoneNumber: '+268 76260618',
        facebook: 'https://www.facebook.com/groups/120309763303006',
        website: 'https://www.tkieswatini.org',
      },
      courses: [
        {
          title: 'BA Business Leadership',
          cutOffPoints: 32,
          minimumCredits: null,
          minimumPass: null,
          subjectRequirements: null
        },
        {
          title: 'BA  Social Media Marketing',
          cutOffPoints: 32,
          minimumCredits: null,
          minimumPass: null,
          subjectRequirements: null
        },
        {
          title: 'Certificate in Finance and Entrepreneurship',
          cutOffPoints: 32,
          minimumCredits: null,
          minimumPass: null,
          subjectRequirements: null
        },
        {
          title: 'Diploma in Civic Engagement',
          cutOffPoints: 32,
          minimumCredits: null,
          minimumPass: null,
          subjectRequirements: null
        },
      ],
      admissionRequirements: [
        'An applicant must have a minimum of six passes in the EGCSE or equivalent high school qualification',
        'The passes must include a D grade or better in English Language and four other relevant subjects'
      ]
      // admissionRequirements: 'An applicant must have a minimum of six passes in the EGCSE or equivalent high school qualification. The passes must include a D grade or better in English Language and four other relevant subjects.'
};

const INSTITUTIONS = [
  {
        title: 'TKI University',
        fee: 100,
        acceptingApps: true,
        imgSrc: 'https://res.cloudinary.com/sasa-app/image/upload/v1634309935/tki_logo_a6gbsc.png',
        locations: ['Manzini', 'Mbabane'],
        govSponsored: true,
        openApps: true,
        accommodations: false,
        contacts: {
          email: 'tki.eswatini@gmail.com',
          phoneNumber: '+268 76260618',
          facebook: 'https://www.facebook.com/groups/120309763303006',
          website: 'https://www.tkieswatini.org',
        },
        courses: [
          {
            title: 'BA Business Leadership',
            cutOffPoints: 32,
            minimumCredits: null,
            minimumPass: null,
            subjectRequirements: null
          },
          {
            title: 'BA  Social Media Marketing',
            cutOffPoints: 32,
            minimumCredits: null,
            minimumPass: null,
            subjectRequirements: null
          },
          {
            title: 'Certificate in Finance and Entrepreneurship',
            cutOffPoints: 32,
            minimumCredits: null,
            minimumPass: null,
            subjectRequirements: null
          },
          {
            title: 'Diploma in Civic Engagement',
            cutOffPoints: 32,
            minimumCredits: null,
            minimumPass: null,
            subjectRequirements: null
          },
        ],
        admissionRequirements: [
          'An applicant must have a minimum of six passes in the EGCSE or equivalent high school qualification',
          'The passes must include a D grade or better in English Language and four other relevant subjects'
        ]
        // admissionRequirements: 'An applicant must have a minimum of six passes in the EGCSE or equivalent high school qualification. The passes must include a D grade or better in English Language and four other relevant subjects.'
  },
  {
    title: "Worker's University College",
    fee: 200,
    acceptingApps: true,
    imgSrc: "//s3.amazonaws.com/appforest_uf/f1623417754234x782607333134262900/workers%20college.jpg",
    locations: ["Manzini"],
    govSponsored: false,
    openApps: true,
    accommodations: false,
    contacts: {
      email: "info@workerscollegesd.com",
      phoneNumber: "(+268) 7687-8143, 7691-2228, 7655-5986",
      facebook: "https://www.facebook.com/workerscollegeswazi/",
      website: "https://lethokusha.wixsite.com/workerscollegesd"
    },
    courses: [
      {
        title: 'BSc in Health Information Management'
      },
      {
        title: 'BSc in Health Information Management'
      },
      {
        title: 'BSc in Health Information Management'
      }
    ],
    admissionRequirements: "3 credits & 3 passes incl. English for Primary teachers Diploma, 4 credits & 2 passes for secondary teachers diploma, 3 credits & 2 passes incl. English for Early Childhood teachers Diploma, 5 credits & 1 passes incl. English and commercial subjects for B.A Commerce",
},

{
  title: "William Pitcher College",
  fee: 100,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622185627798x349624298836687100/Screen%20Shot%202020-07-18%20at%208.20.13%20PM.png",
  locations: ["Manzini"],
  govSponsored: true,
  openApps: false,
  accommodations: true,
  contacts:
  {
    email: "wpcregistrar@outlook.com",
    phoneNumber: "(+268) 2505-2081",
    facebook: "https://www.facebook.com/William-Pitcher-College-2589259577800822/ ",
    website: "https://www.wpc.ac.sz/"
  },
  courses: [
    {
      title: "Primary Teachers Diploma",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Secondary Teachers Diploma",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "A minimum of six passes at SGCSE examination or equivalent including the English Language, three (3) of which must be credits in subjects taught in Primary Schools which are, Mathematics, SiSwati, History, Geography, Physics, Chemistry, Physical Science, Combined Science, Integrated Science, Biology, Human and Social Biology, Home Economics, Food & Nutrition, Fashion & Fabrics, Agriculture, Religious Knowledge, and English Literature. French, ICT, Life skills. (Subjects that will not be recognized as falling into the category of those taught in the Primary Schools are Accounts, Commerce, Technical Drawing, Metal Work, and Woodwork.OR Primary Teachers’ Certificate plus a minimum of five (5) passes at SGCSE or equivalent including the English language, three (3) of which must be credits in subjects taught in primary schools as mentioned in Primary Schools as mentioned in 1.01.  These students will enter Year 2 of the Diploma Programme. OR Any other equivalent qualification approved by the Board of Affiliated Institutions. Students who have passed some courses in other institutions of higher learning may be exempted from taking those courses again. SELECTION CRITERIA A student needs to meet the admission requirements, this may depend on competition amongst applicants."
},

{
  title: "U-Tech College",
  fee: 200,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623649792878x547269137324903230/logo-utech.jpg",
  locations: ["Big Bend"],
  govSponsored: false,
  openApps: true,
  accommodations: true,
  contacts:
  {
    email: "utkadmin@swazi.net",
    phoneNumber: "(+268) 7681-7134",
    facebook: "https://www.facebook.com/U-Tech-College-100596108449295",
    website: null
  },
  courses: [],
  admissionRequirements: "Credit/Pass in Accounting &/or Business related subjects for Business management Credit /Pass in Mathematics or Science for Civil Engineering"
},

{
  title: "University of Eswatini",
  fee: 530,
  acceptingApps: false,
  imgSrc: "https://upload.wikimedia.org/wikipedia/en/thumb/6/60/University_of_Eswatini_logo.svg/1200px-University_of_Eswatini_logo.svg.png",
  locations: ["Matsapha"],
  govSponsored: true,
  openApps: false,
  accommodations: true,
  contacts:
  {
    email: null,
    phoneNumber: "(+268) 2517-0000 (Kwaluseni Campus), 2517-0000/2527-4021 (Luyengo Campus), 2517-0000/2404-0171 (Mbabane Campus)",
    facebook: "https://www.facebook.com/EswatiniUni",
    website: "http://www.uneswa.ac.sz/"
  },
  courses: [
    {
      title: "BSc Animal Science (Dairy)",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Horticulture",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Textile and Apparel Design and Management",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Animal Science",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Consumer Science",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Information Science",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Geographical Information Systems",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Agricultural Extention",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Agronomy",
      cutOffPoints: 33,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BA Journalism and Mass Communication",
      cutOffPoints: 34,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Food Science Nutrition and Tech",
      cutOffPoints: 34,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Agriculture and Biosystems Engineering",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Consumer Science Education",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "B.Ed Secondary - Business Education",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "B.A. Humanities",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Computer Science Education",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Agricultural Economics and Agribusiness Management",
      cutOffPoints: 35,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Environmental Health and Food Science",
      cutOffPoints: 36,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BA Social Science",
      cutOffPoints: 36,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BA Social Work",
      cutOffPoints: 36,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Agricultural Education",
      cutOffPoints: 36,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Environmental Management and Occupational Health and Safety",
      cutOffPoints: 37,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Information Technology",
      cutOffPoints: 37,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "B.Ed Humanities - Secondary",
      cutOffPoints: 37,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelors of Commerce Bachelor of Science",
      cutOffPoints: 38,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc in Environmental Health Science",
      cutOffPoints: 39,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "B.Ed Secondary (Science)",
      cutOffPoints: 39,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "B.Eng Electrical & Electronic Engineering",
      cutOffPoints: 41,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Nursing Science",
      cutOffPoints: 42,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    }
  ],
  admissionRequirements: "In order to enter the University to study for a degree programme, you MUST have a minimum of SIX CREDIT PASSES (C GRADE OR BETTER) in the SGCSE/IGCSE, and these must include passes at C GRADE OR BETTER IN ENGLISH LANGUAGE AND FOUR OTHER RELEVANT SUBJECTS. The Mature Age Entry Scheme (details obtainable on request) serves as an alternative route for this special group of applicants i.e. applicants over 22 years of age who do not meet the normal entrance requirements. You must also note carefully the Special Requirements for the programme in each Faculty before you choose the programme you wish to apply for. SELECTION CRITERIA Applicants are selected in order of merit and the points calculated are from the best six subjects. The cut-off points for each program serve as a guide as to which program you best qualify for with your results."
},

{
  title: "Southern African Nazarene",
  fee: 300,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188877458x404910962840874240/SANU.jpg",
  locations: ["Manzini"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "admissions@sanu.ac.sz",
    phoneNumber: "(+268) 2505-5749",
    facebook: "https://www.facebook.com/SANUSWD",
    website: "http://www.sanu.ac.sz/"
  },
  courses: [
    {
      title: "Diploma in Early Childhood Education and Development Foundation Phase",
      cutOffPoints: 32,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Diploma in Pharmacy",
      cutOffPoints: 32,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Primary Teachers Diploma",
      cutOffPoints: 32,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Medical Laboratory Sciences",
      cutOffPoints: 36,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "BSc Nursing Science and Midwifrey",
      cutOffPoints: 38,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    }
  ],
  admissionRequirements: "A minimum of six  (6) passes at O’ level or equivalent, including English Language, four (4) of which must be credits in subjects taught in Primary Schools which are; Mathematics, SiSwati, History, geography, Physics, Chemistry, Physical Science, Combined Science, Integrated Science, Biology, human & Social Biology, Home Economics, Food & Nutrition, Fashion & Fabric, Agriculture, Religious Knowledge and English Literature. Other subjects will not be recognized. Holders of a Primary Teachers’ Certificate or equivalent qualification may be eligible to apply for the PTD. These students will enter at year 2 of the Diploma Programme."
},

{
  title: "RSTP - Advanced School of Information Technology",
  fee: 250,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188858499x228123364455699900/RSTP.png",
  locations: ["Manzini"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@rstp.org.sz",
    phoneNumber: "(+268) 2518-6386, (+268) 2517-9448",
    facebook: "https://www.facebook.com/RSTP-Advanced-School-of-Information-Technology-292249561130477/",
    website: "https://rstp.org.sz/"
  },
  courses: [
    {
      title: "Forensics Advanced Diploma in Hardware and Networking",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Advanced Diploma in Software Engineering",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Advanced Diploma in Multimedia",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Advanced Diploma in Cyber Security and Network",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Advanced Diploma in Information Security and Ethical Hacking",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "The target group for the Advanced School of IT is young Swazis who have completed their high school with credits in Math & science and mature professionals – keen to empower themselves in the field of Information Technology. The Advanced School of IT school admission policy will enable those who have credits in math & science to enroll regardless of their English grade, whereas collages around the country requires credit in English. The admission requirements of ASIT are: Minimum high school graduates. Minimum of 4 credits At least Pass in English Credit in Mathematics (except in Multimedia) Any other two relevant credits Special Admissions – for review by the admissions board."
},

{
  title: "Regent Business School",
  fee: 0,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623414771125x894270368606146400/rbs.jpg",
  locations: ["Manzini"],
  govSponsored: false,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "swazi@regent.ac.za",
    phoneNumber: "(+268) 2505-5890/ (+268) 2505-6633",
    facebook: "https://www.facebook.com/RegentBusinessSchool/",
    website: "https://regent.ac.za/"
  },
  courses: [],
  admissionRequirements: "4 - 5 credits incl. English Language for enrolment in a Degree 3 credits passes for enrolment in a Higher Certificate"
},

{
  title: "Ngwane Teacher Training",
  fee: 100,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188743678x204224685557448540/Ngwane%20.png",
  locations: ["Nhlangano"],
  govSponsored: true,
  openApps: false,
  accommodations: true,
  contacts:
  {
    email: "ngwanecollege@gmail.com",
    phoneNumber: "(+268) 2207-8112",
    facebook: "https://www.facebook.com/Ngwanecollege",
    website: "https://ngwanecollege.org/"
  },
  courses: [
    {
      title: "Primary Teachers Diploma",
      cutOffPoints: 24,
      minimumCredits: 3,
      minimumPass: 3,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Early Childhood Education",
      cutOffPoints: 24,
      minimumCredits: 3,
      minimumPass: 3,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "Candidates must have six (6) passes in a school leaving certificates, including English. Of the six passes; a minimum of three (3) must be credit in subjects that are taught at primary school level in Eswatini. These are: Mathematics, SiSwati, English Language, French, History, Geography, Physical Science, Chemistry, Combined Sciences, Integrated Science, Biology, Human and Social Biology, Fashion and Fabrics, Food and Nutrition, English literature, and Information Technology Applicants are selected in order of merit and this process includes an oral interview."
},

{
  title: "Mananga Management Center",
  fee: 660,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623650348534x465538750256305700/mmc.png",
  city: "Ezulwini",
  locations: ["Ezulwini"],
  govSponsored: null,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "info@mananga.org",
    phoneNumber: "(+268) 2416-3155/6",
    facebook: "https://www.facebook.com/manangacentre/",
    website: "http://www.mananga.org/"
  },
  courses: [],
  admissionRequirements: "5 credits or passes"
},

{
  title: "Management College of Southern Africa (MANCOSA)",
  fee: null,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623416324142x779223876527146900/MANCOSA.jpg",
  city: "Mbabane",
  locations: ["Mbabane"],
  govSponsored: false,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "",
    phoneNumber: "",
    facebook: "",
    website: "https://www.mancosa.co.za/"
  },
  courses: [],
  admissionRequirements: "5  credits incl. English language for enrolment in a Degree Form 5 IGCSE not recognized"
},

{
  title: "Limkokwing University",
  fee: 100,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188798626x503085774972231740/Limkokwing.jpg",
  city: "Mbabane",
  locations: ["Mbabane"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: null,
    phoneNumber: "(+268) 2422-0532, 2422-0528",
    facebook: "https://www.facebook.com/LimkokwingEswatini",
    website: "https://www.limkokwing.net/m/admission/limkokwing_eswatini"
  },
  courses: [
    {
      title: "Associate Degree in Hotel Management",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: "{english: null}"
    },
    {
      title: "Associate Degree in Information Technology",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: "{english: null}"
    },
    {
      title: "Associate Degree in Business Information Technology",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: "{english: null}"
    },
    {
      title: "Associate Degree in Public Relations",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in TV film Production",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Events Managemen",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Business Management",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in International Tourism",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Journalism",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Creative Multimedia",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Architectural Technology",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Associate Degree in Graphic Design",
      cutOffPoints: 20,
      minimumCredits: 4,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    }
  ],
  admissionRequirements: "Application Procedure: Step 1 - Applicants are required to provide: Certified copies of Academic Results relevant to entry requirements. Portfolio consisting 3 pieces of Original Artwork, if applicable. Step 2 Applicants are also required to submit: Photocopy of Identity Card (Local students only) 3 Passport size photographs with name written on the reverse For international students, applicants must submit three(3) copies of current passport including all blank pages Step 3 Submit completed application form. Send all the relevant documents together with the registration fee to the following address: Corner Somhlolo Avenue, MR3 Highway, Remainder Farm 1117, Sidwashini North, Mbabane, Swaziland. Step 4 For private students only, upon getting Admission Letter all payments for tuition fee are to be made to/in: Cheques or bank drafts, payable to LIMKOKWING UNIVERSITY (PTY) LTD. Please come to our Marketing office for the bank details."
},

{
  title: "Institute of Research MGT & DVPT (IRDM)",
  fee: 0,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623651322219x457132017479882500/download.png",
  city: "Mbabane",
  locations: ["Mbabane"],
  govSponsored: null,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "info@irdm-university-college.africa",
    phoneNumber: "(+268) 7671-6006, 7611-6353, 7634-3819 (WhatsApp)",
    facebook: "https://www.facebook.com/Be-a-graduate-with-IRDM-433246753815763",
    website: "https://www.irdm-university-college.africa/"
  },
  courses: [],
  admissionRequirements: "4 passes or foundation certificate"
},

{
  title: "Industrial Development Management (IDM)",
  fee: 300,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623649854554x354987381472071230/IDM.png",
  locations: ["Matsapha"],
  govSponsored: false,
  openApps: true,
  accommodations: null,
  contacts:
  {
    email: "khosien@idmbls.ac.sz",
    phoneNumber: "(+268) 2550-4085",
    facebook: "https://www.facebook.com/IDMSwaziland",
    website: "https://www.idmbls.com/eswatini-campus"
  },
  courses: [],
  admissionRequirements: "3 credits incl. English for Diploma in Human Resource Management"
},

{
  title: "Gwamile Voctim",
  fee: 50,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188908130x992698984555407700/Gwamile.png",
  locations: ["Matsapha"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@gwamilevoctim.ac.sz",
    phoneNumber: "(+268) 2518-6240/1",
    facebook: "https://www.facebook.com/Gwamile-Voctim-Matsapha-245273269462235",
    website: "http://www.gwamilevoctim.ac.sz/"
  },
  courses: [
    {
      title: "Diploma in Business Finance and Accounting",
      cutOffPoints: 18,
      minimumCredits: 3,
      minimumPass: 1,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Mechanical and Manufacturing Engineering",
      cutOffPoints: 18,
      minimumCredits: 3,
      minimumPass: 1,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Vehicle Body Repairs (returning students)",
      cutOffPoints: 18,
      minimumCredits: 3,
      minimumPass: 1,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "Application to be accompanied by: A receipt of E50.00 payable at any Government Revenue Office, Account No: 304/6002/61101. Certified copies of academic certificates. Certified copy of national ID. Certified copy of birth certificate. Deadline for return of applications is Wednesday, 31 March 2021. Application forms with incomplete enclosures will not be accepted."
},

{
  title: "Good Shepard College of Nursing",
  fee: 420,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622189099103x229262160889255100/Good%20Shepherd.jpeg",
  locations: ["Siteki"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@gscn.org.sz",
    phoneNumber: "(+268) 2343-6556/2343-4419",
    facebook: "https://www.facebook.com/GSCNsiteki/",
    website: "https://www.gscn.org.sz/"
  },
  courses: [
    {
      title: "Diploma in Occupational Health and Safety",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Diploma in General Nursing",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 2 }
    }
  ],
  admissionRequirements: "CERTIFICATE IN NURSING ASSISTANT:DURATION - 2 YEARS, Must have Four (4) Passes with Two (2) Credits, Mathematics/ or English (Credit), Science (Credit) and 4 Passes. DIPLOMA IN GENERAL NURSING: DURATION - 3 YEARS Six (6) Passes with Four (4) Credits, English (Credit), Mathematics (Credit), Science (Credit), Any credits from any Subjects"
},

{
  title: "Global University College",
  fee: 200,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623650616708x903764158497934100/guc.jpg",
  locations: ["Manzini"],
  govSponsored: false,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "info@globaluniversity.co",
    phoneNumber: "(+268) 7605-7533, 2505-7409",
    facebook: "https://www.facebook.com/Global-University-College-1049832331750187/",
    website: "https://www.globaluniversity.co/"
  },
  courses: [],
  admissionRequirements: "3 Credits incl. English & 2 passes for primary teachers diploma. 5 credits incl. English for Secondary Teachers Diploma. 3 credits incl. English & 2 passes for diploma in ICT, Project Management, Banking and Finance, Purchasing and Supply, Commerce & OHS. School leaving Certificate for Vocational courses. APPLICATON FREE FOR VOCATIONAL  COURSES"
},

{
  title: "Eswatini Medical Christian University",
  fee: 300,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622189050117x700072013965997300/Eswatini%20Chritian%20Medical%20UNI.jpg",
  city: "Mbabane",
  locations: ["Mbabane"],
  govSponsored: true,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@emcu.ac.sz",
    phoneNumber: "(+268) 2472-8157",
    facebook: "https://www.facebook.com/EswaMedicalUni",
    website: "https://emcu.ac.sz/"
  },
  courses: [
    {
      title: "Bachelor of Nursing Science",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Medical Laboratory Sciences",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Radiography",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Psychology",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Pharmacy",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Social Work",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    },
    {
      title: "Bachelor of Computer Science",
      cutOffPoints: 30,
      minimumCredits: 6,
      minimumPass: null,
      subjectRequirements: { english: 2 }
    }
  ],
  admissionRequirements: "A minimum of six credits obtained in the GCE “O” Level/IGCSE/SGCSE must be at C grade or better. Subjects that must be passed at C grade or better include the following: English Language, Mathematics (Math Literacy shall not be considered) NB: Some courses might require 1 or more science subject such as Biology and Physical Science"
},

{
  title: "Eswatini College of Technology",
  fee: 150,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623857814062x177085609095560800/SCOT.jpeg",
  locations: ["Mbabane"],
  govSponsored: true,
  openApps: false,
  accommodations: true,
  contacts:
  {
    email: "info@ecot.ac.sz",
    phoneNumber: "(+268) 2404-2681",
    facebook: "https://www.facebook.com/Eswatini-College-of-Technology-103958861740292",
    website: "https://ecot.ac.sz/"
  },
  courses: [
    {
      title: "Diploma in Hospitality and Tourism Management",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Human Resources Management",
      cutOffPoints: 27,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Office Management and Technology",
      cutOffPoints: 28,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "National Diploma in Mechanical Engineering",
      cutOffPoints: 29,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "National Diploma in Vehicle Body Repairs",
      cutOffPoints: 30,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "National Diploma in Electrical and Electronics Engineering",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "National Diploma in Automotive Engineering (Light and Heavy)",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Secondary Teachers Diploma: Commercials",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Secondary Teachers Diploma: ICT",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Civil Engineering",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    },
    {
      title: "Diploma in Computer Science",
      cutOffPoints: 26,
      minimumCredits: 4,
      minimumPass: 2,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "An applicant must have a minimum of six passes in the EGCSE or equivalent high school qualification. The passes must include a D grade or better in English Language and four other relevant subjects"
},

{
  title: "Emergency Medical Rescue College",
  fee: 150,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622185501758x909074140344793700/Emergency%20Medical%20Rescue%20College.png",
  locations: ["Mbabane"],
  govSponsored: true,
  openApps: false,
  accommodations: true,
  contacts:
  {
    email: "info@emrcollege.com",
    phoneNumber: "(+268) 2422-0239",
    facebook: "https://www.facebook.com/EMRCollege/",
    website: "http://www.emrcollege.com/"
  },
  courses: [],
  admissionRequirements: "Emergency Medical Rescue College is an institution that primarily focuses on training within the Emergency Medical Service (EMS), it only specializes in emergency medical care and rescue-related courses. Providing training to people already in the EMS but not limited to personnel within the Department of Defense, Police Service, Fire Department, and those intending on establishing a career within the EMS or Rescue Service."
},

{
  title: "CIT College",
  fee: 650,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623418391851x187761923198988450/cit.jpg",
  locations: ["Mbabane"],
  govSponsored: false,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@cit.ac.sz",
    phoneNumber: "(+268) 2422-0745",
    facebook: "https://www.facebook.com/CIT-College-657756967656448",
    website: "https://cit.ac.sz/"
  },
  courses: [],
  admissionRequirements: "Min. 5 passes across the board 3 credits incl. English for teaching courses 5 passes for short course."
},

{
  title: "Bradford University College",
  fee: 150,
  acceptingApps: false,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1623651693584x853074770484890500/bradford.png",
  locations: ["Manzini"],
  govSponsored: false,
  openApps: false,
  accommodations: false,
  contacts:
  {
    email: "info@bradforduniversity.co",
    phoneNumber: "(+268) 7638-5550",
    facebook: "https://www.facebook.com/Bradford-University-College-Manzini-Swaziland-1523128394656440/",
    website: "https://www.bradforduniversity.co/"
  },
  courses: [],
  admissionRequirements: "Min. 3 credits & 3 passes incl. English for Faculty of Education Min. 2 credits & passes for Faculties of IT, Management and Accounting"
},

{
  title: "Botho University",
  fee: 0,
  acceptingApps: true,
  imgSrc: "//s3.amazonaws.com/appforest_uf/f1622188975989x697941762774337200/botho.png",
  locations: ["Manzini"],
  govSponsored: true,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "eswatini@bothouniversity.ac.bw",
    phoneNumber: "(+268) 2505-2288",
    facebook: "https://www.facebook.com/BothoBU/",
    website: "https://swaziland.bothouniversity.com/"
  },
  courses: [
    {
      title: "BSc in Health Information Management",
      cutOffPoints: 32,
      minimumCredits: 5,
      minimumPass: null,
      subjectRequirements: { english: 1 }
    }
  ],
  admissionRequirements: "To get an offer of study in Botho University, student must have completed SGCSE, IGCSE or their equivalence, and have obtained minimum 5 Credit (Grade C or Better), with Mathematics and English. If your points add to 31 and below, your English and Mathematics must add up to 10 points. Programmes have their specific entry requirement that every student must meet to be admitted in to the programme. All students can apply in person or using the online application form available on the website. Please read the “Steps to Apply” document available on the application portal before applying. Clear certified copies of all relevant educational certificates. Clear certified copies of Omang/passport/identity card. Please read the Fee Structure and Policy document available on the website before applying"
},

{
  title: "AMADI University",
  fee: 120,
  acceptingApps: true,
  imgSrc: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1623416685982x698103262163511900%2FAMADI.jpg",
  city: "Mbabane",
  locations: ["Manzini", "Ngwenya", "Mbabane"],
  govSponsored: null,
  openApps: true,
  accommodations: false,
  contacts:
  {
    email: "info@amadiuniversity.org",
    phoneNumber: "(+268) 2405-0748",
    facebook: "https://www.facebook.com/amadiuniversity",
    website: "http://www.amadiuniversity.org/"
  },
  courses: [],
  admissionRequirements: "Application free for short courses and 3 credits incl. a commercial subject for Administration courses, 5 credits incl. English for Education Courses, 3 credits incl. English for Early Childhood Care Courses Diploma required for enrolment in a Degree"
},
];

export default INSTITUTIONS;
