import ArrowIcon from '../../assets/js-icons/ArrowIcon';

var classNames = require('classnames');

const BackButton = ({ className }) => {
  function handleClick(){
      window.history.back();
  };

  return (
    <div className={classNames('d-flex flex-row', className)}>
      <ArrowIcon className='mr-10' rotation='180deg'/>
      <p className={'m-0'} onClick={handleClick}>back</p>
    </div>
  );
};

BackButton.defaultProps = {
  className: null
};

export default BackButton;
