import axios from "axios";
import {
  API_BASE_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  STUDENT_USER_TYPE,
  STUDENT_APPLICATIONS,
  SCHOOL_APPLICATIONS_SEARCH,
} from "./constants.js";
import useSWR from "swr";

export const loginUser = (email, password) => {
  // make an api call to the rails back end to log in
  return axios.post(LOGIN_URL, {
    user: { email, password },
  });
};

export const signUpUser = ({ firstName, lastName, email, password }) => {
  return axios.post(SIGN_UP_URL, {
    user: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      user_type: STUDENT_USER_TYPE,
    },
  });
};

// CREATE A NEW APPLICATION
export const createStudentApplicationQuery = (studentId, schoolId, form ) => {
  return axios.post(STUDENT_APPLICATIONS, {
    student_application: {
      student_id: studentId,
      school_id: schoolId,
      form: form,
    },
  })
}

// UPDATE EXSITING APPLICATION
export const updateStudentApplicationQuery = (studentId, schoolId, form ) => {
  const URL = `/students/${studentId}/applications`

  return axios.put(API_BASE_URL + URL, {
    student_application: {
      student_id: studentId,
      school_id: schoolId,
      form: form,
    },
  })
}

// SUBMIT STUDENT APPLICATION
export const submitStudentApplicationQuery = (studentId, schoolId, form ) => {
  const URL = `/students/${studentId}/applications/submit`

  return axios.put(API_BASE_URL + URL, {
    student_application: {
      student_id: studentId,
      school_id: schoolId,
      form: form,
    },
  })
}

export const createStudentDocumentQuery = ({title, url, student_id, doc_type, key}) => {
  const URL = `${API_BASE_URL}/student_documents`;
  const params = {
    student_document: {
      title, 
      url, 
      student_id, 
      doc_type, 
      key,
    }
  };
  return axios.post(URL, params)
};

export const updateStudentApplicationStatusQuery = ({ applicationId, status, lastReviewedOn }) => {
  const URL = `${API_BASE_URL}/student_applications/${applicationId}/status`;
  const params = {
    student_application: {
      status,
      last_reviewed_on: lastReviewedOn,
    },
  };
  return axios.put(URL, params)
}

export const fetcherPost = (data) => async (url) => {
  console.log('fetcher post data: ', data)
  const res = await axios.post(url, data);
  if (res.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.data;
    error.status = res.status;
    throw error;
  } else {
    return res.data;
  }
};

export const fetcherGet = async (url) => {
  const res = await axios.get(url);
  if (res.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.data;
    error.status = res.status;
    throw error;
  } else return res.data;
};

export const fetcherPut = async (url, params) => {
  const res = await axios.put(url, { params });
  if (res.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.data;
    error.status = res.status;
    throw error;
  } else {
    return res.data;
  }
};

export const useApiGetSchoolCourses = (params) => {
  const { data, error } = useSWR(
    `${API_BASE_URL}/school/${params.id}/courses`,
    fetcherGet
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetSchoolProfile = (params) => {
  const { data, error } = useSWR(
    `${API_BASE_URL}/schools/${params.id}`,
    fetcherGet
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetSchools = (params) => {
  const { data, error } = useSWR(`${API_BASE_URL}/schools`, fetcherGet);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetCourses = () => {
  const { data, error } = useSWR(`${API_BASE_URL}/courses`, fetcherGet);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetSchoolApplications = (schoolId) => {
  const { data, error } = useSWR(`${API_BASE_URL}/schools/${schoolId}/applications`, fetcherGet);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetSchoolStudentApplication = (studentId, schoolId) => {
  const { data, error } = useSWR(`${API_BASE_URL}/schools/${schoolId}/applications/students/${studentId}`, fetcherGet);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useApiGetSchoolApplicationsSearchResults = (schoolId) => {
  const { data, error } = useSWR(SCHOOL_APPLICATIONS_SEARCH, fetcherGet);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

// FETCH STUDENT APPLICATIONS BY STUDENT ID
export const useApiGetStudentApplicationsByStudentId = (studentId) => {
  const URL = `${API_BASE_URL}/students/${studentId}/applications`
  const { data, error } = useSWR(URL, fetcherGet);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
