import { Redirect, Route } from "react-router";
const PrivateRoute = ({
  component: Component,
  ...props
}) => {
  const { pageProps } = props
  return (
    <Route
      {...props}
      render={(innerProps) =>
        localStorage.getItem("user") ? (
          <Component {...innerProps} {...pageProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};


export default PrivateRoute
