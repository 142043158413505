import './index.css';


export const SingleDigit = ({ item, className, subTitleClassName }) => {
  return (
      <div className={className} id='single-digit-container'>
        <p className='single-digit-value'>{ item.value }</p>
        <p className='diagram-title'>{ item.title }</p>
        <p className={subTitleClassName}>{ item.subTitle }</p>
      </div>
  )
};

SingleDigit.defaultProps = {
  subTitleClassName: null,
}
