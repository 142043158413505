import templates from '../../constants/application-templates'
const AdminTemplate = ({ match }) => {
  const key = match.params.key;
  const template = templates?.[key]

  if(!key || !template){
    return 'template does not exist'
  }

  return JSON.stringify(template)
};

export default AdminTemplate
