import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Image,
  Text,
  Icon,
  Link,
  Button,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { BsFacebook } from "react-icons/bs";
import { FaGlobeAfrica } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { GrDocumentText } from "react-icons/gr";

import AppHeader from "../../../../components/AppHeader";
import AppDateField from "../../../../components/ApplicationModels/AppDateField";
import Course from "../../../../components/Course";
import INSTITUTIONS from "../../../../constants/institutions";

import DocumentIcon from "../../../../assets/js-icons/DocumentIcon";
import MultiBuildingIcon from "../../../../assets/js-icons/MultiBuildingIcon";

import "../../App.css";
import { useApiGetSchoolCourses, useApiGetSchoolProfile } from "../../../../api";
import { useRecoilValue } from "recoil";
import { schoolAtom, userAtom } from "../../../../state";

const Profile = () => {
  const history = useHistory();
  const user = useRecoilValue(userAtom)
  // var school = INSTITUTIONS[0];

  const { data: courses, isLoading:coursesLoading, error:coursesError } = useApiGetSchoolCourses({ id: user.school_id });
  const {data: profile, isLoading:profileLoading, error:profileError} = useApiGetSchoolProfile({id: user.school_id})

  if (profileError) return <Text>An error has occurred</Text>;
  if (profileLoading) return <Text>Loading</Text>;
  if (!profile) return <Text>An error has occurred </Text>;

  if (coursesError) return <Text>An error has occurred loading courses.</Text>;
  if (coursesLoading) return <Text>Loading</Text>;
  if (!courses) return <Text>An error has occurred </Text>;

  return (
    <>
      <body className="d-md-flex">
        <AppHeader />
        <div className="col-xs-12 app-body-container" id="app-container">
          <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
            <Flex>
              <Text fontSize={25} fontWeight={"500"}>
                Profile
              </Text>
              <Button
                onClick={() => history.push("profile/edit")}
                _hover={{ bg: "#FFA800", color: "black" }}
                fontSize={13}
                w={55}
                h={35}
                ml={3}
                bg="black"
                color={"white"}
                borderRadius={0}
              >
                Edit
              </Button>
            </Flex>
            <Flex
              mt={10}
              flexDir={["column", "column", "column", "column", "row", "row"]}
            >
              <Flex flexDir={"column"}>
                <Flex>
                  <Image
                    src={'https://res.cloudinary.com/sasa-app/image/upload/v1634309935/tki_logo_a6gbsc.png'}
                    width={144}
                    height={144}
                  />
                  <Flex w="50%" flexDir={"column"} ml={10}>
                    <Text fontWeight={"700"}>{profile.name}</Text>
                    <Text mt={2}>{profile.email}</Text>
                    <Text mt={2}>{profile.phoneNumber}</Text>
                    <Flex mt={2}>
                      <Flex>
                        <Icon w={25} h={25} color="#4267B2" as={BsFacebook} />
                        <Link
                          href={profile.facebook}
                          alignSelf={"center"}
                          ml={2}
                        >
                          Facebook
                        </Link>
                      </Flex>
                      <Flex ml={3}>
                        <Icon w={25} h={25} as={FaGlobeAfrica} />
                        <Link
                          href={profile.website}
                          alignSelf={"center"}
                          ml={2}
                        >
                          Website
                        </Link>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex mt={10} flexDir={"column"}>
                  <Text>
                    Accepting apps from <b>10/01/2022 - 10/05/2022</b>
                  </Text>
                </Flex>
              </Flex>
              <Flex
                flexDir={"column"}
                mt={[10, 10, 10, 10, 0, 0]}
                ml={[0, 0, 0, 0, 5, 5]}
                w={["100%", "100%", "100%", "100%", "50%", "50%"]}
              >
                <Flex>
                  <Flex w={25} h={25}>
                    <MultiBuildingIcon />
                  </Flex>
                  <Flex ml={3} flexDir={"column"}>
                    <Text fontWeight={"700"}>Accomodation</Text>
                    <Text mt={2}>yes</Text>
                  </Flex>
                </Flex>
                <Flex flexDir={"row"} mt={5}>
                  <Icon w={25} h={25} as={IoLocationSharp} />
                  <Flex ml={3} flexDir={"column"}>
                    <Text mb={2} fontWeight={"700"}>
                      Locations
                    </Text>
                    <Flex flexDir={"column"}>
                      {profile.locations.map((location, index) => (
                        <Text key={index}>
                          {index + 1}. {location}
                        </Text>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDir={"row"} mt={5}>
                  <Icon w={25} h={25} as={GrDocumentText} />
                  <Flex ml={3} flexDir={"column"}>
                    <Text mb={2} fontWeight={"700"}>
                      Admission Requirements
                    </Text>
                    <UnorderedList flexDir={"column"}>
                      {profile.admission_requirements.map(
                        (requirement, index) => (
                          <ListItem key={index}>
                            <Text>{requirement}</Text>
                          </ListItem>
                        )
                      )}
                    </UnorderedList>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex mt={5} mb={5} flexDir="column">
              <Flex mb={10}>
                <Text fontWeight={"700"}>Courses</Text>
                <Button
                  onClick={() => history.push("/app/courses")}
                  _hover={{ bg: "#FFA800", color: "black" }}
                  fontSize={13}
                  w={55}
                  h={35}
                  ml={3}
                  bg="black"
                  color={"white"}
                  borderRadius={0}
                >
                  Edit
                </Button>
              </Flex>
              <Flex flexDir={"column"}>
                {courses.map((course, index) => (
                  <Course course={course} mode="view" key={index} />
                ))}
              </Flex>
            </Flex>
          </Flex>
        </div>
      </body>
    </>
  );
};

export default Profile;
