import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import ArrowIcon from "../../assets/js-icons/ArrowIcon";
import { useState } from "react";
import { JOIN_SASA_URL, ANALYTICS_EVENTS } from "../../constants";
import ReactGA from "react-ga";
import ClickUpSurveyModal from "../../components/ClickUpSurveyModal";
import { isMobile, isWeb } from "../../utilities";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useApiGetSchoolProfile } from "../../api";
import { MdLocationOn } from "react-icons/md";
var classNames = require("classnames");

const CourseSearchResultModal = ({ isOpen, onClose, course }) => {
  const [surveyModalState, setSurveyModalState] = useState(false);
  const {
    data: profile,
    isLoading,
    error,
  } = useApiGetSchoolProfile({ id: course.school_id });

  const toggleSurveyModal = () => {
    setSurveyModalState(!surveyModalState);
    ReactGA.event(ANALYTICS_EVENTS.JOIN_SASA_CTA);
  };

  if (isLoading) return <Text>Loading</Text>;
  if (error) return <Text>An error has occurred</Text>;
  if (!profile) return <Text>An error has occurred </Text>;
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader fontWeight={"700"} fontSize={25}>
            <Flex>
              <Flex _hover={{ cursor: "pointer" }} onClick={onClose}>
                <ArrowIcon
                  className={classNames(
                    "search-results-modal-arrow-icon",
                    { "rotate-180": isWeb },
                    { "rotate-90": isMobile }
                  )}
                />
              </Flex>
              <Heading className="text-center m-auto">{course.title}</Heading>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Flex justify={"center"}>
              <Flex flexDir={"column"}>
                <Flex>
                  <Text fontWeight={"600"}>Institution: </Text>
                  <Text ml={1}>{profile.title}</Text>
                </Flex>
                <Flex>
                  <Text fontWeight={"600"}>Graduate Level: </Text>
                  <Text ml={1}>{course.graduate_level}</Text>
                </Flex>
                <Flex>
                  <Text fontWeight={"600"}>Sponsorship Priority: </Text>
                  <Text ml={1}>{course.sponsorship_priority}</Text>
                </Flex>
                <Flex>
                  <Text fontWeight={"600"}>Course Type: </Text>
                  <Text ml={1}>{course.course_type}</Text>
                </Flex>
                <Flex>
                  <Text fontWeight={"600"}>Current Points: </Text>
                  <Text ml={1}>
                    {course.cut_off_points ? course.cut_off_points : 0}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDir={"column"} p={50} mt={10}>
              <Flex>
                <Icon ml={2} h={25} w={25} as={BsFillInfoCircleFill} />
                <Flex flexDir={"column"} ml={5}>
                  <Heading fontWeight={"600"} fontSize={25}>
                    About Course
                  </Heading>
                  <Text>{course.description}</Text>
                </Flex>
              </Flex>
              <Flex mt={10}>
                <Icon h={35} w={35} as={MdLocationOn} />
                <Flex flexDir={"column"} ml={5}>
                <Heading fontWeight={"600"} fontSize={25}>
                  Locations
                </Heading>
                {profile.locations.map((location, index) => (
                  <Text>{location}</Text>
                ))}
                </Flex>
              </Flex>
            </Flex>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => toggleSurveyModal()}
                className={classNames(
                  "search-result-modal-button text-center",
                  { mobile: isMobile },
                  { web: isWeb }
                )}
              >
                <p className="search-result-modal-getstarted-link m-0 p-0">
                  Join Sasa
                </p>
              </button>
            </div>
            <ClickUpSurveyModal
              modalState={surveyModalState}
              toggleModal={toggleSurveyModal}
              url={JOIN_SASA_URL}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CourseSearchResultModal;
