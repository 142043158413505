import {
  useState,
  Fragment,
  useEffect,
} from 'react';

import { useRecoilValue } from 'recoil';
import ReactGA from 'react-ga';


import Drawer from '@material-ui/core/Drawer';
import './home-header.css';

import hamburgerMenu from '../../assets/hamburger-menu@24px.png';
import hamburgerMenuWhite from '../../assets/hamburger-menu-white.png';
import HomeRoundIcon from '../../assets/js-icons/HomeRoundIcon';
import BuildingRoundIcon from '../../assets/js-icons/BuildingRoundIcon';
import AppPencilIcon from '../../assets/js-icons/AppPencilIcon';
import LoginIcon from '../../assets/js-icons/LoginIcon';
import SasaLogo from '../../assets/js-icons/SasaLogo';
import { releaseV1 } from '../../releases';
import { isWeb, isMobile, getVisitorStorage } from '../../utilities';
import { visitorTypeAtom } from '../../state';

import { JOIN_SASA_URL, REQUEST_DEMO_URL, ANALYTICS_EVENTS } from '../../constants';
import {
  Flex,
  Link
} from '@chakra-ui/react';

var classNames = require('classnames');

const HOME_LINK = "Home";
const INSTITUTIONS = "Browse Institutions";
const COURSES = "Browse Courses";
const LOGIN = "Login";
const SIGN_UP = "Sign Up";


const MobileHomeHeader = ({ activeLink, buttonCopy, visitor }) => {
  const [drawerState, toggleDrawer] = useState(false);

  function handleSignUpCta() {
    window.location.replace('/sign-up')
  }

  const LinkList = () => {
    return (
      <Fragment>
        <div onClick={() => toggleDrawer(false)} id='mobile-nav-link-list'>
          <a href='/' className='mobile-nav-link-list-item'>
            <span>{<HomeRoundIcon />}</span> {HOME_LINK}
          </a>
          <a href='/search' className='mobile-nav-link-list-item'>
            <span>{<BuildingRoundIcon />}</span> {INSTITUTIONS}
          </a>

           <a href='/courses' className='mobile-nav-link-list-item'>
            <span>{<AppPencilIcon />}</span> {COURSES}
          </a>


          <a href='/login' className='mobile-nav-link-list-item'>
            <span>{<LoginIcon />}</span>{LOGIN}
          </a>

          <a href='/sign-up' className='mobile-nav-link-list-item'>
            <button className='sign-up'>
              {SIGN_UP}
            </button>
          </a>
        </div>
      </Fragment>
    );
  };

  return (
    <div className='mobile-nav-header d-flex flex-row beta-release-black' id='home-mobile-nav'>
      <div className='left' onClick={() => toggleDrawer(true)}>
        <img src={hamburgerMenuWhite} />
      </div>

      <div className='ml-10'>
        <SasaLogo className='sasa-icon-logo' />
      </div>

      <div className='right'>
        <p
          onClick={() => handleSignUpCta()}
          className='m-0 underline'
          style={{ fontSize: 11, fontWeight: 'bold' }}>Click To {buttonCopy}</p>
      </div>

      <Drawer anchor={'left'} open={drawerState} onClose={() => toggleDrawer(false)}>
        <LinkList />
      </Drawer>
    </div>
  );
};

const HomeHeader = () => {
  const [activeLink, setActiveLink] = useState(null);
  const defaultLinkStyles = 'nav-header-link';
  const visitor = useRecoilValue(visitorTypeAtom) || getVisitorStorage()
  const buttonCopy = 'Sign Up';

  function handleSignUpCta() {
    window.location.replace('/sign-up')
  }

  return (
    <Fragment>
      <Flex flexDir='row' h={62} alignItems='center' pt='24px' bg='white' pb='8px' justifyContent='space-between' className='d-none d-md-flex'>
        <Flex ml='24px' alignItems='center'>
          <SasaLogo className='sasa-icon-logo' />

          <Link style={{textDecoration:'none'}} href='/' mr='40px' className={classNames({ 'active': activeLink === HOME_LINK ? true : false })}>{HOME_LINK}</Link>

          <Link style={{textDecoration:'none'}} href='/search' mr='40px' className={classNames({ 'active': activeLink === INSTITUTIONS ? true : false })}>{INSTITUTIONS}</Link>

         <Link style={{textDecoration:'none'}} href='/courses' mr='40px' className={classNames({'active': activeLink === COURSES ? true : false })}>{COURSES}</Link>
        </Flex>

        <Flex className='right' alignItems='center' justifyContent='space-around' style={{width: 200, marginRight: 40}}>
          <a href='/login' className={classNames(defaultLinkStyles, { 'active': activeLink === LOGIN ? true : false })}>{LOGIN}</a>
          <a href='/sign-up' className={classNames(defaultLinkStyles, { 'active': activeLink === SIGN_UP ? true : false })}>
            <button
              onClick={() => handleSignUpCta}
              className='sign-up-header-button'>
              {buttonCopy}
            </button>
          </a>
        </Flex>
      </Flex>

      <div className='d-flex d-md-none'>
        <MobileHomeHeader buttonCopy={buttonCopy} visitor={visitor} />
      </div>
    </Fragment>
  )
};

export default HomeHeader;
