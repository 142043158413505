import { useState } from 'react';
import {
  Flex,
  Link,
  Text,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import './index.css'

const SignatureField = ({ initialValue, handleSignature }) => {
  const [ text, setText ] = useState(initialValue);
  const [ signature, setSignature] = useState(initialValue);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
      onOpen()
  }

  const handleSetText = (e) => {
    e.preventDefault()
    setText(e.target.value)
  }

  const handleSubmit = () => {
    setSignature(text)
    handleSignature(text)
    onClose()
  }

  return (
    <>
      {!text && <Text className='empty-field underline' onClick={handleOpen}>Click To Sign</Text>}
      {signature && <Text className='signature-field-two signed-field' onClick={handleOpen}>{signature}</Text>}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={10} style={{paddingTop:24}}>
          <Flex flexDir='column' className='signature-modal'>
            <Input placeholder="Sign With Name" onChange={handleSetText} className='signature-input'/>
            <br></br>
            {text && <Text className='signature-field-two signed-field'>{text}</Text>}
            <hr></hr>
          </Flex>
          <ModalFooter>
            <Button borderRadius={0} colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              borderRadius={0}
              color="white"
              _hover={{ bg: "#FFA800", color: "black" }}
              bg="black"
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignatureField;
