import { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Button,
  Input,
} from "@chakra-ui/react";
import { Formik } from "formik";

import AppHeader from '../AppHeader';
import AppForm from '../AppForm';
import ProgressCounter from '../ProgressCounter';
import BackButton from '../BackButton';

const PageForm = ({ title, fields, initialValues, hideTitle=false, handleSubmit }) => {
  const fieldsTotal = fields.length
  const [form, setForm] = useState(initialValues)
  const [formProgress, setFormProgress] = useState(null)
  const errorHandler = null


  const onSubmit = (newFormValues) => {
    setForm(newFormValues)
    handleSubmit(newFormValues)
  }

  const handleProgressCounter = ()=>{
    const progress = Object.keys(form).reduce((acc, key)=>{
      if(!!form[key]){
        acc = acc + 1
      }
      return acc
    }, 0)
    setFormProgress(progress)
  }

  useEffect(()=>{
    if(form){
      handleProgressCounter()
    }
  }, [form])

  useEffect(()=>{
    if(initialValues){
      setFormProgress(Object.keys(initialValues)?.length)
    }
  }, [initialValues])

  return (
    <Flex flexDir={'column'}>
      <Flex mt={4} mb={4}>
        <BackButton />
      </Flex>
      <Flex flexDir={'column'} p={3}>
        {!hideTitle && <Text className='text-center'>{title}</Text>}
        <AppForm
          fields={fields}
          errorHandler={errorHandler}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          />
      </Flex>
    </Flex>
  )
};

PageForm.defaultValues = {
}

export default PageForm
