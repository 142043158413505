const courses = [
    {
        id: 1,
        title: 'BSc in Health Information Management',
        sponsorship_priority: 'High',
        course_type: 'Bachelor of science',
        cut_off_points: 200,
        graduate_level: 'undergraduate',
        description: 'Agronomy and crop science majors are highly sought after in today\’s economy and they offer a wide variety of career paths. If you love working outdoors with soil and plants, consider studyingagronomy, crop science and agriculture',
        duration: '5 years',
        location: 'Luyengo Campus',
        job_prospects: [
            'Soil Conservation Technician',
            'Crop Manager',
            'Agronomist',
            'Seed Analyst',
            'Crop Protection Services Specialist'
        ]
    },
    {
        id: 2,
        title: 'BSc in Health Information Management 2',
        sponsorship_priority: 'Low',
        course_type: 'Bachelor of science',
        cut_off_points: 50,
        graduate_level: 'undergraduate',
        description: 'Agronomy and crop science majors are highly sought after in today\’s economy and they offer a wide variety of career paths. If you love working outdoors with soil and plants, consider studyingagronomy, crop science and agriculture',
        duration: '5 years',
        location: 'Luyengo Campus',
        job_prospects: [
            'Soil Conservation Technician',
            'Crop Manager',
            'Agronomist',
            'Seed Analyst',
            'Crop Protection Services Specialist'
        ]
    },
    {
        id: 3,
        title: 'BSc in Health Information Management 3',
        sponsorship_priority: 'Medium',
        course_type: 'Bachelor of science',
        cut_off_points: 50,
        graduate_level: 'undergraduate',
        description: 'Agronomy and crop science majors are highly sought after in today\’s economy and they offer a wide variety of career paths. If you love working outdoors with soil and plants, consider studyingagronomy, crop science and agriculture',
        duration: '5 years',
        location: 'Luyengo Campus',
        job_prospects: [
            'Soil Conservation Technician',
            'Crop Manager',
            'Agronomist',
            'Seed Analyst',
            'Crop Protection Services Specialist'
        ]
    },
]

export default courses