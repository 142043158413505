import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga'

import './search-result-modal.css';

import MultiBuildingIcon from '../../assets/js-icons/MultiBuildingIcon';
import DocumentIcon from '../../assets/js-icons/DocumentIcon';
import LocationIcon from '../../assets/js-icons/LocationIcon';
import PencilIcon from '../../assets/js-icons/PencilIcon';
import ArrowIcon from '../../assets/js-icons/ArrowIcon';

import { JOIN_SASA_URL, ANALYTICS_EVENTS } from '../../constants';

import { isMobile, isWeb } from '../../utilities';
import { Flex, Image, Text } from '@chakra-ui/react';

var classNames = require('classnames');

const useStyles = makeStyles({
  list: {
    height: isMobile ? '85vh' : '100%',
    padding: '10px',
  }
});

const InstitutionSearchResultModal = ({ item, toggleModal }) => {
  const [ surveyModalState, setSurveyModalState ] = useState(false);
  var classes = useStyles();

  function handleSignUpCta(){
    window.location.replace('/sign-up')
  }

  return (
    <div className='search-results-modal-container' id={classNames({ 'search-results-web-modal': isWeb })}>

      <div className={classNames(classes.list, 'search-result-modal-list', 'scroll-y')}>

        <section>
          <div className='d-flex'>
            <div onClick={() => toggleModal(false)}>
              <ArrowIcon className={classNames('search-results-modal-arrow-icon', { 'rotate-180': isWeb }, { 'rotate-90': isMobile })} />
            </div>
            <Text fontWeight={'500'} fontSize={20} className='text-center mb-20 m-auto'>{item.title}</Text>
          </div>
          <Flex className='d-flex flex-row search-result-modal-header'>
            <Image src={item.logo_image_src} width={'96px'} height={'80px'} />
            <div>
              <p>E-Mail: {item.email}</p>
              <p>Phone Number: {item.phone_number}</p>
              {/*<p>Application Fee: E{item.fee}</p>*/}
              <p>Accepting Applications: {!!item.accepting_applications ? 'yes' : 'no'}</p>
            </div>
          </Flex>
        </section>

        <section className='search-result-modal-section-container'>
          <div className='search-result-modal-section'>
            <div className="search-result-modal-section-title-wrap">
              <MultiBuildingIcon className='icon' />
              <p className='bold search-result-modal-section-title'>Accomodation</p>
            </div>
            <div className='search-result-modal-section-body'>
              {!!item.accommodations && <p>This school provides accommodation</p>}
              {!item.accommodations && <p>This school does not provide accommodation</p>}
            </div>
          </div>

          <div className='search-result-modal-section'>
            <div className="search-result-modal-section-title-wrap">
              <LocationIcon className='icon' />
              <p className='bold search-result-modal-section-title'>Locations</p>
            </div>
            <div className='search-result-modal-section-body'>
              {item.locations.map((location) => {
                return <p>{location}</p>
              })}
            </div>
          </div>

          <div className='search-result-modal-section'>
            <div className="search-result-modal-section-title-wrap">
              <PencilIcon className='icon' />
              <p className='bold search-result-modal-section-title'>Courses</p>
            </div>
            <div className='search-result-modal-section-body search-result-modal-courses'>
              {item.courses?.map((course) => {
                return <p className='mt-10'>{course.title}</p>
              })}
            </div>
          </div>

          <div className='search-result-modal-section'>
            <div className="search-result-modal-section-title-wrap">
              <DocumentIcon className='icon' />
              <p className='bold search-result-modal-section-title'>Admission Requirements</p>
            </div>
            <div className='search-result-modal-section-body pb-10'>
              <p>{item.admission_requirements}</p>
            </div>
          </div>
        </section>
      </div>

      <div className='d-flex justify-content-center'>
        <button
          onClick={()=>handleSignUpCta()}
          className={classNames('search-result-modal-button text-center', { 'mobile': isMobile }, { 'web': isWeb })}>
          <p className='search-result-modal-getstarted-link m-0 p-0'>Sign Up</p>
        </button>
      </div>
    </div>
  )
};

export default InstitutionSearchResultModal;
