import { NavLink } from 'react-router-dom';
import {
  Flex,
  Text,
} from "@chakra-ui/react";

import CheckMarkFilledIcon from '../../assets/js-icons/CheckMarkFilledIcon';


const FormDetailSummary = ({ title, link, containerStyles={}, isComplete=false}) => {
  const styles = {
      detailsContainer: {
        margin: 5,
        padding: 20,
        backgroundColor: '#EBEBEB',
        ...containerStyles
      },
      detailsTitle: {
        fontWeight: 600
      }
  }

  return (
    <NavLink to={link}>
      <Flex justifyContent='space-between' style={styles.detailsContainer}>
        <Text style={styles.detailsTitle}>{title}</Text>
        <CheckMarkFilledIcon checked={isComplete}/>
      </Flex>
    </NavLink>
  );
}

export default FormDetailSummary
