import { useEffect, useState } from "react";
import "./index.css";
import { Flex, Text } from "@chakra-ui/react";

import { useRecoilValue, useRecoilState } from "recoil";
import {
  userAtom,
  academicDetailsAtom,
  personalDetailsAtom,
  nextOfKinDetailsAtom,
  studentProfileDocumentsAtom,
} from "../../../../state";

import AppHeader from "../../../../components/AppHeader";
import FormDetailSummary from "../../../../components/FormDetailSummary";
import PhotoUploadAndDisplay from "../../../../components/PhotoUploadAndDisplay";
import StudentNotice from "../../../../components/StudentNotice";
import PERSONAL_DETAILS_FIELDS from "../../../../constants/student-personal-details/personal-details";
import NEXT_OF_KIN_DETAILS_FIELDS from "../../../../constants/student-personal-details/next-of-kin-details";

const styles = {
  photoUploadContainer: {
    margin: "auto",
    marginTop: 20,
  },
};

const Profile = () => {
  const user = useRecoilValue(userAtom);
  const academicDetails = useRecoilValue(academicDetailsAtom);
  const personalDetails = useRecoilValue(personalDetailsAtom);
  const nextOfKinDetails = useRecoilValue(nextOfKinDetailsAtom);
  const studentDocuments = useRecoilValue(studentProfileDocumentsAtom);

  const [personalDetailsCompleted, setPersonalDetailsCompleted] =
    useState(null);
  const [nextOfKinDetailsCompleted, setNextOfKinDetailsCompleted] =
    useState(null);
  const [academicDetailsCompleted, setAcademicDetailsCompleted] =
    useState(null);
  const [uploadDocumentsCompleted, setUploadDocumentCompleted] = useState(null);

  const mockApplicationsFetch = async (e) => {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    await sleep(2000).then(() => console.log("finished fetching data"));
  };
  const getIsDetailsSectionComplete = (formValues, fieldsTemplate) => {
    const requiredFieldNames = fieldsTemplate
      .filter((item) => item?.required)
      .map((i) => i.name);
    return !requiredFieldNames.find((i) => !formValues[i]);
  };

  useEffect(() => {
    const allFieldsComplete = academicDetails.length > 0;
    setAcademicDetailsCompleted(allFieldsComplete);
  }, [academicDetails]);

  useEffect(() => {
    const allFieldsComplete = getIsDetailsSectionComplete(
      personalDetails,
      PERSONAL_DETAILS_FIELDS
    );
    setPersonalDetailsCompleted(allFieldsComplete);
  }, [personalDetails]);

  useEffect(() => {
    const allFieldsComplete = getIsDetailsSectionComplete(
      nextOfKinDetails,
      NEXT_OF_KIN_DETAILS_FIELDS
    );
    setNextOfKinDetailsCompleted(allFieldsComplete);
  }, [nextOfKinDetails]);

  useEffect(()=>{
    const allFieldsComplete = !Object.keys((studentDocuments)).find((i)=>studentDocuments[i] === null)
    setUploadDocumentCompleted(allFieldsComplete)
  }, [studentDocuments])

  mockApplicationsFetch();

  return (
    <Flex flexDir={"column"}>
      <AppHeader />
      <StudentNotice />
      <Flex style={styles.photoUploadContainer}>
        <PhotoUploadAndDisplay />
      </Flex>
      <FormDetailSummary
        link="/app/student/profile/personal-details"
        title="Personal Details"
        isComplete={personalDetailsCompleted}
      />
      <FormDetailSummary
        link="/app/student/profile/academic-details"
        title="Academic Details"
        isComplete={academicDetailsCompleted}
      />
      <FormDetailSummary
        link="/app/student/profile/next-of-kin-details"
        title="Next Of Kin Details"
        isComplete={nextOfKinDetailsCompleted}
      />
      <FormDetailSummary
        link="/app/student/profile/documents"
        title="Upload Documents"
        isComplete={uploadDocumentsCompleted}
      />
    </Flex>
  );
};

export default Profile;
