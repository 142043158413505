import { useState, useEffect, Fragment } from 'react';
import './index.css';

var classNames = require('classnames');

const VIEW_MODE = 'view';
const EDIT_MODE = 'edit';

const AppDateField = ({ value, label, name, mode, className, onChange }) => {
  const [date, setDate] = useState(value);

  const handleOnChange = (event) => {
    onChange(event)
    setDate(event.target.value);
  };


  const ViewField = () => {
    return(
      <Fragment>
        <label>{label}:</label>
        <div className='application-date-view'>
          <p>{value}</p>
        </div>
      </Fragment>)
  };

  const EditField = () => {
    return(
      <Fragment>
        {!!label && <label className='date-label'>{ label }</label> }

        <input
          id='app-date-input'
          type='date'
          name={name}
          value={date}
          onChange={(event)=>handleOnChange(event)}
        />
      </Fragment>)
  }

  return (
    <div className={classNames('d-flex flex-row application-date-container', className, { 'view-mode' : mode === VIEW_MODE })}>
      { mode === VIEW_MODE ? <ViewField /> : <EditField /> }
    </div>
  );
};

AppDateField.defaultProps = {
  label: 'date',
  mode: VIEW_MODE,
  onChange: (e)=>{
    console.log('event: ', e);
  },
  name: null,
  value: null,
};

export default AppDateField;
