var APPLICANTS = [
  {
    id:1,
    name: 'Samkelo Motsa',
    first_name: 'Samkelo',
    last_name: 'Motsa',
    email: 'motsasamkelo@gmail.com',
    course: 'Secondary Teachers Diploma',
    points: 32,
    status: 'not reviewed',
    applied_on: new Date('09/04/2021'),
    last_school_attended: {
      name: 'Manzini Central High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: "https://res.cloudinary.com/sasa-app/image/upload/v1634310010/Screen_Shot_2021-10-15_at_9.59.52_AM_onygvt.png",
  },
  {
    id:2,
    name: 'Nomile Dlamini',
    first_name: 'Nomile',
    last_name: 'Dlamini',
    email: 'nomiledlamini13@gmail.com',
    course: 'Primary teacher Diploma',
    points: 28,
    status: 'not reviewed',
    applied_on: new Date('08/16/2021'),
    last_school_attended: {
      name: 'St. Pauls Methodist High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:3,
    name: 'Malima Mdletshe',
    first_name: 'Malima',
    last_name: 'Mdletshe',
    email: 'mdletshe.malima@example.com',
    course: 'Nursing',
    points: 35,
    status: 'not reviewed',
    applied_on: new Date('08/16/2021'),
    last_school_attended: {
      name: 'Ngwane Park High School',
      graduation_date: new Date('15/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:4,
    name: 'Nokwanda Mtsetfwa',
    first_name: 'Nokwanda',
    last_name: 'Mtsetfwa',
    email: 'nokwandamtstfwa@example.com',
    course: 'Associate degree in Business Management',
    points: 29,
    status: 'not reviewed',
    applied_on: new Date('07/16/2021'),
    last_school_attended: {
      name: 'St. Francis High School',
      graduation_date: new Date('12/10/2018'),
    },
    profile_photo: null,
  },
  {
    id:5,
    name: 'Siboniso Mondlane',
    first_name: 'Siboniso',
    last_name: 'Mondlane',
    email: 'mondlanesiboniso@yahoo.com',
    course: 'Mechanical Engineering',
    points: 33,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Gilgal High School',
      graduation_date: new Date('12/10/2020'),
    },
    profile_photo: null,
  },
  {
    id:6,
    name: 'Zandile Mlotshwa',
    first_name: 'Zandile',
    last_name: 'Mlotshwa',
    email: 'mlotshwazandile@hotmail.com',
    course: 'Civil Engineering',
    points: 34,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Manzini Nazarene High School',
      graduation_date: new Date('09/11/2018'),
    },
    profile_photo: null,
  },
  {
    id:7,
    name: 'Penelope Anastasia',
    first_name: 'Penelope',
    last_name: 'Anastasia',
    email: 'anastasia.penelope@gmail.com',
    course: 'Social Psychology',
    points: 27,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Hillside High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:8,
    name: 'Ntuthuko Hlophe',
    first_name: 'Ntuthuko',
    last_name: 'Hlophe',
    email: 'ntuthukohlophe.com',
    course: 'Bachelor of Commerce in Management',
    points: 26,
    status: 'not reviewed',
    applied_on: new Date('09/08/2021'),
    last_school_attended: {
      name: 'Mtfonjeni High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:9,
    name: 'Nonduduzo Dlamini',
    first_name: 'Nonduduzo',
    last_name: 'Dlamini',
    email: 'dlaminindu@gmail.com',
    course: 'Bachelor of Commerce in Marketing',
    points: 38,
    status: 'not reviewed',
    applied_on: new Date('07/27/2021'),
    last_school_attended: {
      name: 'Manzini High School',
      graduation_date: new Date('09/11/2018'),
    },
    profile_photo: null,
  },
  {
    id:10,
    name: 'King Malindzisa',
    first_name: 'King',
    last_name: 'Malindzisa',
    email: 'malindzisa.king@gmail.com',
    course: 'Diploma in Adult Education',
    points: 27,
    status: 'not reviewed',
    applied_on: new Date('06/16/2021'),
    last_school_attended: {
      name: 'Evelyn Baring High School',
      graduation_date: new Date('09/11/2018'),
    },
    profile_photo: null,
  },
  {
    id:11,
    name: 'Nongcebo Skhosana',
    first_name: 'Nongcebo',
    last_name: 'Skhosana',
    email: 'nongceboskhosana@yahoo.com',
    course: 'Bachelor of Arts in Humanities',
    points: 33,
    status: 'not reviewed',
    applied_on: new Date('10/08/2021'),
    last_school_attended: {
      name: 'Duze High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:12,
    name: 'Motsa Siphosami',
    first_name: 'Motsa',
    last_name: 'Siphosami',
    email: 'siphosamimotsa@gmail.com',
    course: 'Associate Degree in Film and TV',
    points: 28,
    status: 'not reviewed',
    applied_on: new Date('03/04/2021'),
    last_school_attended: {
      name: 'Mahlangatsha High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:13,
    name: 'Sukati Siyanda',
    first_name: 'Sukati',
    last_name: 'Siyanda',
    email: 'siyandasukati@gmail.com',
    course: 'Bachelor of Science (Hons) in Information Technology',
    points: 33,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Herefords High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:14,
    name: 'Meluleki Gwebu',
    first_name: 'Meluleki',
    last_name: 'Gwebu',
    email: 'gwebumeluleki@gmail.com',
    course: 'Bachelor of Engineering in Electrical and Electrical Engineering',
    points: 35,
    status: 'not reviewed',
    applied_on: new Date('08/16/2021'),
    last_school_attended: {
      name: 'Mhlume High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:15,
    name: 'Sithembiso Mandlazi',
    first_name: 'Sithembiso',
    last_name: 'Mandlazi',
    email: 'mandlazisthembiso.com',
    course: 'Bachelor of laws (LLB)',
    points: 45,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Siteki Nazarene High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:16,
    name: 'Phumzile McFadden',
    first_name: 'Phumzile',
    last_name: 'McFadden',
    email: 'phumzilem@yahoo.com',
    course: 'BCOM in Accounting',
    points: 28,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'St. Theresa\'s High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:17,
    name: 'Ndlovu Mangaliso',
    first_name: 'Ndlovu',
    last_name: 'Mangaliso',
    email: 'mangalison@gmail.com',
    course: 'Higher Certificate in Retail Management',
    points: 32,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'St Marks High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:18,
    name: 'Senkhosi Matsebula',
    first_name: 'Senkhosi',
    last_name: 'Matsebula',
    email: 'senkhosim@outlook.com',
    course: 'Associate Degree in Graphic Design',
    points: 29,
    status: 'not reviewed',
    applied_on: new Date('06/17/2021'),
    last_school_attended: {
      name: 'U-Tech High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:19,
    name: 'Pascwa Pelef',
    first_name: 'Pascwa',
    last_name: 'Pelef',
    email: 'pelefpascwa@outlook.com',
    course: 'BCOM in Supply Chain Management',
    points: 27,
    status: 'not reviewed',
    applied_on: new Date('07/20/2021'),
    last_school_attended: {
      name: 'New Heaven High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:20,
    name: 'Banele Mamba',
    first_name: 'Banele',
    last_name: 'Mamba',
    email: 'mambabenele@yahoo.com',
    course: 'Bsc in Health Information Management',
    points: 33,
    status: 'not reviewed',
    applied_on: new Date('02/05/2021'),
    last_school_attended: {
      name: 'Mkhuzweni High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:21,
    name: 'Panashe Nyakapau',
    first_name: 'Panashe',
    last_name: 'Nyakapau',
    email: 'panashenyakapau@outlook.com',
    course: 'Bsc in Mobile Computing',
    points: 33,
    status: 'not reviewed',
    applied_on: new Date('05/16/2021'),
    last_school_attended: {
      name: 'Mhobodleni High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:22,
    name: 'Muzi Mkhonta',
    first_name: 'Muzi',
    last_name: 'Mkhonta',
    email: 'mkhontamuzi@yahoo.com',
    course: 'Bsc in Netowrk and Computer Forensics',
    points: 35,
    status: 'not reviewed',
    applied_on: new Date('05/24/2021'),
    last_school_attended: {
      name: 'Salesian High School',
      graduation_date: new Date('01/01/2021'),
    },
    profile_photo: null,
  },
  {
    id:23,
    name: 'Siniketiwe Masilela',
    first_name: 'Siniketiwe',
    last_name: 'Masilela',
    email: 'masilela.sinikitiwe@gmail.com',
    course: 'Bsc in Network and Computer Forensics',
    points: 36,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'St. Michaels High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:24,
    name: 'Mncedisi Shabalala',
    first_name: 'Mncedisi',
    last_name: 'Shabalala',
    email: 'shabalalamncedisi@hotmail.com',
    course: 'Bsc in Computing',
    points: 31,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Swazi National High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
  {
    id:25,
    name: 'Temancele Mavuso',
    first_name: 'Temancele',
    last_name: 'Mavuso',
    email: 'temancelem@gmail.com',
    course: 'Bsc in Business Management',
    points: 31,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Ka-Boyce High School',
      graduation_date: new Date('09/11/2020'),
    },
    profile_photo: null,
  },
  {
    id:26,
    name: 'Kwanele Nxumalo',
    first_name: 'Kwanele',
    last_name: 'Nxumalo',
    email: 'nxumalokwanele@yahoo.com',
    course: 'Bsc in Accounting',
    points: 32,
    status: 'not reviewed',
    applied_on: new Date('09/16/2021'),
    last_school_attended: {
      name: 'Ka-Schiele High School',
      graduation_date: new Date('09/11/2019'),
    },
    profile_photo: null,
  },
];

export default APPLICANTS
