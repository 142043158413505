import './app-header.css';
import { Fragment, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text, Link as NavLink2, Divider } from '@chakra-ui/react';
import Drawer from '@material-ui/core/Drawer';

import { userTypeAtom, userAtom } from '../../state';
import { handleLogOut } from '../../utilities';
import HydrateUserSession from '../HydrateUserSession';
import { STUDENT_USER_TYPE, SCHOOL_USER_TYPE } from '../../api/constants.js';
import SasaLogo from '../../assets/js-icons/SasaLogo.js';
import hamburgerMenu from '../../assets/hamburger-menu@24px.png';


const PROFILE_LINK = "Profile";
const DOCUMENTS_LINK = "Documents";
const LOG_OUT = "Log Out";

// INSTITUTIONS LINKS
const APPLICANTS_LINK = "Applicants";
const APPLICATIONS_LINK = "Applications";
const DASHBOARD_LINK = "Dashboard";
const COURSES_LINK = "Courses";

const InstitutionAppHeaderMobile = () => {
  const [drawerState, toggleDrawer] = useState(false);

  const StudentLinkList = () => {
    return (
      <div id='mobile-nav-link-list' className='d-flex flex-column'>
        {/**<NavLink to='/app/dashboard' className='mobile-nav-link-list-item'>{DASHBOARD_LINK}</NavLink>**/}
        <NavLink to='/app/profile' className='mobile-nav-link-list-item'>{PROFILE_LINK}</NavLink>
        <NavLink to='/app/courses' className='mobile-nav-link-list-item'>{COURSES_LINK}</NavLink>
        <NavLink to='/app/applicants' className='mobile-nav-link-list-item'>{APPLICANTS_LINK}</NavLink>
        <Text pos='absolute' className='logout-btn mobile' _hover={{ cursor: 'pointer' }} onClick={() => handleLogOut()}>{LOG_OUT}</Text>
      </div>
    );
  };

  return (
    <Flex className='pl-20 d-flex align-items-center'>
      <Flex onClick={() => toggleDrawer(true)} w={'30px'} h={'30px'}>
        <img src={hamburgerMenu} />
      </Flex>
      <Flex className='ml-20 align-items-center'>
        <SasaLogo />
      </Flex>
      <Drawer anchor={'left'} open={drawerState} onClose={() => toggleDrawer(false)}>
        <StudentLinkList />
      </Drawer>
    </Flex>
  )
};

const InsitutionAppHeaderWeb = () => {
  return (
    <Flex w={300} h={'100vh'} pos='fixed' flexDir={'column'} borderRight='2px solid #EFEFEF'>
      <Flex w={150}>
        <SasaLogo />
      </Flex>
      <Flex id='app-web-nav-link-list' className='d-flex flex-column'>
        <NavLink2 href='/app/profile'>{PROFILE_LINK}</NavLink2>
        <NavLink2 href='/app/courses'>{COURSES_LINK}</NavLink2>
        <NavLink2 href='/app/applicants'>{APPLICANTS_LINK}</NavLink2>
        <Text pos='absolute' className='logout-btn' _hover={{ cursor: 'pointer' }} onClick={() => handleLogOut()}>{LOG_OUT}</Text>
      </Flex>
    </Flex>
  );
};

const InsitutionAppHeaders = () => {
  return (
    <Fragment>
      <Flex className='d-flex d-md-none'>
        <InstitutionAppHeaderMobile />
      </Flex>
      <Flex className='d-none d-md-flex'>
        <InsitutionAppHeaderWeb />
      </Flex>
    </Fragment>
  );
};

const StudentAppHeaderMobile = () => {
  const [drawerState, toggleDrawer] = useState(false);

  const StudentLinkList = () => {
    return (
      <div id='mobile-nav-link-list' className='d-flex flex-column'>
        <NavLink to='/app/student/profile' className='mobile-nav-link-list-item'>Profile</NavLink>
        <NavLink to='/app/student/applications' className='mobile-nav-link-list-item'>My Applications</NavLink>
        <Text pos='absolute' className='logout-btn mobile' _hover={{ cursor: 'pointer' }} onClick={() => handleLogOut()}>{LOG_OUT}</Text>
      </div>
    );
  };

  return (
    <Flex className='pl-20 d-flex align-items-center'>
      <Flex onClick={() => toggleDrawer(true)} w={'30px'} h={'30px'}>
        <img src={hamburgerMenu} alt='Hamburger Menu' />
      </Flex>
      <Flex className='ml-20 align-items-center'>
        <SasaLogo />
      </Flex>
      <Drawer anchor={'left'} open={drawerState} onClose={() => toggleDrawer(false)}>
        <StudentLinkList />
      </Drawer>
    </Flex>
  )
}

const StudentAppHeaders = () => {
  return (
    <Fragment>
      <Flex className='d-flex'>
        <StudentAppHeaderMobile />
      </Flex>
    </Fragment>
  )
}

const AppHeader = () => {
  const userType = useRecoilValue(userTypeAtom);
  const user = useRecoilValue(userAtom);

  if(userType === SCHOOL_USER_TYPE){
    return <InsitutionAppHeaders />
  } else if (userType === STUDENT_USER_TYPE) {
    return <StudentAppHeaders />
  }
};

export default AppHeader;
