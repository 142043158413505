const ArrowIcon = ({ className, rotation }) => {
  return (
    <img
      className={className}
      style={
        { transform: `rotate(${rotation})` }
      }
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNSAzbDMuMDU3LTMgMTEuOTQzIDEyLTExLjk0MyAxMi0zLjA1Ny0zIDktOXoiLz48L3N2Zz4=" />
  )
};

ArrowIcon.defaultProps = {
  rotation: '0deg'
};

export default ArrowIcon;
