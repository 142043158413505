import {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userTypeAtom, userAtom, schoolAtom, schoolApplicantsResultsAtom } from '../../../../state';
import { useApiGetSchoolApplications } from '../../../../api';
import './applicants.css';
import '../../App.css';

import Slider from '@material-ui/core/Slider';

import AppHeader from '../../../../components/AppHeader';
import SearchBar from '../../../../components/SearchBar';

import APPLICANTS from '../../../../constants/applicants.js';
import { Input } from '@chakra-ui/react';

var classNames = require('classnames');

const Applicants = () => {
    const user = useRecoilValue(userAtom)
    const userType = useRecoilValue(userTypeAtom)
    const school = useRecoilValue(schoolAtom)
    const searchPlaceHolder = 'search by student name, email or course choice'
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [searchTerm, setSearchTerm] = useState(null)
    const [points, setPoints] = useState([0, 100])
    const [page, setPage] = useState(0)
    const [pageIndexes, setPageIndexes] = useState([1])
    const { data: applicants, isLoading, error } = useApiGetSchoolApplications(school.id)
    const [ applicantsState, setApplicantsState ] = useRecoilState(schoolApplicantsResultsAtom)
    const [filteredApplicants, setFilteredApplicants] = useState([])
    const [activeFilters, setActiveFilters] = useState({
      searchTerm: null,
      startDate: null,
      endDate: null,
      points: null,
    })
    const handleSelectedDates = (event, period) => {
      event.preventDefault();
      let date = event.target.value;

      if(period==='start'){
        setStartDate(date)
        setActiveFilters({...activeFilters, startDate: date})
      } else if (period==='end') {
        setEndDate(date)
        setActiveFilters({...activeFilters, endDate: date})
      };
    };

    const handleSearchInput = (input) => {
      setActiveFilters({...activeFilters, searchTerm: input})
    };

    const handlePointsChange = (event, newValue) => {
      setPoints(newValue)
      setActiveFilters({...activeFilters, points: newValue})
    }

    useEffect(()=>{
      const results = applicants?.reduce((acc, item)=>{
        acc[item?.student_id] = item
        return acc
      }, {})
      setApplicantsState(results)
    }, [applicants])

    useEffect(()=>{
      let numOfPages = Math.ceil(filteredApplicants.length / 16)
      let pageIndexArray = []

      for (var i = 1; i <= numOfPages; i++) {
         pageIndexArray.push(i)
      };
      setPageIndexes(pageIndexArray)
    }, [])

    useEffect(()=>{
      if(applicants){
        let newList = applicants.filter((item)=>{
          return (
            (activeFilters.searchTerm === null || matchSearchResults(item, activeFilters.searchTerm)) &&
            (activeFilters.startDate === null || matchStartDate(item)) &&
            (activeFilters.endDate === null || matchEndDate(item)) &&
            (activeFilters.points === null || matchPoints(item))
          );
        });
        setFilteredApplicants(newList);
      } else {
        setFilteredApplicants([]);
      }
    }, [activeFilters, applicants]);

    function matchSearchResults(item, searchTerm){
      // match names
      if(item.full_name.toLowerCase().includes(searchTerm.toLowerCase())){
        return true
      }

      // match email
      if(item.email.toLowerCase().includes(searchTerm.toLowerCase())){
        return true
      }

      // match email
      if(item.first_academic_choice.toLowerCase().includes(searchTerm.toLowerCase())){
        return true
      }

      return false
    };

    function matchStartDate(item){
      return new Date(startDate) <= item.applied_on
    }

    function matchEndDate(item){
      return new Date(endDate) >= item.applied_on
    }

    function matchPoints(item){
      return (item.points >= activeFilters.points[0] && item.points <= activeFilters.points[1])
    }

    function handleNextPage(index){
      if(index + 1 < pageIndexes[pageIndexes.length-1]){
        setPage(index+1);
      }
    };

    function handlePrevPage(index){
      if(index + 1 > pageIndexes[0]){
        setPage(index-1);
      }
    };


    return (
      <body className='d-md-flex' id='applicants-page-container'>
        <header id='app-header-container' className='col-xs-12 col-md-3'>
          <AppHeader />
        </header>

        <div className='col-xs-12 app-body-container'>
          <h1 className='applicants-title'>Applicants</h1>

          <section id='applicants-filters-container' className='d-flex flex-row'>
            <div className='applicants-search-bar-container applicant-filters col-5'>
              <SearchBar placeholder={searchPlaceHolder} className='applicants-search-bar' handleInputChange={handleSearchInput}/>
            </div>

            <div id='applicants-date-filters' className='applicant-filters'>
              <label>application dates</label>
              <div className='d-flex flex-row'>
                <label className='date-label'>from</label><Input borderRadius={0} h={35} type='date' onChange={(event)=>handleSelectedDates(event, 'start')} name='startDate' key='startDate01'/>
                <label className='date-label'>to</label><Input borderRadius={0} h={35} type='date' onChange={(event)=>handleSelectedDates(event, 'end')} name='endDate' key='endDate01'/>
              </div>
            </div>

            <div className='applicant-filters' id='applicant-slider-container'>
              <label>total points</label>
              <Slider
                value={points}
                onChange={handlePointsChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </div>
          </section>

          <header id='applicants-search-results-header'>
            <label className='applicant-name-wrapper'>name</label>
            <label className='applicant-email-wrapper'>email</label>
            <label className='applicant-course-wrapper'>course choice</label>
            <label className='applicant-points-wrapper'>points</label>
            <label className='applicant-status-wrapper'>application status</label>
          </header>

          <section id='applicants-result-container'>
            {
              filteredApplicants.slice(page * 16, (page * 16 + 16)).map((item, index)=>{
                let styleOdd = index === 0 || index % 2 === 0 ? null : {
                  backgroundColor: '#F5F5F5'
                }
                return <NavLink to={`/app/applicants/${item.student_id}`} key={`applicants-item-${index}`}>
                    <div className='d-flex applicants-result' style={styleOdd}>
                        <p className='applicant-name-wrapper'>{item.full_name}</p>
                        <p className='applicant-email-wrapper'>{item.email}</p>
                        <p className='applicant-course-wrapper'>{item.first_academic_choice}</p>
                        <p className='applicant-points-wrapper'>{item.points}</p>
                        <p className='applicant-status-wrapper'>{item.status}</p>
                    </div>
              </NavLink>
            })}

            <div className='d-flex flex-row' id='applicants-results-navigation'>
              <label onClick={()=>handlePrevPage(page)}>prev</label>
                {pageIndexes.map((p, index)=>{
                  return <p className={classNames('page', {'active': p===page+1})} onClick={()=>setPage(index)}>{p}</p>
                })}
              <label onClick={()=>handleNextPage(page)}>next</label>
            </div>
          </section>


        </div>

      </body>
    )
};

export default Applicants
