import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const SubmitStudentApplicationModal = React.memo(({ openModal, handleOnClose }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(()=>{
    if(openModal){
      onOpen()
    };
  }, [openModal])

  const handleConfirmation = (isConfirmed)=>{
      handleOnClose(isConfirmed)
      onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={0}>
          <ModalHeader>Submit Final Application</ModalHeader>
          <ModalBody>
            <Text>this action cannot be un-done</Text>
          </ModalBody>
          <ModalFooter>
          <Button borderRadius={0} colorScheme="green" mr={3} onClick={()=>handleConfirmation(true)}>
            Yes
          </Button>
          <Button
            onClick={()=>handleConfirmation(false)}
            borderRadius={0}
            color="white"
            _hover={{ bg: "#FFA800", color: "black" }}
            bg="black"
          >
            Cancel
          </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
});

export default SubmitStudentApplicationModal
