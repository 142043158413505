import { studentDetailsKeys } from '../../constants/student-details-keys'
import { validatePresence, validateDateText } from '../../utilities/formValidators'

const { personalDetails } = studentDetailsKeys
const dateFormat = 'yyyy/mm/dd'

const FIELDS = [
  {
    title: 'Id Number',
    name: personalDetails.idNumber,
    fieldType: 'input',
    validate: (input) => {
      if(!validatePresence(input)){
        return "Id is required"
      }
    },
    required: true,
  },
  {
    title: 'Date Of Birth',
    name: personalDetails.dob,
    fieldType: 'input',
    placeholder: dateFormat,
    validate: (input) => {
      if(!validatePresence(input)){
        return "Date of birth is required"
      }
      if(!validateDateText(input, dateFormat)){
        return `Please enter a valid date in format ${dateFormat}`
      }
    },
    required: true,
  },
  {
    title: 'Gender',
    name: personalDetails.gender,
    fieldType: 'checkboxes',
    options:
      {
        values: [
          {
            title: 'female',
            name: 'female',
          },
          {
            title: 'male',
            name: 'male',
          },
          {
            title: 'prefer not to say',
            name: 'other',
          },
        ]
      },
    validate: (input) => {
      if(!input){
        return "Gender is required"
      }
    },
    required: true,
  },
  {
    title: 'Citizenship',
    name: personalDetails.citizenship,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "Citizenship is required"
      }
    },
    required: true,
  },
  {
    title: 'Do you have any disability or special need that you would like tertiary institutions to consider during admissions?',
    name: personalDetails.disability,
    fieldType: 'textarea',
    validate: null,
    required: false,
  },
  {
    title: 'What are your academic or professional interests?',
    name: personalDetails.academicProfessionalInterests,
    fieldType: 'textarea',
    validate: null,
    required: false,
  }
]

export default FIELDS
