import ApplicationSectionViewContainer from '../ApplicationSectionViewContainer';
import AcademicDetailItem from '../AcademicDetailItem';
import SectionView from '../SectionView';
import AcademicApplicationView from '../AcademicApplicationView';

import { APPLICATION_SECTION_TYPES } from '../../constants/application-templates/types';


const renderGradeList = (section) => {
  const grades = section?.grades;

  return <>
    {
      !!grades ? grades.map((grade)=><AcademicDetailItem item={grade} />) : null
    }
  </>
};

const ApplicationSectionList = ({ sections }) => {
  return sections.map((section)=>{
    if(section.type === APPLICATION_SECTION_TYPES.standard
      || section.type === APPLICATION_SECTION_TYPES.signature
      || section.type === APPLICATION_SECTION_TYPES.document
    ){
      return <ApplicationSectionViewContainer header={section.header} title={section.title}>
          <SectionView fields={section.fields} className='d-flex flex-row flex-wrap'/>
        </ApplicationSectionViewContainer>
    } else if (section.type === APPLICATION_SECTION_TYPES.academics) {
      return <AcademicApplicationView header={section?.header} title={section?.title} grades={section?.grades} />
    }
  });
};

export default ApplicationSectionList
