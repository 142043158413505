import { useRef, useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";

import { useRecoilValue, useRecoilState } from 'recoil';
import { userAtom } from '../../../../state';
import { selectStudentApplicationBySchoolId, selectStudentApplicationIsSubmitted } from '../../../../state/selectors';
import { APPLICATION_SECTION_TYPES } from '../../../../constants/application-templates/types';
import { makeKey } from '../../../../utilities';

import SectionView from '../../../../components/SectionView';
import AppHeader from '../../../../components/AppHeader';
import ApplicationSectionViewContainer from '../../../../components/ApplicationSectionViewContainer';
import SelectApplicationSection from '../../../../components/SelectApplicationSection';
import AcademicDetailItem from '../../../../components/AcademicDetailItem';
import SubmitStudentApplicationModal from '../../../../components/SubmitStudentApplicationModal';


import { submitStudentApplicationQuery } from '../../../../api';

const styles = {
  selectApplicationSectionStyle: {
    width: '100%',
  },
  sectionSelectorContainer: {
    width: '100%',
    maxWidth: 787,
    margin: 'auto',
    position: 'fixed',
    zIndex: 2,
  },
  selectionsContainer: {
    marginTop:100
  },
  submitButton: {
    height: 60,
    width: 360,
    bottom: 0,
    margin: 'auto',
    backgroundColor: 'black',
    color: 'white'
  },
  hasBeenSubmittedText: {
    textAlign:'center',
    color:'#3498db',
    fontWeight: 500,
    fontSize: 20,
  }
}

const renderGradeList = (section) => {
  const grades = section?.grades
  return <>
    {
      !!grades ? grades.map((grade)=><AcademicDetailItem item={grade} />) : null
    }
  </>
};

const StudentApplicationsReview = ({ match }) => {
  const toast = useToast();
  const user = useRecoilValue(userAtom);
  const schoolId = match.params.school_id;
  const [ studentApplication, setStudentApplication ] = useRecoilState(selectStudentApplicationBySchoolId(schoolId));
  const [ applicationIsSubmitted, setApplicationIsSubmitted ] = useState(null)
  const [ openModal, setOpenModal ] = useState(false)
  const sections = studentApplication?.sections;
  const sectionTitles = sections.reduce((acc, section)=>{
    const title = section?.title
    if(title){
      acc.push(title)
    };
    return acc
  }, [])
  const itemsRef = useRef([]);

  useEffect(() => {
       itemsRef.current = itemsRef.current.slice(0, sections.length);
  }, [sections]);

  useEffect(()=>{
    setApplicationIsSubmitted(studentApplication?.is_submitted)
  }, [studentApplication])


  const executeScroll = (index) => itemsRef?.current?.[index]?.scrollIntoView();

  const handleSubmitApplication = () => {
    const studentId = user?.student_id;
    submitStudentApplicationQuery(studentId, schoolId, studentApplication).then((res)=>{
      setApplicationIsSubmitted(true)
      toast({
        title: "Success",
        description: "Congrats Application Has Been Submitted!",
        status: "success",
        duration: 2000,
      })
    }).catch((err)=>{
      toast({
        title: "Failed",
        description: "Sorry something went wrong, please try again",
        status: "error",
        duration: 2000,
      })
    })
  };

  const handleConfirmationModal = (isConfirmed) => {
    if(isConfirmed){
      handleSubmitApplication()
    }
    setOpenModal(false)
  }

  const canShowSubmitButton = !applicationIsSubmitted;

  return (
    <Flex flexDir='column' style={{paddingBottom:50, backgroundColor:'#e3e3e3'}}>
      <Flex style={styles.sectionSelectorContainer} className='d-md-none'>
        <SelectApplicationSection
          items={sectionTitles}
          handleSelect={executeScroll}
          style={styles.selectApplicationSectionStyle}
          />
      </Flex>
      <Flex style={styles.selectionsContainer} flexDir='column'>
        {
          sections.map((section, i)=>{
            if(section.type === APPLICATION_SECTION_TYPES.standard  
              || section.type === APPLICATION_SECTION_TYPES.signature
              || section.type === APPLICATION_SECTION_TYPES.document){
              return <Flex ref={el => itemsRef.current[i] = el} key={makeKey()}>
                <ApplicationSectionViewContainer header={section?.header} title={section?.title}>
                  <SectionView fields={section?.fields}/>
                </ApplicationSectionViewContainer>
              </Flex>
            } else if (section.type === APPLICATION_SECTION_TYPES.academics) {
              return <Flex ref={el => itemsRef.current[i] = el} key={makeKey()}>
                <ApplicationSectionViewContainer header={section?.header} title={section?.title}>
                  {renderGradeList(section)}
                </ApplicationSectionViewContainer>
              </Flex>
            }
          })
        }
      </Flex>
      {
        canShowSubmitButton && <Button style={styles.submitButton} onClick={()=>setOpenModal(true)}>Submit</Button>
      }
      {
        applicationIsSubmitted && <Text style={styles.hasBeenSubmittedText}>Congrats This Application Has been Submitted! 🎉</Text>
      }
      <SubmitStudentApplicationModal openModal={openModal} handleOnClose={handleConfirmationModal} />
    </Flex>
  )
};

export default StudentApplicationsReview
