import React from "react";
import { Flex, Heading, useToast } from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from 'recoil';

import BackButton from "../../../../../components/BackButton";
import DocumentUpload from "../../../../../components/DocumentUpload";
import { studentProfileDocumentsAtom, userAtom } from "../../../../../state";
import { createStudentDocumentQuery } from '../../../../../api';

const docTypes = {
  transcript: 'pdf',
  photoId: 'jpg',
};

const docKeys = {
  transcript: 'transcript',
  photoId: 'photoId',
};

const docTitles = {
  transcript: 'Transcript',
  photoId: 'Photo Id'
};

const StudentDocuments = () => {
  const [documents, setDocuments] = useRecoilState(studentProfileDocumentsAtom);
  const user = useRecoilValue(userAtom);
  const toast = useToast()


  const handleDocUpload = (url, name) => {
    const params = {
      title: docTitles[name],
      url,
      student_id: user?.student_id,
      doc_type: docTypes[name],
      key: docKeys[name],
    };
    setDocuments({...documents, ...{[name]: url}});
    createStudentDocumentQuery(params).catch((err)=>{
      setDocuments({...documents, ...{[name]: null}});
      toast({
        title: "Error",
        description: "Document upload failed, please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    })
  };

  return (
    <>
      <Flex flexDir={"column"} w="100%" h="100vh">
        <Flex w="100%" flexDir={"row"} h={58}>
          <Flex mt={4} mb={4}>
            <BackButton />
          </Flex>
          <Flex w="100%" justifyContent="center"></Flex>
        </Flex>
        <Flex alignSelf={"center"} flexDir='column'>
          <Heading alignSelf={"center"}>Documents</Heading>
          <br></br>
          <DocumentUpload
            initialValue={documents.transcript}
            title={docTitles.transcript}
            docType={docTypes.transcript}
            styles={{minWidth:280}}
            onSuccess={(url)=>handleDocUpload(url, 'transcript')}/>
          <DocumentUpload
            initialValue={documents.photoId}
            title={docTitles.photoId}
            docType={docTypes.photoId}
            styles={{minWidth:280}}
            onSuccess={(url)=>handleDocUpload(url, 'photoId')}/>
        </Flex>
      </Flex>
    </>
  );
};

export default StudentDocuments;
