import { studentDetailsKeys } from '../../constants/student-details-keys'
const { nextOfKinDetails } = studentDetailsKeys

const FIELDS = [
  {
    title: 'First Name',
    name: nextOfKinDetails.firstName,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "First name is required"
      }
    },
    required: true,
  },
  {
    title: 'Last Name',
    name: nextOfKinDetails.lastName,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "Last name is required"
      }
    },
    required: true,
  },
  {
    title: 'Relationship',
    name: nextOfKinDetails.relationship,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "Relationship is required"
      }
    },
    required: true,
  },
  {
    title: 'Email Address',
    name: nextOfKinDetails.email,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "Email is required"
      }
    },
    required: true,
  },
  {
    title: 'Contact Number',
    name: nextOfKinDetails.contactNumber,
    fieldType: 'input',
    validate: (input) => {
      if(!input.trim()){
        return "Contact number is required"
      }
    },
    required: true,
  },
  {
    title: 'Permanent Address',
    name: nextOfKinDetails.address,
    fieldType: 'textarea',
    validate: (input) => {
      if(!input.trim()){
        return "Permanent address is required"
      }
    },
    required: true,
  }
];

export default FIELDS;
