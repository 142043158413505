import React from 'react'
import {
    Flex,
    Text
} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'

const COURSE_MODES = {
  edit: 'edit',
  view: 'view',
}

const Course = ({ course, mode }) => {
    const history = useHistory()
    const inEditMode = mode === COURSE_MODES.edit

    return (
        <>
            <Flex style={{boxShadow: '2px 2px 3px #C4C4C4'}} pr={5} pl={5} mb={10} borderRadius={5} h={55} w='100%' border='1px solid #E7E7E7' bg='white'>
                <Flex w='100%' display={'flex'} justifyContent={'space-between'} alignSelf={'center'}>
                    <Text>{course?.title}</Text>
                    {inEditMode && <Flex fontWeight={300}>
                        <Text onClick={() => history.push(`/app/courses/${course?.id}/edit`)} _hover={{cursor: 'pointer', color: 'blue.500'}} color='blue.300' mr={5}>edit</Text>
                        <Text _hover={{cursor: 'pointer', color: 'red.600'}} color='red.300'>delete</Text>
                    </Flex> }
                </Flex>

            </Flex>
        </>
    )
}

Course.defaultProps = {
  mode: COURSE_MODES.edit
}

export default Course
