import {
  Flex,
  Text,
} from "@chakra-ui/react";
import { makeKey } from '../../utilities';
import AppTextField  from '../../components/ApplicationModels/AppTextField'
import DocumentUpload from "../DocumentUpload";
import './index.css';

const SectionView = ({ fields, className }) => {
  // let sortedFields = unsortedFields.sort((a, b)=> a.position - b.position);
  return <Flex className={className} flexDir='column'>
    { fields?.map((field, index)=>{
      const fieldLabel = field?.label || field?.title

      switch (field?.fieldType) {
        case 'textfield':
          return <div className='app-text-field'><AppTextField
            size={field?.size}
            label={fieldLabel}
            value={field?.value}
            key={makeKey()}
            mode='view'
          /></div>
          break;

        case 'text-display':
          return <Flex className='mb-10'>
            <Text>{field?.value}</Text>
          </Flex>
          break;

        case 'signature':
          return <Flex className='mb-10'>
            <Text style={{fontSize: 24}} className='signature-field-two signed-field'>{field?.value}</Text>
          </Flex>
          break;

        case 'document':
          return <Flex className="mb-10">
            <DocumentUpload
              initialValue={field?.value}
              title={field?.title}
              docType={field?.options?.docType}
              displayMode
              />
            </Flex>
          break;

        default:
          return <div className='app-text-field'><AppTextField
            size='md'
            label={fieldLabel}
            value={field?.value}
            key={makeKey()}
            mode='view'
          /></div>
      }
    }) }
  </Flex>
};

export default SectionView
