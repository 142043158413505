import {
  atom,
  selector,
} from 'recoil';

import {
  PERSONAL_DETAILS_KEY,
  NEXT_OF_KIN_DETAILS_KEY,
  ACADEMIC_DETAILS_KEY,
} from '../constants/student-details-keys';

export const localStorageEffect = key => ({setSelf, onSet}) => {
  try {
    const savedValue = localStorage.getItem(key)
    if (!!savedValue && savedValue != "undefined" && savedValue != "null") {
      setSelf(JSON.parse(savedValue));
    };

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  } catch (e) {
    return null
  }
};

//elias comments
// ATOMS

export const schoolAtom = atom({
  key: 'school',
  default: null,
  effects: [
    localStorageEffect('school')
  ]
})

export const userAtom = atom({
  key: 'user',
  default: null,
  effects: [
    localStorageEffect('user')
  ]
});

export const userTypeAtom = atom({
  key: 'userType',
  default: null,
  effects: [
    localStorageEffect('userType')
  ]
});

export const jwtTokenAtom = atom({
  key: 'jwtToken',
  default: null
})

export const isUserLoggedInAtom = atom({
  key: 'isUserLoggedIn',
  default: null,
  effects: [
    localStorageEffect('user')
  ]
});

export const loginErrorAtom = atom({
  key: 'loginError',
  default: null
});

export const visitorTypeAtom = atom({
  key: 'visitorType',
  default: null
});

export const coursesAtom = atom({
  key: 'courses',
  default: null,
  effects: [
    localStorageEffect('courses')
  ]
});

export const personalDetailsProgressAtom = atom({
  key: 'personalDetailsProgress',
  default: {
    progress: null,
    total: null
  },
  effects: [
    localStorageEffect('personalDetailsProgress')
  ]
})

export const personalDetailsAtom = atom({
  key: PERSONAL_DETAILS_KEY,
  default: {},
  effects: [
    localStorageEffect([PERSONAL_DETAILS_KEY])
  ]
})

export const nextOfKinDetailsAtom = atom({
  key: NEXT_OF_KIN_DETAILS_KEY,
  default: {},
  effects: [
    localStorageEffect([NEXT_OF_KIN_DETAILS_KEY])
  ]
})

export const academicDetailsAtom = atom({
  key: ACADEMIC_DETAILS_KEY,
  default: [],
  effects: [
    localStorageEffect([ACADEMIC_DETAILS_KEY])
  ]
})

/*
* student applications stores students existing applications as an object
* the object key is the school_id and value is the application template with student initialValues
* IE:
{
    "1": {title: TKI...}
    "2": {title: MIT...}
    "3": {title: HARVARD}
}
*/
export const studentApplicationsAtom = atom({
  key: 'studentApplications',
  default: {},
  effects: [
    localStorageEffect('studentApplications')
  ]
})

export const signatureFormAtom = atom({
  key: 'signatureForm',
  default: {
    applicantSignature: null,
    applicantName: null,
    date: null,
  },
  effects: [
    localStorageEffect('signatureForm')
  ]
})

export const schoolApplicantsResultsAtom = atom({
  key: 'schoolApplicantsResults',
  default: {},
  effects: [
    localStorageEffect('schoolApplicantsResults')
  ]
})

export const studentProfileDocumentsAtom = atom({
  key: 'studentProfileDocuments',
  default: {
    transcript: null,
    photoId: null,
  },
  effects: [
    localStorageEffect('studentProfileDocuments')
  ]
})
