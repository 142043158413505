// SET ENV VARIABLES
const apiBaseLookUp = {
  'local': 'http://localhost:3000',
  'staging': 'https://sasa-api-staging.herokuapp.com',
  'production': 'https://sasa-api-turaco.herokuapp.com'
};
const location = window.location.href;
let APP_ENV = null;

if(location.includes('localhost')){
  APP_ENV = 'local'
} else if (location.includes('staging')) {
  APP_ENV = 'staging'
} else if (location.includes('applysasa.io')) {
  APP_ENV = 'production'
}

// API ROUTES
export const API_BASE_URL  = apiBaseLookUp[APP_ENV];
export const LOGIN_URL = API_BASE_URL + '/login';
export const SIGN_UP_URL = API_BASE_URL + '/sign-up';
export const STUDENT_APPLICATIONS = API_BASE_URL + '/student_applications';
export const SCHOOL_APPLICATIONS_SEARCH = API_BASE_URL + '/schools/applications/search';

// user types
export const STUDENT_USER_TYPE = 'student';
export const SCHOOL_USER_TYPE = 'school_admin';

// Other Exports
export { APP_ENV }

// Cloudinary
const CLOUDINARY_ENV_VAR = APP_ENV === 'production' ? 'PROD' : 'DEV'
export const CLOUDINARY_NAME = 'apply-sasa'
export const UPLOAD_PRESET = 'sasa_default'
export const CLOUDINARY_STUDENTS_PROFILE_PHOTO = `${CLOUDINARY_ENV_VAR}/students/profile-photos`
export const CLOUDINARY_STUDENTS_DOCUMENTS = `${CLOUDINARY_ENV_VAR}/students/documents`
