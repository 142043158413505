import { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  useRecoilState,
} from 'recoil';
import { visitorTypeAtom } from '../../state';

import './home-intro-modal.css';
import { isMobile, isWeb, setVisitorStorage } from '../../utilities';

var classNames = require('classnames');


const HomeIntroModal = ({ modalOpen }) => {
  const [ modalOpenStatus, setModalOpen ] = useState(modalOpen);
  const [ choice, setChoice ] = useState(null);
  const [ visitorType, setVisitorType ] = useRecoilState(visitorTypeAtom);

  function handleContiue(){
    setVisitorType(choice);
    setVisitorStorage(choice);
    setModalOpen(false);
  };

  function handleSelectChoice(choice){
    setChoice(choice)
  }

  useEffect(()=>{
    setModalOpen(modalOpen)
  }, [modalOpen])

  return (
    <Modal
      open={modalOpenStatus}
    >
      <div className={classNames('home-intro-modal-container mx-auto', {'web': isWeb, 'mobile': isMobile})}>
        <div className='d-flex flex-column mx-auto home-intro-modal-content'>
          <p className='home-intro-modal-title'>which option best describes you?</p>
          <div>
            <div className='d-flex align-items-center select-group'>
              <input
                type='checkbox'
                className='home-intro-modal-checkbox'
                onChange={()=>handleSelectChoice('student')}
                name='student'
                checked={choice === 'student'}
              />
              <label className='m-0'>I'm a Student</label>
            </div>

            <div className='d-flex align-items-center select-group'>
              <input
                type='checkbox'
                className='home-intro-modal-checkbox'
                onChange={()=>handleSelectChoice('institution')}
                name='institution'
                checked={choice === 'institution'}
              />
              <label className='m-0'>I'm an Institution Employee</label>
            </div>
          </div>
          <button onClick={()=>handleContiue()}>Continue</button>
        </div>
      </div>
    </Modal>)
};


HomeIntroModal.defaultProps = {
  modalOpen: true
};

export default HomeIntroModal
