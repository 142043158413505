import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const CompleteAllSectionsModal = React.memo(({ openModal, sectionTitles, handleOnClose }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(()=>{
    if(openModal){
      onOpen()
    };
  }, [openModal])

  const handleClose = ()=>{
      onClose()
      handleOnClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={0}>
          <ModalHeader>Please Complete The Following Sections To Proceed</ModalHeader>
          <ModalBody>
            {
              sectionTitles.map((title)=><Text>* {title}</Text>)
            }
          </ModalBody>
          <ModalFooter>
          <Button
            onClick={handleClose}
            borderRadius={0}
            color="white"
            _hover={{ bg: "#FFA800", color: "black" }}
            bg="black"
          >
            CLOSE
          </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
});

export default CompleteAllSectionsModal
