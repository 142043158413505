import ApplicationSectionList from '../../ApplicationSectionList';
import ApplicationStatusView from '../../ApplicationStatusView';

import './index.css';

const formatStatusViewFromApplication = (application) => {
  return {
    status: application?.status,
    lastReviewedOn: application?.last_reviewed_on,
    applicationId: application?.id
  }
}

const ApplicationView = ({ application }) => {
  const statusViewDetails = formatStatusViewFromApplication(application)
  return (
    <div className='application-view-container'>
      <ApplicationStatusView details={statusViewDetails}/>
      <ApplicationSectionList sections={application?.form?.sections} />
    </div>
  )
};

export default ApplicationView;
