import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  Text,
  Icon,
  Link,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Textarea,
  Select,
} from "@chakra-ui/react";
import AppHeader from "../../../../components/AppHeader";
import "../../App.css";

import { BsFacebook, BsTrash } from "react-icons/bs";
import { FaGlobeAfrica } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";
import AppDateField from "../../../../components/ApplicationModels/AppDateField";
import DocumentIcon from "../../../../assets/js-icons/DocumentIcon";
import MultiBuildingIcon from "../../../../assets/js-icons/MultiBuildingIcon";
// import { fetcherPut } from "../../../../api";
import { API_BASE_URL } from "../../../../api/constants";
import axios from "axios";
import { useRecoilState } from "recoil";
import { schoolAtom } from "../../../../state";

const ProfileEdit = () => {
  const [school, setSchool] = useRecoilState(schoolAtom);
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(school.title);
  const [email, setEmail] = useState(school.email);
  const [phoneNumber, setPhone] = useState(school.phone_number);
  const [accommodations, setAccommodations] = useState(school.accommodations);
  const [facebook, setFacebook] = useState(school.facebook);
  const [website, setWebsite] = useState(school.website);
  const [locations, setLocations] = useState(school.locations);
  const [requirements, setRequirements] = useState(school.admission_requirements);
  const [newLocation, setNewLocation] = useState("");
  const [newRequirement, setNewRequirement] = useState("");

  const toast = useToast();

  const editLocation = (input, index) => {
    if (input.target.value !== school.locations[index]) {

      const locationsCopy = [...locations];
      locationsCopy[index] = input.target.value;
      setLocations(locationsCopy);
    } else {
      const locationsCopy = [...locations];
      locationsCopy[index] = school[index];
      setLocations(locationsCopy);
    }
  };

  const addLocation = (onClose) => {
    if (newLocation.length > 0) {
      const locationsCopy = [...locations];
      locationsCopy.push(newLocation);
      setLocations(locationsCopy);
      setNewLocation("");
      onClose();

    } else {
      toast({
        description: "Field can't be empty",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addRequirement = (onClose) => {
    if (newRequirement.length > 0) {
      const requirementsCopy = [...requirements];
      requirementsCopy.push(newRequirement);
      setRequirements(requirementsCopy);
      setNewRequirement("");
      onClose();

    } else {
      toast({
        description: "Field can't be empty",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeLocation = (index) => {
    const locationsCopy = [...locations];
    locationsCopy.splice(index, 1);
    setLocations(locationsCopy);
  };

  const removeRequirement = (index) => {
    const requirementsCopy = [...requirements];
    requirementsCopy.splice(index, 1);
    setRequirements(requirementsCopy);
  };

  const handleUpdate = async () => {
      if(
        !title ||
        !email ||
        !website ||
        !facebook ||
        !locations || locations?.length === 0 ||
        !phoneNumber ||
        !requirements || requirements?.length === 0
      ){
        toast({
          title: "Error",
          description: "All fields are required",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
        return
      }

      try {
        const params = {
          title,
          email,
          website,
          facebook,
          locations,
          phone_number: phoneNumber,
          admission_requirements: requirements,
          accommodations: accommodations,
          // can_apply_from: Date.now(),
          // can_apply_to: Date.now(),
        }
        await axios
          .put(`${API_BASE_URL}/schools/${school.id}`, params)
          .then((res) => {
            if (res.status.toString().startsWith('20')) {
              // Toast success message
              toast({
                title: "Success",
                description: "Profile successfully updated",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              setSchool({...school, ...params})
            }
          });
      } catch (e) {
          toast({
            title: "Error",
            description: "Something went wrong, saving failed",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      }
  };
  return (
    <>
      <body className="d-md-flex">
        <AppHeader />
        <div className="col-xs-12 app-body-container" id="app-container">
          <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
            <Flex flexDir={"column"}>
              <Text fontSize={25} fontWeight={"500"}>
                Profile
              </Text>
              <Button
                onClick={handleUpdate}
                _hover={{ bg: "#FFA800", color: "black" }}
                h={55}
                w={200}
                fontSize={15}
                fontWeight={500}
                borderRadius={0}
                color="white"
                bg="black"
                type="submit"
              >
                Save Changes
              </Button>
            </Flex>
            <Flex
              mt={10}
              flexDir={["column", "column", "column", "column", "row", "row"]}
            >
              <Flex
                w={["100%", "100%", "100%", "100%", "50%", "50%"]}
                flexDir={"column"}
              >
                <Flex flexDir={["column", "row"]}>
                  <Flex w="100%" flexDir={"column"} mt={(10, 0)}>
                    <Text fontWeight={"700"}>Name</Text>
                    <Input
                      value={title}
                      name="title"
                      onChange={(e) => {
                          setTitle(e.target.value);
                      }}
                    />
                    <Text fontWeight={"700"} mt={2}>
                      Email
                    </Text>
                    <Input
                      value={email}
                      onChange={(e) => {
                          setEmail(e.target.value);
                      }}
                    />
                    <Text fontWeight={"700"} mt={2}>
                      Phone Number(s)
                    </Text>
                    <Input
                      value={phoneNumber}
                      onChange={(e) => {
                          setPhone(e.target.value);
                      }}
                    />
                    <Flex flexDir={"column"} mt={2}>
                      <Flex>
                        <Flex w="100%" flexDir={"column"}>
                          <Text fontWeight={"700"}>Facebook</Text>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Icon as={BsFacebook} color="#4267B2" />
                              }
                            />
                            <Input
                              onChange={(e) => {
                                  setFacebook(e.target.value);
                              }}
                              value={facebook}
                              placeholder="facebook url"
                            />
                          </InputGroup>
                        </Flex>
                      </Flex>

                      <Flex mt={5}>
                        <Flex w="100%" flexDir={"column"}>
                          <Text fontWeight={"700"}>Website</Text>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<Icon as={FaGlobeAfrica} />}
                            />
                            <Input
                              onChange={(e) => {
                                  setWebsite(e.target.value);
                              }}
                              value={website}
                              placeholder="mysite.com"
                            />
                          </InputGroup>
                        </Flex>
                      </Flex>

                      <Flex mt={10} flexDir={"column"}>
                        <Text>
                          Accepting apps from <b>10/01/2022 - 10/05/2022</b>
                        </Text>
                        <Flex>
                          <AppDateField
                            mode="edit"
                            value=""
                            label="from"
                            className="pf-date"
                          />
                          <AppDateField
                            mode="edit"
                            value=""
                            label="to"
                            className="pf-date"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                flexDir={"column"}
                ml={[0, 0, 0, 0, 5, 5]}
                mt={[10, 10, 10, 10, 0, 0]}
                w={["100%", "100%", "100%", "100%", "50%", "50%"]}
              >
                <Flex>
                  <Flex w={25} h={25}>
                    <MultiBuildingIcon />
                  </Flex>
                  <Flex ml={3} flexDir={"column"}>
                    <Text fontWeight={"700"}>Accomodation</Text>
                    <Select defaultValue={school.accommodations} mt={2} onChange={ (e)=>{
                      setAccommodations(e.target.value)
                    }}>
                      <option value={true}>yes</option>
                      <option value={false}>no</option>
                    </Select>
                  </Flex>
                </Flex>
                <Flex flexDir={"row"} mt={5}>
                  <Icon w={25} h={25} as={IoLocationSharp} />
                  <Flex ml={3} flexDir={"column"}>
                    <Flex>
                      <Text fontWeight={"700"}>Locations</Text>
                      <AddModal
                        text={newLocation}
                        setText={setNewLocation}
                        onSubmit={addLocation}
                        title={"Add Location"}
                        placeholder={"Location"}
                      />
                    </Flex>
                    <Flex flexDir={"column"} mt={2}>
                      {locations.map((location, index) => (
                        <InputGroup mt={2} key={index}>
                          <Input
                            onChange={(e) => editLocation(e, index)}
                            value={location}
                          />
                          <InputRightElement
                            children={
                              <Icon
                                _hover={{ cursor: "pointer", color: "red.600" }}
                                onClick={() => removeLocation(index)}
                                as={BsTrash}
                                color="red.500"
                              />
                            }
                          />
                        </InputGroup>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDir={"row"} mt={5}>
                  <Flex w={[25]} h={25}>
                    <DocumentIcon />
                  </Flex>
                  <Flex ml={3} flexDir={"column"}>
                    <Flex>
                      <Text fontWeight={"700"}>Admission Requirements</Text>
                      <AddModal
                        text={newRequirement}
                        setText={setNewRequirement}
                        onSubmit={addRequirement}
                        title={"Add Requirement"}
                        placeholder={"Requirement"}
                      />
                    </Flex>
                    <Flex flexDir={"column"} mt={2}>
                      {requirements.map((requirement, index) => (
                        <InputGroup mt={2} key={index}>
                          <Input
                            onChange={(e) => editLocation(e, index)}
                            value={requirement}
                          />
                          <InputRightElement
                            children={
                              <Icon
                                _hover={{ cursor: "pointer", color: "red.600" }}
                                onClick={() => removeRequirement(index)}
                                as={BsTrash}
                                color="red.500"
                              />
                            }
                          />
                        </InputGroup>
                      ))}
                    </Flex>
                    {/* <Textarea mt={2} onChange={e => {
                                            if (e.target.value !== school.admissionRequirements) {
                                                setEditing(true)
                                                setReq(e.target.value)
                                            } else {
                                                setEditing(false)
                                                setReq(school.admissionRequirements)
                                            }
                                        }} mt={2} w={[300, 450]} h={200} value={requirements} /> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </body>
    </>
  );
};

const AddModal = ({ onSubmit, text, setText, title, placeholder }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Icon
        _hover={{ color: "#FFA800", cursor: "pointer" }}
        onClick={onOpen}
        ml={2}
        w={18}
        h={18}
        as={AiOutlinePlus}
        alignSelf={"center"}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={0}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant={"flushed"}
              placeholder={placeholder}
            />
          </ModalBody>

          <ModalFooter>
            <Button borderRadius={0} colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => onSubmit(onClose)}
              borderRadius={0}
              color="white"
              _hover={{ bg: "#FFA800", color: "black" }}
              bg="black"
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileEdit;
