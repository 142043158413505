import axios from 'axios'
import { useState } from 'react'
import {
  Flex,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRecoilValue, useRecoilState } from 'recoil'
import { TrashIcon } from 'chakra-ui-ionicons';

import BackButton from '../../../../../components/BackButton';
import AcademicDetailItem from '../../../../../components/AcademicDetailItem';
import AddAcademicDetailModal from '../../../../../components/AcademicDetailItem/AddAcademicDetailModal';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { userAtom, academicDetailsAtom } from '../../../../../state';
import { API_BASE_URL } from '../../../../../api/constants';
import AcademicDetailsDisplay from '../../../../../components/AcademicDetailsDisplay'

import { removeItemFromArray } from '../../../../../utilities';

const AcademicDetails = () => {
  const [academicDetails, setAcademicDetails] = useRecoilState(academicDetailsAtom)
  const subjectsIsEmpty = academicDetails.length === 0
  const [ openConfirmationModal, setConfirmationModal] = useState(false)
  const [ itemToBeRemoved, setItemToBeRemoved ] = useState(null)
  const user = useRecoilValue(userAtom)
  const toast = useToast()

  const handleNewAcademicDetail = (newItem)=>{
    axios.post(`${API_BASE_URL}/students/${user.student_id}/academic-details`, newItem)
    .then((res) => {
        if (res.status.toString().startsWith('20')) {
          // Toast success message
          toast({
            title: "Success",
            description: "Saved new academic detail",
            status: "success",
            duration: 1500,
          });
          setAcademicDetails([...academicDetails, res.data])
        }
      }).catch((e) => {
        toast({
          title: "Error",
          description: "Something went wrong, saving failed",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    })
  }

  const handleRemoveItemConfirmation = (isConfirmed) => {
    if(isConfirmed){
      const newItems = academicDetails.reduce((acc, item)=>{
        if(item.subject != itemToBeRemoved.subject){
          acc.push(item)
        }
        return acc
      }, [])
      setAcademicDetails(newItems)
      axios.delete(`${API_BASE_URL}/students/academic_details/${itemToBeRemoved.id}`).then((res)=>
        toast({
          title: "Success",
          description: "removed successfully!",
          status: "success",
          duration: 1500,
        })
      )
    }
    setItemToBeRemoved(null)
    setConfirmationModal(false)
  }

  const handleTrashItem = (idx) => {
    setItemToBeRemoved(academicDetails[idx])
    setConfirmationModal(true)
  }

  return (
    <>
      <Flex mt={4} mb={4}>
        <BackButton />
      </Flex>
      <AcademicDetailsDisplay
          handleNewItem={handleNewAcademicDetail}
          handleRemoveItem={handleRemoveItemConfirmation}
          academicDetails={academicDetails}
          setAcademicDetails={setAcademicDetails}
          openConfirmationModal={openConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          itemToBeRemoved={itemToBeRemoved}
          setItemToBeRemoved={setItemToBeRemoved}
          handleTrashItem={handleTrashItem}
        />
    </>
  )
};

export default AcademicDetails;
