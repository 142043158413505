import axios from 'axios'
import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useToast } from "@chakra-ui/react"

import PageForm from '../../../../../components/PageForm'
import { API_BASE_URL } from '../../../../../api/constants'
import { personalDetailsAtom, userAtom } from '../../../../../state'

import FIELDS from '../../../../../constants/student-personal-details/personal-details'

const StudentPersonalDetails = () => {
  const [ personalDetailsState, setPersonalDetailsState ] = useRecoilState(personalDetailsAtom)
  const toast = useToast()
  const user = useRecoilValue(userAtom)

  const handleSubmit = (form) => {
      axios.post(`${API_BASE_URL}/students/${user.student_id}/personal-details`, form)
      .then((res) => {
          if (res.status.toString().startsWith('20')) {
            // Toast success message
            toast({
              title: "Success",
              description: "Profile updated successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setPersonalDetailsState(form)
          }
        }).catch((e) => {
          toast({
            title: "Error",
            description: "Something went wrong, saving failed",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      })
  };

  return <PageForm
    fields={FIELDS}
    initialValues={personalDetailsState}
    handleSubmit={handleSubmit}
    title='Personal Details'
  />
}

export default StudentPersonalDetails
