import { Button, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { useApiGetSchoolCourses } from "../../../../api";
import { API_BASE_URL } from "../../../../api/constants";
import AppHeader from "../../../../components/AppHeader";
import Course from "../../../../components/Course";
import { schoolAtom, coursesAtom, userAtom } from "../../../../state";
// import { courses } from '../../../../constants/courses';

import "../../App.css";

const Courses = () => {
  const history = useHistory();
  const [school, setSchool] = useRecoilState(schoolAtom);
  const [coursesState, setCoursesState] = useRecoilState(coursesAtom);
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (!school) {
      const getSchool = async () => {
        await axios
          .get(`${API_BASE_URL}/schools/${user.school_id}`)
          .then((res) => {
            setSchool(res.data);
          });
      };
      getSchool();
    }
  }, [school, setSchool, user.school_id]);
  
  const {
    data: courses,
    isLoading,
    error,
  } = useApiGetSchoolCourses({ id: user.school_id });

  if (courses) setCoursesState(courses);
  if (error) return <Text>An error has occurred</Text>;
  if (isLoading) return <Text>Loading</Text>;
  if (!courses) return <Text>An error has occurred </Text>;
  
  return (
    <body className="d-md-flex">
      <AppHeader />
      <div className="col-xs-12 app-body-container" id="app-container">
        <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
          <Text fontSize={25} fontWeight={"500"}>
            Courses
          </Text>
          <Button
            onClick={() => history.push("/app/new/course")}
            _hover={{ bg: "#FFA800", color: "black" }}
            h={55}
            fontSize={15}
            fontWeight={500}
            mt={10}
            w={200}
            borderRadius={0}
            color="white"
            bg="black"
          >
            Add New Course
          </Button>
          <Flex mt={10} flexDir={"column"}>
            {courses.map((course, index) => (
              <Flex key={index}>
                <Course course={course} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </div>
    </body>
  );
};

export default Courses;
