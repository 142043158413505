import React, { useState } from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Formik } from "formik";
import { useRecoilValue } from "recoil";

import AppHeader from "../../../../components/AppHeader";
import { API_BASE_URL } from "../../../../api/constants";
import { useHistory } from "react-router-dom";
import { schoolAtom } from '../../../../state';

import "../../App.css";

const NewCourse = () => {
  const [editing, setEditing] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const school = useRecoilValue(schoolAtom)

  const handleAddCourse = async (e) => {
    e.preventDefault();
    const title = e.target.title.value;
    const sponsorship_priority = e.target.sponsorship_priority.value;
    const course_type = e.target.course_type.value;
    const cut_off_points = e.target.cut_off_points.value;
    const description = e.target.desc.value;
    const graduate_level = e.target.graduate_level.value;

    if (
      !title ||
      !sponsorship_priority ||
      !course_type ||
      !cut_off_points ||
      !description ||
      !graduate_level
    ) {
      toast({
        title: "Error",
        description: "All fields are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await axios
      .post(`${API_BASE_URL}/courses`, {
        school_id: school?.id,
        title,
        sponsorship_priority,
        course_type,
        cut_off_points,
        description,
        graduate_level,
        is_visible: true,
      })
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: "Success",
            description: "Course successfully created",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/app/courses");
        } else {
          toast({
            title: "Error",
            description: "An error has occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <body className="d-md-flex">
        <AppHeader />
        <div
          className="col-xs-12 app-body-container"
          id="app-container"
        >
          <Flex ml={[0, 0, 300, 300]} flexDir={"column"} minH="100vh">
            <Text fontSize={25} fontWeight={"500"}>
              New Course
            </Text>
            <Formik
              initialValues={{
                title: "",
                sponsorship_priority: "",
                course_type: "",
                graduate_level: "",
                cut_off_points: "",
                desc: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.title) errors.title = "Required";
                if (!values.sponsorship_priority)
                  errors.sponsorship_priority = "Required";
                if (!values.course_type) errors.course_type = "Required";
                if (!values.graduate_level) errors.graduate_level = "Required";
                if (!values.cut_off_points) errors.cut_off_points = "Required";
                if (!values.desc) errors.desc = "Required";

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <form onSubmit={handleAddCourse}>
                  <Button
                    isLoading={editing && isSubmitting}
                    loadingText="Adding Course"
                    type="submit"
                    disabled={!editing}
                    _hover={{ bg: "#FFA800", color: "black" }}
                    h={55}
                    fontSize={15}
                    fontWeight={500}
                    w={200}
                    rounded={0}
                    color="white"
                    bg="black"
                  >
                    Add Course
                  </Button>

                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Title
                  </Text>
                  <Input
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    name="title"
                    onBlur={handleBlur}
                    value={values.title}
                    rounded={0}
                    w={500}
                  />
                  <Text color="red.500">{touched.title && errors.title}</Text>
                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Sponsorship Priority
                  </Text>

                  <Select
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    rounded={0}
                    name="sponsorship_priority"
                    onBlur={handleBlur}
                    w={120}
                  >
                    <option value="High" selected>
                      High
                    </option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </Select>
                  <Text color="red.500">
                    {touched.sponsorship_priority &&
                      errors.sponsorship_priority}
                  </Text>
                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Course Type
                  </Text>
                  <Input
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="course_type"
                    rounded={0}
                    value={values.course_type}
                    w={300}
                  />
                  <Text color="red.500">
                    {touched.course_type && errors.course_type}
                  </Text>
                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Graduate Level
                  </Text>
                  <Select
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    rounded={0}
                    name="graduate_level"
                    w={200}
                  >
                    <option selected>Undergraduate</option>
                    <option>Postgraduate</option>
                  </Select>
                  <Text color="red.500">
                    {touched.graduate_level && errors.graduate_level}
                  </Text>
                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Points Cutoff
                  </Text>
                  <Input
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    type="number"
                    name="cut_off_points"
                    onBlur={handleBlur}
                    value={values.cut_off_points}
                    w={70}
                    rounded={0}
                  />
                  <Text color="red.500">
                    {touched.cut_off_points && errors.cut_off_points}
                  </Text>
                  <Text mt={10} fontSize={25} fontWeight={600}>
                    Description
                  </Text>
                  <Textarea
                    onChange={(e) => {
                      setEditing(true);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="desc"
                    minW={500}
                    h={200}
                    value={values.desc}
                    w={70}
                    rounded={0}
                  />
                  <Text mb={20} color="red.500">
                    {touched.desc && errors.desc}
                  </Text>
                </form>
              )}
            </Formik>
          </Flex>
        </div>
      </body>
    </>
  );
};

export default NewCourse;
