/*
  DOCUMENTATION - https://devexpress.github.io/devextreme-reactive/react/chart/demos/bar/simple-bar/
*/

import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';


export const BarGraph = ({ data, valueKey, titleKey, rotated }) => {
  return (
    <Chart
      data={data}
      rotated
    >
      <ArgumentAxis />
      <ValueAxis max={7} />

      <BarSeries
        valueField="applicants"
        argumentField="course"
        barWidth={0.5}
      />
      <Animation />
      <EventTracker />
      <Tooltip />
    </Chart>
  )
};
