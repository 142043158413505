import { Flex, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import PageForm from '../../../../components/PageForm'
import StudentApplicationsAcademicsFormSection from '../student-applications-academics-form-section'
import StudentApplicationsSignatureSection from '../student-applications-signature-section'
import { APPLICATION_SECTION_TYPES } from '../../../../constants/application-templates/types'
import { studentApplicationsAtom } from '../../../../state'
import { selectStudentApplicationSection } from '../../../../state/selectors'

//styles
const styles = {
  container: {
    backgroundColor: "white",
  },
};

// Model FXN
const getInitialValues = (appSection) => {
  console.log(appSection);
  return appSection?.fields?.reduce((acc, i) => {
    acc[i.name] = i.value || null;
    return acc;
  }, {});
};

const StudentApplicationsFormSection = ({ match }) => {
  const schoolId = match.params.school_id;
  const sectionId = match.params.section_id;
  const schoolApplication = useRecoilValue(studentApplicationsAtom);
  const [appSection, setAppSection] = useRecoilState(
    selectStudentApplicationSection({ schoolId, sectionId })
  );
  const [initialValues, setInitialValues] = useState(null);
  const toast = useToast();

  // useEffect(() => {
  //   console.log(initialValues)
  // }, [initialValues])
  useEffect(() => {
    setInitialValues(getInitialValues(appSection));
  }, [appSection]);

  // On Submit of the form
  // Pull up the applications form atom
  // Grab an existing application if it exists else create a new one
  // Fill in the new values
  const handleSubmitFormSection = (form) => {
    setAppSection(form);
    toast({
      title: "Success",
      description: "Saved!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const { type } = appSection;
  const isStandardSection = type === APPLICATION_SECTION_TYPES.standard;
  const isAcademicsSection = type === APPLICATION_SECTION_TYPES.academics;
  const isSignatureSection = type === APPLICATION_SECTION_TYPES.signature;
  const isDocumentSection = type === APPLICATION_SECTION_TYPES.document;
  return (
    <Flex flexDir="column" style={styles.container}>
      {initialValues &&
        (isStandardSection || isDocumentSection || isSignatureSection) && (
          <PageForm
            fields={appSection.fields}
            initialValues={initialValues}
            handleSubmit={handleSubmitFormSection}
            title={appSection.title}
          />
        )}
      {isAcademicsSection && (
        <StudentApplicationsAcademicsFormSection
          schoolId={schoolId}
          sectionId={sectionId}
          appSection={appSection}
        />
      )}

    </Flex>
  );
};

export default StudentApplicationsFormSection;
