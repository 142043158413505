import AppTextField from '../ApplicationModels/AppTextField';
import ApplicationSectionViewContainer from '../ApplicationSectionViewContainer';
import { makeKey } from '../../utilities';

var classNames = require('classnames');

const AcademicView = ({ field, className }) => {
    return <><tr key={makeKey()}>
    <td>{field.subject}</td>
    <td>{field.certificate}</td>
    <td>{field.grade}</td>
    <td>{field.year_taken}</td>
  </tr></>
}

const AcademicApplicationView = ({ header, title, grades }) => {
  return (
    <ApplicationSectionViewContainer header={header} title={title}>
      <table>
        <tr>
          <th>subject</th>
          <th>certificate</th>
          <th>grade</th>
          <th>year taken</th>
        </tr>
        {grades.map((field)=><AcademicView field={field}/>)}
      </table>
    </ApplicationSectionViewContainer>
  )
};

export default AcademicApplicationView
