import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import { Formik } from 'formik';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { STUDENT_PROFILE_PAGE } from '../../routes'

import './signup.css';
import { signUpUser } from '../../api/index';
import HomeHeader from '../../components/HomeHeader';
import { handleRedirect, setLoginSessionStorage, goToAppIfLoggedIn, isUserLoggedIn } from '../../utilities';
import { userAtom } from '../../state';

const SignUp = () => {
  goToAppIfLoggedIn();
  const userLoggedIn = isUserLoggedIn();
  const [formSuccess, setFormSuccess] = useState(false);
  const [signUpSuccessful, setSignUpSuccess] = useState(false);
  const [signUpError, setSignUpError] = useState(null);

  useEffect(()=>{
    if(!!signUpSuccessful){
      setLoginSessionStorage(true);
      handleRedirect(STUDENT_PROFILE_PAGE);
    };
  }, [signUpSuccessful]);

  function handleSignUp(e){
    e.preventDefault()
    if(formSuccess){
      let userData = {
        email: e.target.email.value,
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
        password: e.target.password.value,
      }

      signUpUser(userData).then((result)=>{
        setSignUpSuccess(true);
      }).catch((error)=>{
        let message = null
        let defaultMessage = 'sorry something went wrong please check information is correct and try again';

        if(
          !!error.response.data.message
          && error.response.data.message != []
        ){
          message = error.response.data.message
        };
          setSignUpError(message[0] || defaultMessage);
      });

    };
  };

  return (
      <body id='sign-up-page'>
        <header>
          <HomeHeader />
        </header>

        <section id='sign-up-section-container' className='login-sign-up-section-container'>

          <div className='login-signup-intro-subtitle text-center'>
            <p className='login-signup-welcome-text-1 m-0 p-0 m-auto'>Welcome!</p>
            <p className='login-signup-welcome-text-2'>Complete the form below to sign up for an account</p>
          </div>

          <Formik
                 initialValues={{
                   firstName:'',
                   lastName:'',
                   email: '',
                   password: '',
                   passwordConfirmation: '',
                 }}
                 validate={values => {
                   const errors = {};

                   // validate email
                   if (!values.email) {
                     errors.email = 'Required';
                   } else if (
                     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                   ) {
                     errors.email = 'Invalid email address';
                   }

                   // validate password
                   if (!values.password){
                     errors.password = 'Required'
                   }

                   // validate firstName
                   if(!values.firstName){
                     errors.firstName = 'Required'
                   }

                   // validate lastName
                   if(!values.lastName){
                     errors.lastName = 'Required'
                   }

                   // validate passwordConfirmation
                   if(values.password != values.passwordConfirmation){
                     errors.passwordConfirmation = "doesn't match password"
                   }

                   if(Object.keys(errors).length === 0){
                     setFormSuccess(true)
                   } else {
                     setFormSuccess(false)
                   }

                   return errors;
                 }}
                 onSubmit={(values, { setSubmitting }) => {
                   setTimeout(() => {
                     alert(JSON.stringify(values, null, 2));
                     setSubmitting(false);
                   }, 400);
                 }}
               >
                 {({
                   values,
                   errors,
                   touched,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isSubmitting,
                   /* and other goodies */
                 }) => (
                   <form
                      onSubmit={handleSignUp}
                      className="login-sign-up-form-container"
                      id='sign-up-form-container'
                    >
                    <div className='sign-up-input-group'>
                       <label>First Name</label>
                       <input
                         type="firstName"
                         name="firstName"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.firstName}
                         className="signup-field"
                       />
                       <span className='login-sign-up-error'>{errors.firstName && touched.firstName && errors.firstName}</span>
                    </div>
                    <div className='sign-up-input-group'>
                      <label>Last Name</label>
                      <input
                        type="lastName"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        className="signup-field"
                      />
                      <span className='login-sign-up-error'>{errors.lastName && touched.lastName && errors.lastName}</span>
                    </div>

                    <div className='sign-up-input-group'>
                      <label htmlFor="email">Email Address</label>
                      <input
                         type="email"
                         name="email"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.email}
                         className="signup-field"
                         autoComplete="off"
                      />
                      <span className='login-sign-up-error'>{errors.email && touched.email && errors.email}</span>
                    </div>

                    <div className='sign-up-input-group'>
                      <label htmlFor="password">Password</label>
                      <input
                         type="password"
                         name="password"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.password}
                         className="signup-field"
                         autoComplete="off"
                      />
                      <span className='login-sign-up-error'>{errors.password && touched.password && errors.password}</span>
                    </div>

                    <div className='sign-up-input-group'>
                      <label htmlFor="password">Password Confirmation</label>
                      <input
                         type="password"
                         name="passwordConfirmation"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.passwordConfirmation}
                         className="signup-field"
                         autoComplete="off"
                      />
                      <span className='login-sign-up-error'>{errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}</span>
                    </div>

                     <button type="submit" disabled={isSubmitting} className="login-sign-up-button">
                       Sign Up
                     </button>
                   </form>
                 )}
          </Formik>
          <p className='text-center login-signup-error'>{signUpError}</p>
          <p className='text-center m-0'>Already have an account</p>
          <p className='text-center m-0 underline'><a href='/login'>Click here to log in</a></p>
        </section>
      </body>
  )
};

export default SignUp
